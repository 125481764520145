import DatePicker, { registerLocale } from 'react-datepicker';
import {
    InterestRateApplicationState,
    InterestRateState,
    NumberFormat,
    useLayoutStyles,
} from '@r3/cbdc-asset-frontend-core';
import { setHours, setMinutes } from 'date-fns';
import { useEffect, useState } from 'react';

import { TextField } from '@material-ui/core';
import enGB from 'date-fns/locale/en-GB';
import { isCurrencyInputValid } from 'components/commonComponents/CurrencyInput/isCurrencyInputValid';
import { useDatePickerStyles } from 'materialStyles/datePickerStyles';
import { useTranslation } from 'react-i18next';

const LOCALE = 'enGb';
const DATE_PICKER_FORMAT_WITH_TIME = 'dd/MM/yyyy HH:mm';
const DATE_PICKER_FORMAT = 'dd/MM/yyyy';
const INTEREST_RATE_DECIMAL = 2;

registerLocale(LOCALE, enGB);

type Props = {
    interestRateApplyState: InterestRateApplicationState;
    interestRateState: InterestRateState | undefined;
    setInterestState: (interestRateState: InterestRateState | undefined) => void;
    inheritTimeFromMinDate?: boolean;
    minDate?: Date;
    allowZeroValue?: boolean;
    hideTimeSelect?: boolean;
};

const InterestRateSetValues: React.FC<Props> = ({
    interestRateState,
    setInterestState,
    interestRateApplyState,
    minDate = new Date(),
    inheritTimeFromMinDate = false,
    allowZeroValue = false,
    hideTimeSelect = false,
}) => {
    const datePickerClasses = useDatePickerStyles();
    const layoutClasses = useLayoutStyles();
    const { t: text } = useTranslation();
    const [interestValue, setInterestValue] = useState<string>(
        interestRateState?.values[0]?.yearlyInterest.toString() ?? '0'
    );
    const [fromDate, setFromDate] = useState<Date>(
        interestRateState?.values[0].effectiveDate ? new Date(interestRateState?.values[0].effectiveDate) : minDate
    );
    const isInterestValueValid = (val: string, decimal: number) => {
        if ((val.match(/-/g) || []).length > 1) {
            return false;
        }
        if (val.startsWith('-')) {
            val = val.split('-')[1];
        }
        return isCurrencyInputValid(val, decimal);
    };

    useEffect(() => {
        if (fromDate) {
            let updatedDate = new Date(fromDate);
            updatedDate.setSeconds(0);
            updatedDate.setMilliseconds(0);
            if (inheritTimeFromMinDate) {
                const hours = minDate.getHours();
                const minutes = minDate.getMinutes();
                let seconds = minDate.getSeconds();
                updatedDate.setHours(hours);
                updatedDate.setMinutes(minutes);
                updatedDate.setSeconds(seconds);
            }
            setInterestState({
                applicationState: interestRateApplyState,
                values: [{ yearlyInterest: parseFloat(interestValue), effectiveDate: updatedDate.toISOString() }],
            });
        } else {
            setInterestState({
                applicationState: InterestRateApplicationState.NOT_SELECTED,
                values: [{ yearlyInterest: parseFloat(interestValue), effectiveDate: '' }],
            });
        }
        // eslint-disable-next-line
    }, [interestRateApplyState, interestValue, fromDate]);

    const handleInterestInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event.preventDefault();
        let val = event.target.value.toString();
        if (isInterestValueValid(val, INTEREST_RATE_DECIMAL)) {
            setInterestValue(val.toString());
        }
    };

    const updateInterestRateValue = () => {
        setInterestValue(NumberFormat.stringToFixed(interestValue, INTEREST_RATE_DECIMAL));
    };

    const isErorInInterestValueInput = () => {
        if (interestValue.length <= 0 || isNaN(parseFloat(interestValue))) {
            return true;
        }

        if (!allowZeroValue && Math.abs(parseFloat(interestValue)) === 0) {
            return true;
        }

        return false;
    };

    return (
        <>
            <div style={{ display: 'flex', height: 'fit-content' }}>
                <div className={datePickerClasses.datePickerWrapper}>
                    <div style={{ marginRight: 12 }}>
                        <div className={datePickerClasses.datePickerLabel}>{text('interestRates.interestValue')}</div>
                        <TextField
                            error={isErorInInterestValueInput()}
                            id="InterestValueInput"
                            className={` ${layoutClasses.dayInput} ${layoutClasses.formInputSmall}`}
                            style={{ width: 160, marginTop: 2, marginBottom: 8 }}
                            value={interestValue}
                            inputProps={{
                                style: {
                                    height: 47,
                                    marginTop: 6,
                                    paddingTop: 6,
                                    paddingBottom: 0,
                                    paddingLeft: 8,
                                    border: '2px solid grey',
                                    fontWeight: 400,
                                },
                            }}
                            onBlur={updateInterestRateValue}
                            onChange={handleInterestInputChange}
                            variant="standard"
                            data-testid={'interest-value-input'}
                        />
                    </div>
                    <div style={{ marginRight: 12 }} data-testid={'interest-start-date'}>
                        <div className={datePickerClasses.datePickerLabel}>
                            {hideTimeSelect
                                ? text('interestRates.effectiveStartDateNoTime')
                                : text('interestRates.effectiveStartDate')}
                        </div>
                        <DatePicker
                            className={`${datePickerClasses.datePicker}  ${datePickerClasses.large} ${
                                datePickerClasses.textLow
                            } ${!hideTimeSelect ? datePickerClasses.datePickerWithTime : ''}`}
                            selected={fromDate}
                            minDate={minDate}
                            minTime={setHours(setMinutes(new Date(), 0), 0)}
                            maxTime={setHours(setMinutes(new Date(), 0), 23)}
                            showTimeSelect={!hideTimeSelect}
                            timeIntervals={1}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={(date: Date) => setFromDate(date)}
                            timeInputLabel="Start Time:"
                            dateFormat={hideTimeSelect ? DATE_PICKER_FORMAT : DATE_PICKER_FORMAT_WITH_TIME}
                            locale={LOCALE}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default InterestRateSetValues;
