import { Button, Grid, SvgIcon } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { insertIntoArrayIf, useFeatureFlagContext, useLandingPageStyles } from '@r3/cbdc-asset-frontend-core';

import { ArrowUpwardRounded } from '@material-ui/icons';
import { ReactComponent as BondsIcon } from 'icons/bonds.svg';
import { IssueCBDC } from '../cbdcManager/IssueCBDC/IssueCBDC';
import { LandingBonds } from './LandingBonds';
import { LandingPie } from './LandingPie';
import LandingPieGraphs from './LandingPieGraphs';
import LandingPieWrapper from './LandingPieWrapper';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import TabSection from './TabSection';
import { TransactionDashboard } from '../transactionDashboard/TransactionDashboard';

export const LandingPage = () => {
    const classes = useLandingPageStyles();
    const { isFeatureEnabled } = useFeatureFlagContext();

    const [isTxLogCBDC, setIsTxLogCBDC] = useState<boolean>(true);
    const [txLogQueryType, setTxLogQueryType] = useState<'CBDCToken' | 'KYCReferenceState'>('CBDCToken');

    const landingPieRef = useRef<React.ElementRef<typeof LandingPie>>(null);
    const txDashboardRef = useRef<React.ElementRef<typeof TransactionDashboard>>(null);

    const updateChildren = () => {
        setTimeout(() => {
            if (landingPieRef.current) {
                landingPieRef.current.refresh();
            }
            if (txDashboardRef.current) {
                txDashboardRef.current.refresh();
            }
        }, 1000);
    };

    useEffect(() => {
        if (isTxLogCBDC) {
            setTxLogQueryType('CBDCToken');
        } else {
            setTxLogQueryType('KYCReferenceState');
        }
    }, [isTxLogCBDC]);

    const leftSectionTabComponentPairs = [
        ...insertIntoArrayIf(isFeatureEnabled('issueCBDC'), {
            tabButton: {
                text: 'Issue CBDC',
                icon: (
                    <div style={{ display: 'flex' }}>
                        <ArrowUpwardRounded style={{ width: 15, height: 15 }} />
                        <MonetizationOnIcon style={{ width: 20, height: 20 }} />
                    </div>
                ),
            },
            component: <IssueCBDC onSuccessfulSubmit={updateChildren} ignoreInfoModal={true} />,
        }),
    ];

    const pieGraphTabComponentPairs = [
        {
            tabButton: {
                text: 'BONDS',
                icon: (
                    <SvgIcon>
                        <BondsIcon />
                    </SvgIcon>
                ),
            },
            component: (
                <LandingPieWrapper>
                    <LandingBonds />
                </LandingPieWrapper>
            ),
        },
        {
            tabButton: { text: 'CBDC ASSETS', icon: <MonetizationOnIcon /> },
            component: (
                <LandingPieWrapper>
                    <LandingPie />
                </LandingPieWrapper>
            ),
        },
    ];

    return (
        <Grid
            container
            justifyContent="flex-start"
            direction="row"
            spacing={2}
            style={{ width: '95%', margin: 'auto', marginTop: 24 }}
        >
            {leftSectionTabComponentPairs.length > 0 ? (
                <>
                    <Grid item xs={6}>
                        <div style={{ height: '100%' }}>
                            <TabSection tabComponentPairs={leftSectionTabComponentPairs} />
                        </div>
                    </Grid>
                    <LandingPieGraphs tabComponentPairs={pieGraphTabComponentPairs} />
                </>
            ) : (
                <>
                    <LandingPieGraphs tallSection tabComponentPairs={[pieGraphTabComponentPairs[0]]} />
                    <LandingPieGraphs tallSection tabComponentPairs={[pieGraphTabComponentPairs[1]]} />
                </>
            )}

            <Grid item xs={12}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            marginTop: 12,
                            marginBottom: -10,
                        }}
                    >
                        <Button
                            className={` ${classes.tabButton} ${isTxLogCBDC ? classes.selectedTab : ''} tab`}
                            // style={{
                            //     color: isTxLogCBDC ? 'lightblue' : 'white',
                            // }}
                            onClick={() => setIsTxLogCBDC(true)}
                            endIcon={<LocalAtmIcon />}
                        >
                            CBDC States
                        </Button>

                        <Button
                            // style={{
                            //     color: !isTxLogCBDC ? 'lightblue' : 'white',
                            // }}
                            className={` ${classes.tabButton}  ${!isTxLogCBDC ? classes.selectedTab : ''} tab`}
                            onClick={() => setIsTxLogCBDC(false)}
                            endIcon={<RecentActorsIcon />}
                        >
                            Member Access States
                        </Button>
                    </div>
                    <TransactionDashboard stateToQuery={txLogQueryType} ref={txDashboardRef} />
                </div>
            </Grid>
        </Grid>
    );
};
