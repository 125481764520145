import { CBDCREQUESTS, KYCREQUESTS, REDEEMREQUESTS } from '../../constants/Routes';
import { TabPageMap, TabsWrapper, insertIntoArrayIf, useFeatureFlagContext } from '@r3/cbdc-asset-frontend-core';

import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import { CBDCRequests } from './CBDCRequests/CBDCRequests';
import CachedIcon from '@material-ui/icons/Cached';
import { KycRequests } from './KycRequests/KycRequets';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import React from 'react';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { RedemptionRequests } from './RedemptionRequests/RedemptionRequests';
import { useTranslation } from 'react-i18next';

export const IncomingRequests: React.FC = () => {
    const { t } = useTranslation();
    const { isFeatureEnabled } = useFeatureFlagContext();

    const tabPageMap: TabPageMap[] = [
        ...insertIntoArrayIf(isFeatureEnabled('issuanceRequests'), {
            route: CBDCREQUESTS,
            pageComponent: CBDCRequests,
            tabDetails: {
                icon: (
                    <div style={{ display: 'flex' }}>
                        <ArrowDownwardOutlinedIcon style={{ width: 15, height: 15 }} />
                        <MonetizationOnOutlinedIcon />
                    </div>
                ),
                label: t('pageTitle.CBDCRequests'),
            },
        }),
        ...insertIntoArrayIf(isFeatureEnabled('redemptionRequests'), {
            route: REDEEMREQUESTS,
            pageComponent: RedemptionRequests,
            tabDetails: {
                icon: (
                    <div style={{ display: 'flex' }}>
                        <ArrowDownwardOutlinedIcon style={{ width: 15, height: 15 }} />
                        <CachedIcon />
                    </div>
                ),
                label: t('pageTitle.redemptionRequests'),
            },
        }),
        ...insertIntoArrayIf(isFeatureEnabled('memberAccessRequests'), {
            route: KYCREQUESTS,
            pageComponent: KycRequests,
            tabDetails: {
                icon: (
                    <div style={{ display: 'flex' }}>
                        <ArrowDownwardOutlinedIcon style={{ width: 15, height: 15 }} />
                        <RecentActorsIcon />
                    </div>
                ),
                label: t('pageTitle.kycRequests'),
            },
        }),
    ];

    return <TabsWrapper pageTitle={t('pageTitle.incomingRequests')} tabPageMap={tabPageMap} />;
};
