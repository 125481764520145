import * as ROUTES from 'constants/Routes';

import { insertIntoArrayIf, useFeatureFlagContext } from '@r3/cbdc-asset-frontend-core';

import { Assets } from 'components/cbdcManager/Assets/Assets';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import { IncomingRequests } from 'components/incomingRequests/IncomingRequests';
import { KnowYourCustomer } from 'components/memberControls/KnowYourCustomer';
import { LandingPage } from 'components/landingPage/LandingPage';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import { NavPage } from 'types/NavPage';
import { NetworkTab } from 'components/networkTab/NetworkTab';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ReferenceArchitectures from 'components/referenceArchitectures/ReferenceArchitectures';
import { TransactionDashboardParent } from 'components/transactionDashboard/TransactionDashboardParent';
import { TreasuryDashboard } from 'components/treasuryDashboard/TreasuryDashboard';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import { useTranslation } from 'react-i18next';

const useIssuerPages = () => {
    const { t } = useTranslation();

    const { isFeatureEnabled } = useFeatureFlagContext();

    const pages: NavPage[] = [
        {
            navButton: {
                icon: <DashboardRoundedIcon />,
                highlightPath: '/',
                linkPath: '/',
                label: 'Home',
            },
            route: '/',
            exactRoute: true,
            pageComponent: LandingPage,
        },
        ...insertIntoArrayIf(isFeatureEnabled('cbdcManager'), {
            navButton: {
                icon: <LocalAtmIcon />,
                highlightPath: ROUTES.ASSETS,
                linkPath: ROUTES.AVAILABLECBDC,
                label: t('pageTitle.assets'),
            },
            route: ROUTES.ASSETS,
            pageComponent: Assets,
        }),
        ...insertIntoArrayIf(isFeatureEnabled('accessControls'), {
            navButton: {
                icon: <FingerprintIcon />,
                highlightPath: ROUTES.KYCCONTROL,
                linkPath: ROUTES.LISTKYC,
                label: t('pageTitle.kycControl'),
            },
            route: ROUTES.KYCCONTROL,
            pageComponent: KnowYourCustomer,
        }),
        ...insertIntoArrayIf(isFeatureEnabled('requests'), {
            navButton: {
                icon: <MoveToInboxIcon />,
                highlightPath: ROUTES.INCOMINGREQUESTS,
                linkPath: ROUTES.CBDCREQUESTS,
                label: t('pageTitle.incomingRequests'),
            },
            route: ROUTES.INCOMINGREQUESTS,
            pageComponent: IncomingRequests,
        }),
        ...insertIntoArrayIf(isFeatureEnabled('treasuryDashboard'), {
            navButton: {
                icon: <DonutLargeIcon />,
                highlightPath: ROUTES.DEMODASH,
                linkPath: ROUTES.BONDSDASH,
                label: 'Treasury Dashboard',
            },
            route: ROUTES.DEMODASH,
            pageComponent: TreasuryDashboard,
        }),
        ...insertIntoArrayIf(isFeatureEnabled('txDashboard'), {
            navButton: {
                icon: <ReceiptIcon />,
                highlightPath: ROUTES.TRANSACTIONDASH,
                linkPath: ROUTES.TRANSACTIONDASH,
                label: 'Corda Transactions Dashboard',
            },
            route: ROUTES.TRANSACTIONDASH,
            pageComponent: TransactionDashboardParent,
        }),
        ...insertIntoArrayIf(isFeatureEnabled('referenceModels'), {
            navButton: {
                icon: <DeviceHubIcon />,
                highlightPath: ROUTES.REFARCHITECTURE,
                linkPath: ROUTES.REFARCHITECTURE,
                label: 'Reference Models',
            },
            route: ROUTES.REFARCHITECTURE,
            pageComponent: ReferenceArchitectures,
        }),
        ...insertIntoArrayIf(isFeatureEnabled('visibilityAndReissuance'), {
            navButton: {
                icon: <VisibilityRoundedIcon />,
                highlightPath: ROUTES.VISIBILITYREISSUANCE,
                linkPath: ROUTES.VISIBILITYREISSUANCE,
                label: 'Visibility and Reissuance',
                reloadOnClick: true,
            },
            route: ROUTES.VISIBILITYREISSUANCE,
            pageComponent: TransactionDashboardParent,
        }),
        {
            route: ROUTES.NETWORKTAB,
            pageComponent: NetworkTab,
        },
    ];

    return { pages };
};

export default useIssuerPages;
