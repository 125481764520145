import { Button, Divider, Paper, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useTranslation } from 'react-i18next';

type AssetBasicDetailsStepProps = {
    decimalPlaces: number;
    setDecimalPlaces: (value: number) => void;
    assetName: string;
    setAssetName: (name: string) => void;
    validName: (value: string) => boolean;
};

const AssetBasicDetailsStep: React.FC<AssetBasicDetailsStepProps> = (props) => {
    const { decimalPlaces, setDecimalPlaces, assetName, setAssetName, validName } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    const [currencyDecimalExample, setCurrencyDecimalExample] = useState<string>('0');

    const handleDecimalSelection = (decimalPlaces: number) => {
        if (decimalPlaces === 0) {
            setCurrencyDecimalExample('0');
        } else {
            let num = 0;
            setCurrencyDecimalExample(num.toFixed(decimalPlaces));
        }
        setDecimalPlaces(decimalPlaces);
    };

    const handleFormInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event.preventDefault();
        const value = event.target.value;

        if (event.target.name === 'assetName') {
            setAssetName(value);
        }
    };

    const groupButtons: JSX.Element[] = [];

    for (let i = 0; i <= 3; i++) {
        groupButtons.push(
            <Button
                variant="outlined"
                className={`${layoutClasses.button} ${layoutClasses.buttonAutoSize} decimalButton`}
                onClick={() => handleDecimalSelection(i)}
                disabled={decimalPlaces === i}
                data-testid={'decimalBtn' + i}
                key={i}
            >
                {i}
            </Button>
        );
    }

    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>{t('defineAsset.enterName')}</Typography>
                <Divider className={layoutClasses.summaryDivider} />
                <TextField
                    id="CBDCName"
                    className={` ${layoutClasses.formInput} ${layoutClasses.formInputSmall}`}
                    label={validName(assetName) ? t('defineAsset.assetName') : 'A token with this name already exists.'}
                    onChange={(event) => {
                        handleFormInput(event);
                    }}
                    name="assetName"
                    value={assetName}
                    required
                    variant="filled"
                    data-testid="asset-name-input"
                    error={!validName(assetName)}
                />

                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {t('defineAsset.selectDecimal')} {currencyDecimalExample}
                </Typography>
                <Divider className={layoutClasses.summaryDivider} />
                <div className={`${layoutClasses.buttonGroup}`}>{groupButtons}</div>
            </Paper>
        </div>
    );
};

export default AssetBasicDetailsStep;
