import apiCall from './apiCall';

/**
 * @remark
 * Used to issue an asset to memeber on the network
 *
 * @param identifier name of the asset
 * @param amountToIssue amount of asset to send
 * @param partyToIssueTo how many decimal places will be present on the asset
 *
 * @returns
 * AxiosResponse which resolves in success or error
 */
export const issueAssetToMember = async (identifier: string, partyToIssueTo: string, amountToIssue: number) =>
    apiCall({
        method: 'post',
        path: '/api/assets/issueTo',
        params: {
            tokenIdentifier: identifier,
            partyToIssueTo: partyToIssueTo,
            amountToIssue: amountToIssue,
        },
    });

/**
 * @remark
 * Used to get the summary of the assets in the current node's vault
 *
 * @param dontTrack to track the request with a loading spinner
 *
 * @returns
 * AxiosResponse which resolves in an asset summary object array or error
 */
export const requestAssetSummary = async (dontTrack?: boolean) =>
    apiCall({
        method: 'get',
        path: '/api/assets/summary',
        dontTrackRequest: dontTrack,
    });
