import { Divider, Paper, Typography } from '@material-ui/core';

import { NumberFormat, useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useTranslation } from 'react-i18next';

type IssueSummaryStepProps = {
    selectedAssetName: string;
    selectedParticipant: string;
    assetAmount: string;
};

const IssueSummaryStep: React.FC<IssueSummaryStepProps> = (props) => {
    const { selectedAssetName, selectedParticipant, assetAmount } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>{t('issueAsset.summaryMessage')}</Typography>

                <Divider className={layoutClasses.summaryDivider} />

                <Typography className={`${layoutClasses.text}`}>
                    {t('commonText.asset') + ':'} {selectedAssetName}
                </Typography>

                <Typography className={`${layoutClasses.text}`}>
                    {t('commonText.participant') + ':'} {selectedParticipant}
                </Typography>

                <Typography className={`${layoutClasses.text}`}>
                    {t('issueAsset.assetAmount') + ':'} {NumberFormat.addThousandSeparators(assetAmount)}
                </Typography>
            </Paper>
        </div>
    );
};

export default IssueSummaryStep;
