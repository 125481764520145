import 'date-fns';

import { CBDCToken, NumberFormat } from '@r3/cbdc-asset-frontend-core';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from '@material-ui/core';

import React from 'react';
import { useTableStyles } from '@r3/cbdc-asset-frontend-core';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string | boolean | Date | CBDCToken },
    b: { [key in Key]: number | string | boolean | Date | CBDCToken }
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof TxLogData;
    label: string;
    numeric: boolean;
}

interface EnhancedTableProps {
    classes: ReturnType<typeof useTableStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TxLogData) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof TxLogData) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow className={classes.tableHeader}>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            className={orderBy === headCell.id ? classes.activeSortLabel : classes.inactiveSortLabel}
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

type TableProps = {
    onClickRow: (row: TxLogData) => void;
    totalResults: number;
    page: number;
    setPage: (page: number) => void;
    data: TxLogData[];
};

const headCells: HeadCell[] = [
    { id: 'command', numeric: false, disablePadding: false, label: 'Tx Type' },
    { id: 'tokenDefinition', numeric: false, disablePadding: false, label: 'Token Name' },
    { id: 'amount', numeric: true, disablePadding: false, label: 'Amount' },
    { id: 'usageCount', numeric: true, disablePadding: false, label: 'Usage Count' },
    { id: 'holder', numeric: false, disablePadding: false, label: 'Initiator' },
    { id: 'counterParty', numeric: false, disablePadding: false, label: 'Counterparty' },
    { id: 'txTime', numeric: false, disablePadding: false, label: 'Tx Time' },
];

export interface TxLogData {
    txId: string;
    command: string;
    contract: string;
    state: string;
    txTime: Date;
    notary: string;
    numInputs: number;
    tokenDefinition: CBDCToken;
    amount: number | string;
    holder: string;
    consumed: boolean;
    issueDate: Date;
    usageCount: number;
    counterParty: string;
}

export const EnhancedTable: React.FC<TableProps> = ({ onClickRow, page, setPage, data }) => {
    const classes = useTableStyles();
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof TxLogData>('txTime');
    const [rowsPerPage] = React.useState(10);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof TxLogData) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0);
    };

    const handleClick = (event: React.MouseEvent<unknown>, row: TxLogData) => {
        onClickRow(row);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (
        <Paper elevation={10} className={classes.root}>
            <div className={classes.paper}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={10}
                        />
                        <TableBody>
                            {stableSort(data, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row)}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.command + row.toString() + index}
                                            className={classes.tableRow}
                                        >
                                            <TableCell component="th" id={labelId} scope="row" align="center">
                                                {row.command}
                                            </TableCell>
                                            <TableCell align="center">{row.tokenDefinition.tokenName}</TableCell>
                                            <TableCell align="right">
                                                {row.amount !== null && row.amount !== 'N/A'
                                                    ? NumberFormat.addThousandSeparators(
                                                          parseFloat(row.amount.toString()).toFixed(
                                                              row.tokenDefinition.decimals
                                                          )
                                                      )
                                                    : 'N/A'}
                                            </TableCell>
                                            <TableCell align="right">{row.usageCount}</TableCell>
                                            <TableCell align="center">{row.holder}</TableCell>
                                            <TableCell align="center">{row.counterParty}</TableCell>

                                            <TableCell>{new Date(row.txTime).toLocaleString()}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    className={`${classes.tableHeader} emptyRow`}
                                    style={{ height: 50 * emptyRows }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    classes={{ root: classes.paginationRoot }}
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </Paper>
    );
};
