import { Paper, Typography } from '@material-ui/core';

import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import React from 'react';
import { useHomeStyles } from '@r3/cbdc-asset-frontend-core';

type NetworkNavButtonProps = {
    domainName: string;
    splitUrl: string;
    text: string | undefined;
    imgUrl: string;
    defaultIcon: boolean;
    color: string;
};

const NetworkNavButton: React.FC<NetworkNavButtonProps> = (props) => {
    const { splitUrl, text, imgUrl, domainName, defaultIcon, color } = props;
    const classes = useHomeStyles();
    return (
        <Paper
            className={classes.linkButton}
            onClick={() => {
                window.open(`http://${domainName}${splitUrl}`, '_blank');
            }}
        >
            <div className={classes.innerDiv}>
                <Typography className={classes.linkText}>{text}</Typography>
            </div>
            <div className={classes.innerDiv}>
                {defaultIcon ? (
                    <AccountBalanceRoundedIcon style={{ color: color }} className={`${classes.linkIcon}`} />
                ) : (
                    <img className={`${classes.linkIcon}`} src={imgUrl} alt={text} />
                )}
            </div>
        </Paper>
    );
};

export default NetworkNavButton;
