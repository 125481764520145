import { useCallback, useState } from 'react';

import { AssetResponse } from '@r3/cbdc-asset-frontend-core';
import { requestAssetSummary } from '../api/assetsApi';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function useGetAssetSummary() {
    const [assetSummary, setAssetSummary] = useState<AssetResponse[]>([]);
    const [summaryError, setError] = useState<string>('');
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const getAssetSummary = useCallback(
        async (dontTrack?: boolean) => {
            const response = await requestAssetSummary(dontTrack);
            if (response.error && !dontTrack) {
                let errormsg = response.error;
                setError(t('error.assetSummary', { error: errormsg }));
            } else {
                const ResponsivePieData: AssetResponse[] = response.data;
                if (ResponsivePieData === null) {
                    return;
                }
                if (ResponsivePieData && ResponsivePieData.length <= 0 && !dontTrack) {
                    const warningText = t('transferAssets.noAssetsInVault');
                    enqueueSnackbar(warningText, {
                        variant: 'info',
                    });
                    setError((text) => text + (text.length > 0 ? '. ' : '') + warningText);
                }
                ResponsivePieData.forEach((assetResponse) => {
                    assetResponse.totalAmount = parseFloat(
                        assetResponse.totalAmount.toFixed(assetResponse.tokenDecimals)
                    );
                });
                setAssetSummary(ResponsivePieData);
            }
        },
        [enqueueSnackbar, t]
    );

    return { assetSummary, getAssetSummary, summaryError };
}
