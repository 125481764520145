import { TransactionRequestState } from '@r3/cbdc-asset-frontend-core';
import { useCallback, useEffect, useState } from 'react';

import { Switch } from '@material-ui/core';

export default function useFilterRequests() {
    const [filterRequestTypes, setFilterRequestTypes] = useState<TransactionRequestState[]>(
        Object.values(TransactionRequestState)
    );
    const [filterSwitches, setFilterSwitches] = useState(<></>);

    const switchFilter = useCallback(
        (state: TransactionRequestState) => {
            if (!filterRequestTypes.includes(state)) {
                setFilterRequestTypes((types) => types.concat([state]));
            } else {
                setFilterRequestTypes((types) => types.filter((type) => type !== state));
            }
        },
        [filterRequestTypes]
    );

    const displayRequestType = (requestType: TransactionRequestState) => {
        if (filterRequestTypes.includes(requestType)) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        const filters = (
            <div style={{ display: 'flex', color: 'white' }}>
                <div>
                    Approved
                    <Switch
                        id="ApprovedFilterSwitch"
                        checked={filterRequestTypes.includes(TransactionRequestState.APPROVED)}
                        onClick={() => switchFilter(TransactionRequestState.APPROVED)}
                    />
                </div>
                <div>
                    Requested
                    <Switch
                        id="RequestedFilterSwitch"
                        checked={filterRequestTypes.includes(TransactionRequestState.REQUESTED)}
                        onClick={() => switchFilter(TransactionRequestState.REQUESTED)}
                    />
                </div>
                <div>
                    Denied
                    <Switch
                        id="DeniedFilterSwitch"
                        checked={filterRequestTypes.includes(TransactionRequestState.DENIED)}
                        onClick={() => switchFilter(TransactionRequestState.DENIED)}
                    />
                </div>
            </div>
        );

        setFilterSwitches(filters);
    }, [filterRequestTypes, switchFilter]);

    return { filterSwitches, displayRequestType };
}
