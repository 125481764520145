import apiCall from './apiCall';

/**
 *
 * @remark
 * Used transfer (push) amount of asset to another wholesale party
 *
 * @param partyName Name of party pushing CBDC tokens to
 * @param amount The amount of tokens being pushed
 * @tokenIdentifier the token id for CBDC
 */
export const transferAssetsTo = async (tokenIdentifier: string, partyName: string, amount: string) =>
    apiCall({
        method: 'post',
        path: `/api/transact/pushTo`,
        params: {
            tokenIdentifier: tokenIdentifier,
            party: partyName,
            quantity: amount,
        },
    });
