import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useRefArchStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            question: {
                display: 'flex',
            },
            questionText: {
                color: theme.palette.primary.contrastText,
                fontSize: 16,
                marginTop: 'auto',
                marginBottom: 'auto',
                marginRight: 12,
                textAlign: 'left',
                width: 300,
                paddingLeft: '1.1em',
                textIndent: '-1.1em',
            },
            refArchImage: {
                objectFit: 'contain',
                maxHeight: '100%',
                maxWidth: '100%',
            },
            animate: {
                animation: `$modalZoom 500ms`,
            },
            '@keyframes modalZoom': {
                '0%': {
                    opacity: 0,
                    transform: 'scale(0)',
                },
                '100%': {
                    opacity: 1,
                    transform: 'scale(1)',
                },
            },
            titleText: {
                margin: '16px auto 20px 160px',
                width: 300,
                fontSize: 32,
                color: 'white',
            },
            docsButton: {
                margin: 'auto 62px auto 20px',
            },
            wrapper: {
                flexWrap: 'nowrap',
                [theme.breakpoints.down('md')]: {
                    flexWrap: 'wrap',
                },
            },
            questionsWrapper: {
                backgroundColor: theme.palette.primary.main,
                padding: 20,
                width: 600,
            },
            questionsLabel: {
                color: theme.palette.primary.contrastText,
                textAlign: 'center',
                maxWidth: 550,
                margin: '0 auto 16px auto',
                fontSize: 20,
            },
            refArchWrapper: { maxWidth: 1200, maxHeight: 720 },
            answerButton: {
                transition: 'all 500ms ease-in-out',
                '&:hover': { transition: 'all 300ms linear' },
            },
        }),
    { index: 1 }
);
