import apiCall from './apiCall';

/**
 * @remark
 * Used to get all of the bonds that the corresponding node holds
 *
 * @returns
 * AxiosResponse which resolves in bonds object or error
 */
export const requestAllBonds = async (dontTrack?: boolean) =>
    apiCall({
        method: 'get',
        path: '/api/bonds/summary',
        dontTrackRequest: dontTrack,
    });
