import { Divider, Fade, Paper } from '@material-ui/core';
import { KycRequest, KycState, useInteractiveChecklistContext } from '@r3/cbdc-asset-frontend-core';
import { issueKycFromRequest, requestAllKycRequests } from 'api/kycApi';
import { useCallback, useEffect, useState } from 'react';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton } from '@material-ui/core';
import { InfoWidget } from 'components/infoModal/InfoWidget/InfoWidget';
import { KycRequestDialogue } from './KycRequestDialogue';
import { ListHeader } from 'components/commonComponents/ListHeader/ListHeader';
import { ResolvedPromise } from 'api/resolvePromise';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useListStyles } from '@r3/cbdc-asset-frontend-core';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const KycRequests = () => {
    const listClasses = useListStyles();
    const layoutClasses = useLayoutStyles();
    const [kycRequestsByPublicKey, setKycRequestsByPublicKey] = useState<Map<string, KycState[]>>();
    const [collapsedKeys, setCollapesedKeys] = useState<string[]>([]);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState<string>('');

    const [selectedKycRequest, setSelectedKycRequest] = useState<KycRequest | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const { getAssetNamebyId, getAllAssets } = useGetAssetDefinitions();
    const [showInfoWidget, setShowInfoWidget] = useState<boolean>(false);
    const checkListContext = useInteractiveChecklistContext();
    const location = useLocation();

    const loadStates = useCallback(async () => {
        const kycStatesResponse: ResolvedPromise = await requestAllKycRequests();
        if (kycStatesResponse.error) {
            enqueueSnackbar(t('error.gettingKycStates', { error: kycStatesResponse.error }), {
                variant: 'error',
            });
            setError(kycStatesResponse.error.length > 0 ? kycStatesResponse.error : t('error.networkError'));
        } else {
            if (kycStatesResponse.data.length <= 0) {
                const warningText = t('listKyc.noKycRequests');
                enqueueSnackbar(warningText, {
                    variant: 'info',
                });
                setError((text) => text + (text.length > 0 ? '. ' : '') + warningText);
            }

            let groups = (kycStatesResponse.data as KycState[]).groupBy('publicKey') as Map<string, KycState[]>;
            setKycRequestsByPublicKey(groups);
            setCollapesedKeys(groups.size < 2 ? [] : Array.from(groups.keys()));

            checkListContext?.completeStep(location.pathname);
        }
    }, [t, enqueueSnackbar, location.pathname, checkListContext]);

    useEffect(() => {
        loadStates();
        getAllAssets();
    }, [loadStates, getAllAssets]);

    const submitApproval = async () => {
        if (!selectedKycRequest) {
            return;
        }

        const kycSubmittionResponse: ResolvedPromise = await issueKycFromRequest({
            id: selectedKycRequest.id.id,
            externalId: null,
        });

        if (kycSubmittionResponse.error) {
            enqueueSnackbar(t('error.approveKyc', { error: kycSubmittionResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.approveKyc'), {
                variant: 'success',
            });
            loadStates();
        }
    };

    const openInfoModal = (kycRequest: KycRequest) => {
        setSelectedKycRequest(kycRequest);
        setModalOpen(true);
    };

    const handleClose = () => {
        setSelectedKycRequest(null);
        setModalOpen(false);
    };

    const approve = async () => {
        handleClose();
        await submitApproval();
        setShowInfoWidget(true);
    };

    const handlePublicKeyClick = (key: string) => {
        if (collapsedKeys.includes(key)) {
            setCollapesedKeys((keys) => keys.filter((x) => x !== key));
        } else {
            setCollapesedKeys((keys) => keys.concat([key]));
        }
    };

    return (
        <>
            <Fade in={true}>
                <div className={`${layoutClasses.componentWrapper} `}>
                    <Paper elevation={5} className={`${layoutClasses.column} `}>
                        {error.length > 0 ? (
                            <ListHeader
                                text={error}
                                refreshAction={() => {
                                    setError('');
                                    loadStates();
                                }}
                            />
                        ) : (
                            <>
                                <ListHeader text={t('listKyc.listOfRequests')} refreshAction={loadStates} />
                                <Divider className={listClasses.divider} />
                                <div className={listClasses.list}>
                                    {kycRequestsByPublicKey !== undefined &&
                                        kycRequestsByPublicKey.mapGroups((publicKey, items) => {
                                            const publickKeyElement: JSX.Element = (
                                                <div
                                                    className={`${listClasses.sortKey} holderGrouping`}
                                                    data-testid={'holder_filter'}
                                                >
                                                    <div>
                                                        <p
                                                            className="stateHolderName"
                                                            data-testid={'holder_filter_name'}
                                                        >
                                                            <b>{t('listKyc.stateHolderName')} </b>
                                                            {items.length > 0 ? items[0].authorisedParty : ''}
                                                        </p>
                                                        <p data-testid={'holder_filter_id'}>
                                                            <b>{t('listKyc.stateHolderId')}</b> {publicKey}
                                                        </p>
                                                    </div>
                                                    <IconButton
                                                        className={`${listClasses.sortKeyButton} expandButton`}
                                                        onClick={() => handlePublicKeyClick(publicKey)}
                                                        data-testid={'holder_filter_expand'}
                                                    >
                                                        {collapsedKeys.includes(publicKey) ? (
                                                            <ExpandMoreIcon color="secondary" />
                                                        ) : (
                                                            <ExpandLessIcon color="secondary" />
                                                        )}
                                                    </IconButton>
                                                </div>
                                            );

                                            if (collapsedKeys.includes(publicKey)) {
                                                return <div key={publicKey}>{publickKeyElement} </div>;
                                            }

                                            return (
                                                <div key={publicKey}>
                                                    {publickKeyElement}
                                                    {items.map((kycRequest) => {
                                                        return (
                                                            <div
                                                                data-testid={'request_container'}
                                                                key={publicKey + '_' + kycRequest.id}
                                                                className={`${listClasses.item} ${listClasses.clickableItem} kycState`}
                                                                onClick={() => openInfoModal(kycRequest)}
                                                            >
                                                                <div>
                                                                    <div className={`${listClasses.text} assetName`}>
                                                                        {t('defineAsset.assetName') + ': '}
                                                                        {getAssetNamebyId(kycRequest.tokenIdentifier)}
                                                                    </div>
                                                                    <div className={`${listClasses.text} assetId`}>
                                                                        {t('commonText.assetId') + ': '}
                                                                        {kycRequest.tokenIdentifier}
                                                                    </div>
                                                                    <div
                                                                        className={`${listClasses.text} memberAccessId`}
                                                                    >
                                                                        {t('commonText.kycId') + ': '}
                                                                        {kycRequest.id.id}
                                                                    </div>
                                                                </div>
                                                                <TouchAppIcon
                                                                    id="TouchIcon"
                                                                    color="secondary"
                                                                    className={listClasses.requestIcon}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}
                                </div>
                                <Divider className={listClasses.divider} />

                                {selectedKycRequest && modalOpen && (
                                    <KycRequestDialogue
                                        open={modalOpen}
                                        requester={selectedKycRequest.authorisedParty}
                                        kycId={selectedKycRequest.id.id}
                                        assetName={getAssetNamebyId(selectedKycRequest.tokenIdentifier)}
                                        approve={approve}
                                        onClose={handleClose}
                                    />
                                )}
                            </>
                        )}
                    </Paper>
                </div>
            </Fade>
            <InfoWidget showing={showInfoWidget} />
        </>
    );
};
