import { Asset, InterestLog, TimePoint } from '@r3/cbdc-asset-frontend-core';
import {
    getMoneySupplyData,
    getMoneySupplyWithExpectedInterest,
    getMoneySupplyWithInterest,
} from 'api/financialReportApi';
import { useCallback, useState } from 'react';

export function parseDate<T>(items: T[], key: string) {
    items.forEach((i) => {
        i[key] = new Date(i[key]);
    });
}

const useMoneySupplyData = () => {
    const [assetData, setAssetData] = useState<TimePoint[]>([]);
    const [interestData, setInterestData] = useState<InterestLog[]>([]);
    const [expectedInterestData, setExpectedInterestData] = useState<InterestLog[]>([]);

    const fetchMoneySupply = useCallback(async (selectedAsset: Asset, nDataPoints: number) => {
        const response = await getMoneySupplyData(selectedAsset.tokenIdentifier, nDataPoints);
        if (response.data === null) {
            setAssetData([]);
            return;
        }

        parseDate(response.data.data, 'x');
        setAssetData(response.data.data);
        setInterestData([]);
        setExpectedInterestData([]);

        const firstItem = response.data.data[0];
        if (firstItem === undefined) {
            return;
        }

        const interestResponse = await getMoneySupplyWithInterest(selectedAsset.tokenIdentifier, firstItem.x);
        const expectedInterestResponse = await getMoneySupplyWithExpectedInterest(
            selectedAsset.tokenIdentifier,
            firstItem.x
        );
        if (interestResponse.data != null) {
            parseDate(interestResponse.data, 'effectiveStartDate');
            setInterestData(interestResponse.data);
        }
        if (expectedInterestResponse.data != null) {
            parseDate(expectedInterestResponse.data, 'effectiveStartDate');
            setExpectedInterestData(expectedInterestResponse.data);
        }
    }, []);

    return { assetData, interestData, expectedInterestData, fetchMoneySupply };
};
export default useMoneySupplyData;
