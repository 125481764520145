import { Asset, useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { Divider, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@material-ui/core';

type SelectAssetStepProps = {
    assetName: string | undefined;
    handleAssetChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
    assets: Asset[];
    headerText: string;
};

const SelectAssetStep: React.FC<SelectAssetStepProps> = (props) => {
    const { assetName, handleAssetChange, assets, headerText } = props;
    const layoutClasses = useLayoutStyles();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>{headerText}</Typography>
                <Divider className={layoutClasses.summaryDivider} />
                <FormControl
                    id="SelectAsset"
                    variant="outlined"
                    className={`${layoutClasses.selectInput} ${layoutClasses.columnNoPadding}`}
                >
                    <InputLabel id="demo-simple-select-helper-label">Asset</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={assetName ?? ''}
                        onChange={handleAssetChange}
                        data-testid={'asset-select'}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {assets.map((as, i) => {
                            return (
                                <MenuItem key={as.tokenName + i} value={as.tokenName} data-testid={'asset-option'}>
                                    {as.tokenName}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Paper>
        </div>
    );
};

export default SelectAssetStep;
