import { Divider, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@material-ui/core';

import React from 'react';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useTranslation } from 'react-i18next';

type SelectParticipantStepProps = {
    participants: string[];
    selectedParticipant: string;
    handleParticipantChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
};

const SelectParticipantStep: React.FC<SelectParticipantStepProps> = (props) => {
    const { selectedParticipant, participants, handleParticipantChange } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>{t('issueAsset.selectParticipant')}</Typography>
                <Divider className={layoutClasses.summaryDivider} />
                <FormControl
                    id="SelectParticipant"
                    variant="outlined"
                    className={`${layoutClasses.selectInput} ${layoutClasses.columnNoPadding}`}
                >
                    <InputLabel id="demo-simple-select-helper-label">{t('commonText.participant')}</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectedParticipant}
                        onChange={handleParticipantChange}
                        data-testid={'participant-select'}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {participants.map((participant, i) => {
                            return (
                                <MenuItem key={participant + i} value={participant}>
                                    {participant}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Paper>
        </div>
    );
};

export default SelectParticipantStep;
