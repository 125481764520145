import { Bond, CBDCIssuanceRequest, KycState, NumberFormat, RedemptionRequest } from '@r3/cbdc-asset-frontend-core';
import { useEffect, useState } from 'react';

import { NOTIFICATIONPOLLINGINTERVAL } from '../../constants/PollingIntervals';
import { ResolvedPromise } from '../../api/resolvePromise';
import { emptyBond } from '../treasuryDashboard/BondsDashboard/BondsDashboard';
import { requestAllBonds } from '../../api/bondsApi';
import { requestAllIssuanceRequests } from '../../api/issuanceApi';
import { requestAllKycRequests } from '../../api/kycApi';
import { requestAllRedemptionRequests } from '../../api/redemptionApi';
import useGetAssetDefinitions from '../../hooks/GetAssetDefinitions';
import { useInterval } from '@r3/cbdc-asset-frontend-core';
import { useSnackbar } from 'notistack';

export const PollingNotificationService = () => {
    const [pollingInterval, setPollingInterval] = useState<number | null>(NOTIFICATIONPOLLINGINTERVAL);
    const [pollCount, setPollCount] = useState<number>(0);
    const [issuancePollCount, setIssuancePollCount] = useState<number>(0);
    const [redemptionPollCount, setRedemptionPollCount] = useState<number>(0);
    const [existingKycRequests, setExistingKycRequests] = useState<KycState[]>([]);
    const [existingIssuanceRequests, setExisitingIssuanceRequests] = useState<CBDCIssuanceRequest[]>([]);
    const [existingRedemptionRequests, setExistingRedemptionRequests] = useState<RedemptionRequest[]>([]);
    const [existingBonds, setExistingBonds] = useState<Bond>(emptyBond);
    const { enqueueSnackbar } = useSnackbar();
    const { getAssetNamebyId, getAllAssets, assets } = useGetAssetDefinitions();
    const [fetching, setFetching] = useState<boolean>(false);

    const getKycRequests = async () => {
        const kycStatesResponse: ResolvedPromise = await requestAllKycRequests(true);
        if (kycStatesResponse.error) {
        } else {
            const states = kycStatesResponse.data as KycState[];
            if (states.length !== existingKycRequests.length && pollCount > 1) {
                checkForNewKycRequests(states);
            }
            setExistingKycRequests(states);
        }
    };

    const checkForNewKycRequests = (states: KycState[]) => {
        const newStates = states.filter((s) => !stringifyAndIncludes(existingKycRequests, s));
        newStates.forEach((state) => {
            enqueueSnackbar(
                `New Member Access State request received from ${
                    state.authorisedParty
                } for access to CBDC: ${getAssetNamebyId(state.tokenIdentifier)}.`,
                {
                    variant: 'info',
                    autoHideDuration: 10000,
                }
            );
        });
    };

    const getAllcbdcIssuanceRequests = async () => {
        const issuanceRequestsResponse: ResolvedPromise = await requestAllIssuanceRequests(true);
        if (issuanceRequestsResponse.error) {
        } else {
            if (!issuanceRequestsResponse.data) {
                return;
            }
            const requests = issuanceRequestsResponse.data as CBDCIssuanceRequest[];
            if (requests.length !== existingIssuanceRequests.length && issuancePollCount > 1) {
                checkForNewcbdcIssuanceRequests(requests);
            }
            setExisitingIssuanceRequests(issuanceRequestsResponse.data as CBDCIssuanceRequest[]);
        }
    };

    const getAllRedemptionRequests = async () => {
        const redemptionRequestResponse: ResolvedPromise = await requestAllRedemptionRequests(true);
        if (redemptionRequestResponse.error) {
        } else {
            if (!redemptionRequestResponse.data) {
                return;
            }
            const requests = redemptionRequestResponse.data as RedemptionRequest[];
            if (requests.length !== existingRedemptionRequests.length && redemptionPollCount > 1) {
                checkForNewRedemptionRequests(requests);
            }
            setExistingRedemptionRequests(redemptionRequestResponse.data as RedemptionRequest[]);
        }
    };

    const checkForNewRedemptionRequests = (states: RedemptionRequest[]) => {
        const newStates = states.filter((s) => !stringifyAndIncludes(existingRedemptionRequests, s));

        newStates.forEach((request) => {
            enqueueSnackbar(
                `New CBDC Redemption request from ${request.requestorParty}, redeeming CBDC: ${request.token.tokenName}.`,
                {
                    variant: 'info',
                    autoHideDuration: 10000,
                }
            );
        });
    };

    const checkForNewcbdcIssuanceRequests = (states: CBDCIssuanceRequest[]) => {
        const newStates = states.filter((s) => !stringifyAndIncludes(existingIssuanceRequests, s));

        newStates.forEach((request) => {
            enqueueSnackbar(
                `New CBDC Issuance request from ${request.requestorParty} for access to CBDC: ${request.token.tokenName}.`,
                {
                    variant: 'info',
                    autoHideDuration: 10000,
                }
            );
        });
    };

    const getAllBondsData = async () => {
        const bondsResponse: ResolvedPromise = await requestAllBonds(true);
        if (bondsResponse.error) {
            return;
        } else {
            let bonds = bondsResponse.data as Bond;
            if (bonds.usdValue !== existingBonds.usdValue && pollCount > 1) {
                if (bonds.usdValue > existingBonds.usdValue) {
                    enqueueSnackbar(
                        `A total of ${NumberFormat.addThousandSeparators(
                            (bonds.usdValue - existingBonds.usdValue).toFixed(3)
                        )} bonds has been added to your vault`,
                        {
                            variant: 'info',
                            autoHideDuration: 10000,
                        }
                    );
                } else {
                    enqueueSnackbar(
                        `A total of ${NumberFormat.addThousandSeparators(
                            (existingBonds.usdValue - bonds.usdValue).toFixed(3)
                        )} bonds has been removed from, your vault`,
                        {
                            variant: 'info',
                            autoHideDuration: 10000,
                        }
                    );
                }
            }
            setExistingBonds(bonds);
        }
    };

    useInterval(() => {
        if (!fetching) {
            setFetching(true);
        }
    }, pollingInterval);

    useEffect(() => {
        const fetchIssuanceRequests = async () => {
            await getAllcbdcIssuanceRequests();
            setIssuancePollCount(issuancePollCount + 1);
        };

        const fetchRedemptionRequests = async () => {
            await getAllRedemptionRequests();
            setRedemptionPollCount(redemptionPollCount + 1);
        };

        const fetchAllData = async () => {
            let receivedResponse = await getAllAssets(true);
            if (receivedResponse) {
                await getKycRequests();
                await fetchIssuanceRequests();
                await fetchRedemptionRequests();
                await getAllBondsData();
                if (assets.length === 0 && pollCount > 0) {
                    setPollCount(0);
                } else {
                    setPollCount(pollCount + 1);
                }
            } else {
                setPollingInterval(null);
                enqueueSnackbar(
                    `Live notifcations have stopped, error contacting server. Please refresh to try again.`,
                    {
                        variant: 'info',
                        persist: true,
                    }
                );
            }
            setFetching(false);
        };

        if (fetching) {
            fetchAllData();
        }
        // eslint-disable-next-line
    }, [fetching]);

    return <> </>;
};

const stringifyAndIncludes = (assets: any[], a): boolean => {
    let stringified = assets.map((a) => JSON.stringify(a));
    return stringified.includes(JSON.stringify(a));
};
