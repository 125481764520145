import * as ROUTES from '../../../constants/Routes';

import { AppBar, Button, Paper, SvgIcon, Toolbar, Typography } from '@material-ui/core';
import { useAppConfigContext, useTopNavWrapperStyles } from '@r3/cbdc-asset-frontend-core';

import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
import { ReactComponent as CBIcon } from 'icons/centralbank.svg';
import { Icon } from '@material-ui/core';
import NavButtons from '../NavButtons/NavButtons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useIssuerPages from 'hooks/useIssuerPages';

interface Props {
    navButton: React.ReactNode;
    toggleNotificationService: () => void;
    receiveNotifications: boolean;
}

const TopNavWrapper: React.FC<Props> = ({ navButton, toggleNotificationService, receiveNotifications }) => {
    const history = useHistory();
    const classes = useTopNavWrapperStyles();
    const appConfigContext = useAppConfigContext();
    const brandIconSource =
        appConfigContext && appConfigContext?.tempBrandIconSource.length > 0
            ? appConfigContext?.tempBrandIconSource
            : `/appConfig/brandedIcon.png`;
    const appIconSource =
        appConfigContext && appConfigContext?.tempAppIconSource.length > 0
            ? appConfigContext?.tempAppIconSource
            : `/appConfig/appicon.png`;
    const defaultIcons = appConfigContext?.appConfig.defaultIcons === 'true' ? true : false;

    const { pages } = useIssuerPages();

    return (
        <Paper elevation={5} square className={classes.grow}>
            <AppBar className={classes.appBar} elevation={0} position="static">
                <Toolbar>
                    {navButton}
                    {defaultIcons && (
                        <SvgIcon
                            id="AppIcon"
                            className={classes.walletsIcon}
                            onClick={() => {
                                history.push('/');
                            }}
                        >
                            <CBIcon />
                        </SvgIcon>
                    )}
                    {!defaultIcons && (
                        <img
                            id="AppIcon"
                            onClick={() => {
                                history.push('/');
                            }}
                            src={appIconSource}
                            height={55}
                            width={55}
                            alt="App Icon"
                        />
                    )}
                    <Typography className={classes.title} variant="h6" noWrap>
                        {appConfigContext?.appConfig.appName}
                    </Typography>
                    <Typography className={classes.subTitle} variant="body1" noWrap>
                        Powered By
                    </Typography>

                    <img className={classes.cordaImg} src={`/cordawht.png`} height={30} width={75} alt="Corda R3" />

                    <div className={classes.grow}></div>
                    <Button
                        className={`navButton  ${classes.buttonNav} ${
                            history.location.pathname.includes(ROUTES.NETWORKTAB) ? classes.selected : ''
                        }`}
                        startIcon={<AccountTreeRoundedIcon />}
                        color="primary"
                        href={ROUTES.NETWORKTAB}
                    >
                        {'Network'}
                    </Button>
                    <div className={classes.sectionDesktop}>
                        <Icon className={classes.appIcon}>
                            <img src={brandIconSource} alt={'r3'} height={35} width={35} />
                        </Icon>
                    </div>
                </Toolbar>
            </AppBar>
            {/* <Divider className={classes.divider} /> */}
            <div className={classes.navButtonContainer}>
                <NavButtons
                    navButtons={pages
                        .filter((page) => typeof page.navButton !== 'undefined')
                        .map((page) => page.navButton!)}
                    receiveNotifications={receiveNotifications}
                    toggleNotificationService={toggleNotificationService}
                />
            </div>
        </Paper>
    );
};

export default TopNavWrapper;
