import { Bond, NumberFormat, ResponsivePieData, useInteractiveChecklistContext } from '@r3/cbdc-asset-frontend-core';
import { DASHBOARDANIMATIONENTERMS, DASHBOARDANIMATIONEXITMS } from '../../../constants/Animations';
import { Divider, Fade, Paper, Slide } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import { BONDSPOLLINGINTERVAL } from '../../../constants/PollingIntervals';
import { DashBoardResponsivePie } from '../DashboardComponents/DashboardResponsivePie';
import { InfoBox } from '../DashboardComponents/InfoBox';
import { useDashboardStyles } from '@r3/cbdc-asset-frontend-core';
import useGetBondsSummary from '../../../hooks/GetBondsSummary';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const emptyBond: Bond = {
    usdValue: 0,
};

export const BondsDashboard: React.FC = () => {
    const [pieData, setPieData] = useState<ResponsivePieData[]>([]);
    const { t } = useTranslation();
    const layoutClasses = useLayoutStyles();
    const classes = useDashboardStyles();
    const checkListContext = useInteractiveChecklistContext();
    const location = useLocation();
    const { bonds, getAllBondsData } = useGetBondsSummary();

    const handlePieClick = () => {};

    const updateChart = useCallback(() => {
        let responsePieData: ResponsivePieData[] = [];
        responsePieData.push({
            id: bonds.usdValue.toString(),
            label: NumberFormat.addThousandSeparators(bonds.usdValue).toString(),
            value: bonds.usdValue,
            color: 'white',
        });
        setPieData(responsePieData);
    }, [bonds]);

    useEffect(() => {
        getAllBondsData();
    }, [getAllBondsData]);

    useEffect(() => {
        if (BONDSPOLLINGINTERVAL <= 0) {
            return;
        }
        const interval = setInterval(function () {
            getAllBondsData(true);
        }, BONDSPOLLINGINTERVAL);

        return () => {
            clearInterval(interval);
        };
    }, [getAllBondsData]);

    useEffect(() => {
        checkListContext?.completeStep(location.pathname);
        updateChart();
    }, [updateChart, location.pathname, checkListContext]);

    return (
        <>
            <Fade in={true}>
                <Paper elevation={5} className={`${layoutClasses.componentWrapper}`}>
                    <InfoBox title={t('dashBoard.bondValue')} content={`1:1 to CBDC`} />
                    <InfoBox title={t('dashBoard.totalBondsInVault')} content={bonds.usdValue.toFixed(3).toString()} />
                </Paper>
            </Fade>

            {bonds.usdValue > 0 && (
                <div className={layoutClasses.componentWrapper}>
                    <Slide
                        direction="left"
                        in={true}
                        timeout={{ enter: DASHBOARDANIMATIONENTERMS, exit: DASHBOARDANIMATIONEXITMS }}
                    >
                        <div
                            className={`${layoutClasses.column} ${layoutClasses.columnFill} ${layoutClasses.columnNoPadding} `}
                        >
                            <div id="PieGraph" className={` ${classes.nivoWrapper}`} style={{ overflow: 'hidden' }}>
                                <DashBoardResponsivePie
                                    altColour={true}
                                    data={pieData}
                                    handlePieClick={handlePieClick}
                                />
                                <Divider className={classes.divider} />
                            </div>
                        </div>
                    </Slide>
                </div>
            )}
        </>
    );
};
