import {
    Asset,
    ComplianceRuleKey,
    StepperFormStep,
    useInteractiveChecklistContext,
} from '@r3/cbdc-asset-frontend-core';
import React, { useEffect, useState } from 'react';

import EnterAmountStep from './EnterAmountStep';
import { Fade } from '@material-ui/core';
import IssueSummaryStep from './IssueSummaryStep';
import { ResolvedPromise } from 'api/resolvePromise';
import SelectAssetStep from 'components/commonComponents/GenericFormSteps/SelectAssetStep';
import SelectParticipantStep from './SelectParticipantStep';
import { StepperForm } from '@r3/cbdc-asset-frontend-core';
import { issueAssetToMember } from 'api/assetsApi';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import useGetKycStates from 'hooks/useGetKycStates';
import useGetOurOwnIdentity from 'hooks/GetOurOwnIdentity';
import useGetParticipantsOnNetwork from 'hooks/GetParticipantsOnNetwork';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

type Props = {
    ignoreInfoModal?: boolean;
    onSuccessfulSubmit?: () => void;
};
export const IssueCBDC: React.FC<Props> = ({ onSuccessfulSubmit, ignoreInfoModal = false }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [selectedParticipant, setSelectedParticipant] = useState<string>('');
    const [selectedAsset, setSelectedAsset] = useState<Asset>({
        tokenDecimals: 0,
        tokenIdentifier: '',
        tokenName: '',
        party: '',
        complianceRules: [],
    });
    const [assetAmount, setAssetAmount] = useState<string>('0');
    const [error, setError] = useState<string>('');
    const { participants, participantsError, getParticipants } = useGetParticipantsOnNetwork();
    const { assets, getAllAssets, assetsError, getAssetNamebyId } = useGetAssetDefinitions();
    const { t } = useTranslation();
    const checkListContext = useInteractiveChecklistContext();
    const location = useLocation();
    const { kycStates, getKycStates } = useGetKycStates();
    const { identity, getOwnIdentity } = useGetOurOwnIdentity();

    const handleReset = () => {
        setSelectedParticipant('');
        setAssetAmount('0');
    };

    useEffect(() => {
        setError(assetsError);
    }, [assetsError]);

    useEffect(() => {
        setError(participantsError);
    }, [participantsError]);

    const submitIssuance = async () => {
        const issuanceResponse: ResolvedPromise = await issueAssetToMember(
            selectedAsset.tokenIdentifier,
            selectedParticipant,
            parseFloat(assetAmount)
        );

        if (issuanceResponse.error) {
            enqueueSnackbar(t('error.issuingAsset', { error: issuanceResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.issueAsset'), {
                variant: 'success',
            });
            checkListContext?.completeStep(location.pathname);

            if (onSuccessfulSubmit) {
                onSuccessfulSubmit();
            }
        }

        handleReset();
    };

    useEffect(() => {
        getKycStates(true);
        getAllAssets();
    }, [getAllAssets, getKycStates]);

    useEffect(() => {
        getParticipants();
        getOwnIdentity();
    }, [enqueueSnackbar, t, getAllAssets, getParticipants, getOwnIdentity]);

    const handleParticipantChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedParticipant(event.target.value as string);
    };

    const getSelectedAsset = (assetName: string): Asset => {
        let selectedAsset: Asset = {
            tokenDecimals: 0,
            tokenIdentifier: '',
            tokenName: '',
            party: '',
            complianceRules: [],
        };
        assets.forEach((asset) => {
            if (assetName === asset.tokenName) {
                selectedAsset = asset;
            }
        });

        return selectedAsset;
    };

    const handleAssetChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let asset = getSelectedAsset(event.target.value as string);
        setAssetAmount('0');
        setSelectedAsset(asset);
    };

    const getComplyingParticipants = (): string[] => {
        const allParticipants = participants.participants.concat([identity]);
        if (selectedAsset.complianceRules?.findIndex((rule) => rule.key === ComplianceRuleKey.MemberAccess) === -1) {
            return allParticipants;
        } else {
            return allParticipants.filter(
                (p) =>
                    kycStates.findIndex(
                        (kyc) =>
                            getAssetNamebyId(kyc.tokenIdentifier) === selectedAsset.tokenName &&
                            p === kyc.authorisedParty
                    ) > -1
            );
        }
    };

    const steps: StepperFormStep[] = [
        {
            title: t('issueAsset.selectAsset'),
            component: (
                <SelectAssetStep
                    headerText={t('issueAsset.selectAvailableAsset')}
                    assetName={selectedAsset.tokenName}
                    handleAssetChange={handleAssetChange}
                    assets={assets}
                />
            ),
            isNextStepDisabled: () => {
                if (selectedAsset.tokenName.length > 0) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('issueAsset.selectParticipantStep'),
            component: (
                <SelectParticipantStep
                    selectedAsset={selectedAsset}
                    getComplyingParticipants={getComplyingParticipants}
                    selectedParticipant={selectedParticipant}
                    handleParticipantChange={handleParticipantChange}
                />
            ),
            isNextStepDisabled: () => {
                if (selectedParticipant.length > 0) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('issueAsset.selectAmount'),
            component: (
                <EnterAmountStep
                    decimalPlaces={selectedAsset.tokenDecimals}
                    assetAmount={assetAmount}
                    setAssetAmount={setAssetAmount}
                />
            ),
            isNextStepDisabled: () => {
                if (assetAmount.length > 0 && parseFloat(assetAmount) >= 10) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('issueAsset.confirmIssuance'),
            component: (
                <IssueSummaryStep
                    selectedAssetName={selectedAsset.tokenName}
                    selectedParticipant={selectedParticipant}
                    assetAmount={assetAmount}
                />
            ),
            isNextStepDisabled: () => {
                return false;
            },
        },
    ];

    return (
        <>
            <Fade in={true}>
                <StepperForm
                    submit={submitIssuance}
                    steps={steps}
                    submitButtonText={t('commonText.issue')}
                    handleReset={handleReset}
                    renderError={error}
                    ignoreInfoModal={ignoreInfoModal}
                />
            </Fade>
        </>
    );
};
