import { useAppConfigContext, useHomeStyles } from '@r3/cbdc-asset-frontend-core';

import NetworkMap from '../../svgs/NetworkMap';
import NetworkNavButton from './NetworkNavButton';
import { Typography } from '@material-ui/core';

export const NetworkTab = () => {
    const url = window.location.href;
    const classes = useHomeStyles();
    const appConfigContext = useAppConfigContext();
    const buttons: JSX.Element[] = [];
    const defaultIcons = appConfigContext?.appConfig.defaultIcons === 'true' ? true : false;
    const wholesaleColour = appConfigContext ? appConfigContext?.appConfig.wholesaleBankColour : 'white';
    if (url.includes('cbdc-sandbox')) {
        const splitUrl = url.substring(url.indexOf('.'), url.length);
        if (!appConfigContext?.appConfig.appType.includes('wholesaleone')) {
            buttons.push(
                <NetworkNavButton
                    key={'wholesaleone'}
                    domainName={'wholesaleone'}
                    splitUrl={splitUrl}
                    text={appConfigContext?.appConfig.wholesaleBankOneName}
                    imgUrl={'/appConfig/networkTabIcons/wholesaleBankOne.png'}
                    defaultIcon={defaultIcons}
                    color={wholesaleColour}
                />
            );
        }

        if (!appConfigContext?.appConfig.appType.includes('wholesaletwo')) {
            buttons.push(
                <NetworkNavButton
                    key={'wholesaletwo'}
                    domainName={'wholesaletwo'}
                    splitUrl={splitUrl}
                    text={appConfigContext?.appConfig.wholesaleBankTwoName}
                    imgUrl={'/appConfig/networkTabIcons/wholesaleBankTwo.png'}
                    defaultIcon={defaultIcons}
                    color={wholesaleColour}
                />
            );
        }

        if (!appConfigContext?.appConfig.appType.includes('wholesalethree')) {
            buttons.push(
                <NetworkNavButton
                    key={'wholesalethree'}
                    domainName={'wholesalethree'}
                    splitUrl={splitUrl}
                    text={appConfigContext?.appConfig.wholesaleBankThreeName}
                    imgUrl={'/appConfig/networkTabIcons/wholesaleBankThree.png'}
                    defaultIcon={defaultIcons}
                    color={wholesaleColour}
                />
            );
        }
    }

    return (
        <>
            {url.includes('cbdc-sandbox') && (
                <>
                    <div style={{ display: 'flex', marginTop: 20 }}>
                        <Typography className={classes.hometext}>CBDC Sandbox Network</Typography>
                    </div>
                    <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 40, width: 500, height: 400 }}>
                        <NetworkMap />
                    </div>
                    <div style={{ display: 'flex', marginTop: 0, marginBottom: 12 }}>
                        <Typography className={classes.appTitle}>
                            You are on '<span>{appConfigContext?.appConfig.appName}'</span>
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', marginTop: 0, marginBottom: 24 }}>
                        <Typography className={classes.subTitle}>
                            Click buttons below to access the other clients.
                        </Typography>
                    </div>
                    <div className={classes.linkWrapper}>{buttons}</div>
                </>
            )}
        </>
    );
};
