import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.css';
import './i18n/i18n';

import {
    AppConfigContextProvider,
    FeatureFlagContextProvider,
    InfoModalContextProvider,
    InteractiveChecklistContextProvider,
} from '@r3/cbdc-asset-frontend-core';

import { CssBaseline } from '@material-ui/core';
import { FEATURE_PAGES } from 'featureFlags/featureFlags';
import { INFO_MODAL_PAGES } from 'constants/InfoModal';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeController } from './components/themeController/ThemeController';
import reportWebVitals from './reportWebVitals';
import useInteractiveChecklist from 'hooks/useInteractiveChecklist';

const App = () => {
    const { CHECKLIST_ACTIONS } = useInteractiveChecklist();

    return (
        <Router>
            <AppConfigContextProvider>
                <InteractiveChecklistContextProvider checklistActions={CHECKLIST_ACTIONS}>
                    <InfoModalContextProvider infoModalPages={INFO_MODAL_PAGES}>
                        <ThemeController>
                            <CssBaseline />
                        </ThemeController>
                    </InfoModalContextProvider>
                </InteractiveChecklistContextProvider>
            </AppConfigContextProvider>
        </Router>
    );
};

ReactDOM.render(
    <FeatureFlagContextProvider defaultFeaturePages={FEATURE_PAGES}>
        <App />
    </FeatureFlagContextProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
