import React, { useEffect, useState } from 'react';

import { TextField } from '@material-ui/core';
import { NumberFormat, useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { isCurrencyInputValid } from './isCurrencyInputValid';

type Props = {
    decimalPlaces: number;
    setValue: (value: string) => void;
    label: string;
    testId: string;
    invalidInput?: boolean;
};

export const CurrencyInput: React.FC<Props> = ({ decimalPlaces, setValue, label, testId, invalidInput }) => {
    const layoutClasses = useLayoutStyles();
    const [localVal, setLocalVal] = useState<string>('');

    const handleInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event.preventDefault();
        let value = NumberFormat.removeThousandSeparators(event.target.value);
        if (isCurrencyInputValid(value, decimalPlaces)) {
            if (decimalPlaces === 0) {
                value = NumberFormat.removeLeadingZeros(value);
            }
            setLocalVal(value);
        }
    };

    useEffect(() => {
        setValue(localVal);
    }, [localVal, setValue]);

    const adjustAssetAmount = () => {
        setLocalVal(NumberFormat.stringToFixed(localVal, decimalPlaces));
    };

    return (
        <>
            <TextField
                error={invalidInput}
                className={`${layoutClasses.formInput} ${layoutClasses.formInputSmall} currencyInput`}
                label={label}
                onChange={handleInput}
                value={NumberFormat.addThousandSeparators(localVal)}
                required
                variant="filled"
                data-testid={testId}
                onBlur={adjustAssetAmount}
            />
        </>
    );
};
