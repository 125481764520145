import {
    DialogAction,
    DialogActions,
    DialogBody,
    DialogWindow,
    RedemptionRequest,
    NumberFormat,
} from '@r3/cbdc-asset-frontend-core';

import { ReactComponent as BondsIcon } from 'icons/bonds.svg';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { SvgIcon } from '@material-ui/core';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next/';

export interface SimpleDialogProps {
    open: boolean;
    request: RedemptionRequest;
    approve: () => void;
    decline: () => void;
    onClose: () => void;
}

export function RedemptionRequestDialog(props: SimpleDialogProps) {
    const { onClose, approve, request, open, decline } = props;
    const { t } = useTranslation();

    return (
        <DialogWindow title={'Redemption Request : ' + request.requestState} open={open} onClose={onClose}>
            <DialogBody>
                <Typography gutterBottom>
                    {t('cbdcRequests.requestId') + ':  '}
                    {request.requestId}
                </Typography>
                <Typography gutterBottom>
                    {t('listKyc.requester') + '   '}
                    {request.requestorParty}
                </Typography>
                <Typography gutterBottom>
                    {t('defineAsset.assetName') + ':  '}
                    {request.token.tokenName}
                </Typography>
                <Typography gutterBottom>
                    {'CBDC to redeem:  '}
                    {NumberFormat.addThousandSeparators(request.requestedAmount)}
                </Typography>
                <div style={{ display: 'flex' }}>
                    <Typography gutterBottom> {'Requested Bonds  '}</Typography>
                    <SvgIcon style={{ width: 18, height: 18 }}>
                        <BondsIcon />
                    </SvgIcon>
                    <Typography gutterBottom>
                        {':  ' + NumberFormat.addThousandSeparators(request.requestedAmount)}
                    </Typography>
                </div>
            </DialogBody>
            {!(request.requestState === 'APPROVED' || request.requestState === 'DENIED') && (
                <DialogActions>
                    <DialogAction text={t('commonText.approve')} action={approve} icon={<ThumbUpIcon />} />
                    <DialogAction text={t('commonText.deny')} action={decline} icon={<NotInterestedIcon />} />
                </DialogActions>
            )}
        </DialogWindow>
    );
}
