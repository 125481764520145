import { Button, Typography } from '@material-ui/core';
import { REFERENCEARCHITECTURES, ReferenceArchitecture } from '../../constants/ReferenceArchitectures';
import React, { useEffect, useState } from 'react';

import { Header } from '@r3/cbdc-asset-frontend-core';
import RefArchQuestions from './RefArchcitectureQuestions';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useRefArchStyles } from '../../materialStyles/refArchStyles';

const ReferenceArchitectures: React.FC = () => {
    const layoutClasses = useLayoutStyles();
    const classes = useRefArchStyles();
    const [currentRefArch, setCurrentRefArch] = useState<ReferenceArchitecture>(REFERENCEARCHITECTURES[0]);
    const [animate, setAnimate] = useState<boolean>(false);

    useEffect(() => {
        setAnimate(true);
        setTimeout(() => {
            setAnimate(false);
        }, 520);
    }, [currentRefArch]);

    return (
        <div>
            <Header>Reference Models</Header>
            <div className={`${layoutClasses.componentWrapper} ${classes.wrapper}`}>
                <div
                    className={`${layoutClasses.column} ${layoutClasses.columnMed} ${layoutClasses.columDark}`}
                    style={{ marginTop: 0, display: 'block' }}
                >
                    <Typography className={classes.questionsLabel}>
                        Answer the questions below to see some example CBDC reference models that R3 has created.
                    </Typography>
                    <RefArchQuestions currentRefArch={currentRefArch} setRefArch={setCurrentRefArch} />
                </div>
                <div className={`${layoutClasses.columnFill}`}>
                    <div className={`${classes.refArchWrapper}`}>
                        <div style={{ display: 'flex' }}>
                            <div className={classes.titleText}>{currentRefArch.title}</div>
                            <Button
                                className={`${layoutClasses.button} ${layoutClasses.buttonAutoSize} ${classes.docsButton}`}
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    window.open(currentRefArch.docLink, '_blank');
                                }}
                                disabled={typeof currentRefArch.docLink === 'undefined'}
                            >
                                {typeof currentRefArch.docLink === 'undefined' ? 'No Docs Available' : 'Docs'}
                            </Button>
                        </div>
                        <img
                            alt="Reference architecture."
                            data-testid={'refImage'}
                            className={`${classes.refArchImage} ${animate ? classes.animate : ''}`}
                            src={currentRefArch.filePath}
                        ></img>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReferenceArchitectures;
