import { Divider, Paper, Typography } from '@material-ui/core';

import { InterestRateState } from '@r3/cbdc-asset-frontend-core/lib/esm/models/asset';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useTranslation } from 'react-i18next';

type Props = {
    interestRateState: InterestRateState | undefined;
};

const InterestRateUpdateSummary: React.FC<Props> = ({ interestRateState }) => {
    const layoutClasses = useLayoutStyles();
    const { t: text } = useTranslation();

    if (!interestRateState) {
        return <></>;
    }

    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`} data-testid="summary-message">
                    {text('interestRates.updateInterestRateSummary')}
                </Typography>
                <Divider className={layoutClasses.summaryDivider} />
                <Typography className={`${layoutClasses.text}`} data-testid="summary-message">
                    {text('interestRates.interestValue') + ': ' + interestRateState.values[0].yearlyInterest + ' %'}
                </Typography>
                <Typography className={`${layoutClasses.text}`} data-testid="summary-message">
                    {text('interestRates.effectiveStartDate') +
                        ': ' +
                        new Date(interestRateState.values[0].effectiveDate).toLocaleString('en-GB')}
                </Typography>
            </Paper>
        </div>
    );
};

export default InterestRateUpdateSummary;
