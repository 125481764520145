import {
    GetReferenceArchitectureFromQuestions,
    ReferenceArchitecture,
    ReferenceArchitectureQuestions,
} from '../../constants/ReferenceArchitectures';
import React, { useState } from 'react';

import { Paper } from '@material-ui/core';
import { PartialBy } from '@r3/cbdc-asset-frontend-core';
import RefArchQuestion from './RefArchitectureQuestion';
import { useRefArchStyles } from '../../materialStyles/refArchStyles';

type RefArchQuestionsProps = {
    setRefArch: (refArch: ReferenceArchitecture) => void;
    currentRefArch: ReferenceArchitecture;
};

const RefArchQuestions: React.FC<RefArchQuestionsProps> = ({ setRefArch, currentRefArch }) => {
    const [refArchQuestions, setRefArchQuestions] = useState<ReferenceArchitectureQuestions>(
        currentRefArch.refQuestions
    );
    const classes = useRefArchStyles();
    const updateQuestions = (
        updateState: PartialBy<ReferenceArchitectureQuestions, keyof ReferenceArchitectureQuestions>
    ) => {
        let currentState = { ...refArchQuestions };
        let refArch = GetReferenceArchitectureFromQuestions({ ...currentState, ...updateState });
        if (typeof refArch !== 'undefined') {
            setRefArchQuestions(refArch.refQuestions);
            setRefArch(refArch);
        } else {
            alert('Sorry, there is no reference model for this answer combination yet. Reverting your last choice.');
        }
    };

    return (
        <Paper className={classes.questionsWrapper}>
            <RefArchQuestion
                text=" 1. Does this currency represent a Central Bank liability?"
                optionOne={{ option: { cbLiability: 'YES' }, optionText: 'YES' }}
                optionTwo={{ option: { cbLiability: 'NO' }, optionText: 'NO', disabled: true }}
                refArchQuestions={refArchQuestions}
                updateQuestions={updateQuestions}
            />
            <RefArchQuestion
                text=" 2. Is this a single tier solution (wholesale or retail) or multi tier system (wholesale and retail)?"
                optionOne={{ option: { tier: 'SINGLETIER' }, optionText: 'Single' }}
                optionTwo={{ option: { tier: 'MULTITIER' }, optionText: 'Multi' }}
                refArchQuestions={refArchQuestions}
                updateQuestions={updateQuestions}
            />
            <RefArchQuestion
                text="3. Are the transactions peer-to-peer with peer-to-peer finality?"
                optionOne={{ option: { peerToPeer: 'YES' }, optionText: 'YES' }}
                optionTwo={{ option: { peerToPeer: 'NO' }, optionText: 'NO', disabled: true }}
                refArchQuestions={refArchQuestions}
                updateQuestions={updateQuestions}
            />
            <RefArchQuestion
                text="4. Would you like this asset to be a bearer asset or balance based asset?"
                optionOne={{ option: { bearerBalance: 'BALANCE' }, optionText: 'BALANCE' }}
                optionTwo={{ option: { bearerBalance: 'BEARER' }, optionText: 'BEARER' }}
                refArchQuestions={refArchQuestions}
                updateQuestions={updateQuestions}
            />
            <RefArchQuestion
                text="5. Would you like transactions to be private with respect to the system operator?"
                optionOne={{ option: { isPrivate: 'YES' }, optionText: 'YES' }}
                optionTwo={{ option: { isPrivate: 'NO' }, optionText: 'NO' }}
                refArchQuestions={refArchQuestions}
                updateQuestions={updateQuestions}
            />
            <RefArchQuestion
                text="6. Is this currency access controlled by the issuer?"
                optionOne={{ option: { memberAccess: 'YES' }, optionText: 'YES' }}
                optionTwo={{ option: { memberAccess: 'NO' }, optionText: 'NO' }}
                refArchQuestions={refArchQuestions}
                updateQuestions={updateQuestions}
            />
        </Paper>
    );
};

export default RefArchQuestions;
