import { AssetResponse, useInteractiveChecklistContext } from '@r3/cbdc-asset-frontend-core';
import { DASHBOARDANIMATIONENTERMS, DASHBOARDANIMATIONEXITMS } from '../../../constants/Animations';
import { Divider, Fade, Paper, Slide } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { ASSETPOLLINGINTERVAL } from '../../../constants/PollingIntervals';
import { DashBoardResponsivePie } from '../DashboardComponents/DashboardResponsivePie';
import { DashboardFilter } from '../DashboardComponents/DashboardFilter';
import { InfoBox } from '../DashboardComponents/InfoBox';
import { useDashboardPieData } from '../../../hooks/PieData';
import { useDashboardStyles } from '@r3/cbdc-asset-frontend-core';
import useGetAssetSummary from '../../../hooks/GetAssetSummary';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const AssetDashboard: React.FC = () => {
    const [filterAssets, setFilterAssets] = useState<string[]>([]);
    const [vaultAssets, setVaultAssets] = useState<AssetResponse[]>([]);
    const classes = useDashboardStyles();
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    const checkListContext = useInteractiveChecklistContext();
    const location = useLocation();
    const { getAssetSummary, assetSummary, summaryError } = useGetAssetSummary();
    const handlePieClick = () => {};
    const { uniqueAssets, assetsInVault, pieData, updatePieData } = useDashboardPieData();

    useEffect(() => {
        checkListContext?.completeStep(location.pathname);
    }, [location.pathname, checkListContext]);

    useEffect(() => {
        getAssetSummary();
    }, [getAssetSummary]);

    useEffect(() => {
        if (ASSETPOLLINGINTERVAL <= 0) {
            return;
        }
        const interval = setInterval(function () {
            getAssetSummary(true);
        }, ASSETPOLLINGINTERVAL);

        return () => {
            clearInterval(interval);
        };
    }, [getAssetSummary]);

    useEffect(() => {
        setFilterAssets(assetSummary.map((asset) => asset.tokenDefinition.tokenName));
        setVaultAssets(assetSummary);
    }, [assetSummary]);

    const updateFilter = (name: string) => {
        let newFilterArray: string[] = [];
        if (filterAssets.includes(name)) {
            newFilterArray = filterAssets.filter((f) => f !== name);
        } else {
            newFilterArray = [...filterAssets];
            newFilterArray.push(name);
        }
        setFilterAssets(newFilterArray);
    };

    useEffect(() => {
        updatePieData(vaultAssets, filterAssets);
    }, [filterAssets, updatePieData, vaultAssets]);

    return (
        <>
            <Fade in={true}>
                <Paper elevation={5} className={`${layoutClasses.componentWrapper}`}>
                    {summaryError.length > 0 ? (
                        <>
                            <InfoBox title={summaryError} content={''} />
                        </>
                    ) : (
                        <>
                            <InfoBox title={t('dashBoard.assetTypes')} content={pieData.length.toString()} />
                            <InfoBox
                                title={t('dashBoard.totalAssetsInVault')}
                                content={assetsInVault.toFixed(3).toString()}
                            />
                        </>
                    )}
                </Paper>
            </Fade>

            {vaultAssets.length > 0 && (
                <div className={layoutClasses.componentWrapper}>
                    <Slide
                        direction="right"
                        in={true}
                        timeout={{ enter: DASHBOARDANIMATIONENTERMS, exit: DASHBOARDANIMATIONEXITMS }}
                    >
                        <div className={`${layoutClasses.column} ${layoutClasses.columnSmall}`}>
                            <DashboardFilter
                                assetSummary={assetSummary}
                                uniqueAssets={uniqueAssets}
                                filterAssets={filterAssets}
                                updateFilter={updateFilter}
                            />
                        </div>
                    </Slide>

                    <Slide
                        direction="left"
                        in={true}
                        timeout={{ enter: DASHBOARDANIMATIONENTERMS, exit: DASHBOARDANIMATIONEXITMS }}
                    >
                        <div
                            className={`${layoutClasses.column} ${layoutClasses.columnFill} ${layoutClasses.columnNoPadding} `}
                            style={{ overflow: 'hidden' }}
                        >
                            <div id="PieGraph" className={` ${classes.nivoWrapper}`} style={{ overflow: 'hidden' }}>
                                <DashBoardResponsivePie
                                    altColour={true}
                                    data={pieData}
                                    handlePieClick={handlePieClick}
                                />
                                <Divider className={classes.divider} />
                            </div>
                        </div>
                    </Slide>
                </div>
            )}
        </>
    );
};
