import { Divider, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@material-ui/core';

import { Asset, ComplianceRuleKey, useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useTranslation } from 'react-i18next';

type SelectParticipantStepProps = {
    selectedAsset: Asset;
    getComplyingParticipants: () => string[];
    selectedParticipant: string;
    handleParticipantChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
};

const SelectParticipantStep: React.FC<SelectParticipantStepProps> = (props) => {
    const { selectedAsset, getComplyingParticipants, selectedParticipant, handleParticipantChange } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>{t('issueAsset.selectParticipant')}</Typography>

                {selectedAsset.complianceRules?.findIndex((rule) => rule.key === ComplianceRuleKey.MemberAccess) ===
                -1 ? (
                    <>
                        <Typography className={`${layoutClasses.text}`}>
                            {'This asset does not require a Member Access State'}
                        </Typography>
                        <Typography className={`${layoutClasses.text}`}>
                            {'(Any participant on the network can hold it)'}
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography className={`${layoutClasses.text}`}>
                            {'This asset requires a Member Access State to hold it'}
                        </Typography>

                        <Typography className={`${layoutClasses.text}`}>
                            {getComplyingParticipants().length > 0
                                ? '(Only participants with a member access state are listed)'
                                : ''}
                        </Typography>
                    </>
                )}

                <Divider className={layoutClasses.summaryDivider} />

                {getComplyingParticipants().length > 0 ? (
                    <FormControl
                        id="SelectParticipant"
                        variant="outlined"
                        className={`${layoutClasses.selectInput} ${layoutClasses.columnNoPadding}`}
                    >
                        <InputLabel id="demo-simple-select-helper-label">{t('commonText.participant')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={selectedParticipant}
                            onChange={handleParticipantChange}
                            data-testid={'participant-select'}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {getComplyingParticipants().map((participant, i) => {
                                return (
                                    <MenuItem key={participant + i} value={participant}>
                                        {participant}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                ) : (
                    <Typography className={`${layoutClasses.text}`}>
                        {'No Participants with required Member Access State Exist'}
                    </Typography>
                )}
            </Paper>
        </div>
    );
};

export default SelectParticipantStep;
