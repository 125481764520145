import { IconButton, Typography } from '@material-ui/core';

import ReplayIcon from '@material-ui/icons/Replay';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useListStyles } from '@r3/cbdc-asset-frontend-core';

type ListHeaderProps = {
    refreshAction: () => void;
    text: string;
};

export const ListHeader: React.FC<ListHeaderProps> = ({ refreshAction, text }) => {
    const listClasses = useListStyles();
    const layoutClasses = useLayoutStyles();
    return (
        <div className={listClasses.header}>
            <Typography className={`${layoutClasses.sectionLabel}`}>{text}</Typography>
            <IconButton
                data-testid={'refresh_list'}
                id={'RefreshList'}
                onClick={() => refreshAction()}
                color="secondary"
                component="span"
            >
                <ReplayIcon />
            </IconButton>
        </div>
    );
};
