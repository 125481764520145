import './App.css';

import * as ROUTES from '../../constants/Routes';

import { Assistant, LoginScreen } from '@r3/cbdc-asset-frontend-core';
import { IconButton, Theme, createStyles, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Assets } from '../cbdcManager/Assets/Assets';
import { DOCS_URL } from 'constants/InfoModal';
import DrawerNav from '../layout/DrawerNav/DrawerNav';
import Footer from '../layout/Footer/Footer';
import { IncomingRequests } from '../incomingRequests/IncomingRequests';
import { KnowYourCustomer } from '../memberControls/KnowYourCustomer';
import { LandingPage } from '../landingPage/LandingPage';
import Loading from '../loading/Loading';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import NavBar from '../layout/NavBar/NavBar';
import { NetworkTab } from '../networkTab/NetworkTab';
import { PollingNotificationService } from '../pollingNotificationService/PollingNotificationService';
import ReferenceArchitectures from '../referenceArchitectures/ReferenceArchitectures';
import SnackbarCloseButton from '../commonComponents/Notifications/SnackBarCloseButton';
import { SnackbarProvider } from 'notistack';
import TopNavWrapper from '../layout/TopNavWrapper/TopNavWrapper';
import { TransactionDashboardParent } from '../transactionDashboard/TransactionDashboardParent';
import { TreasuryDashboard } from '../treasuryDashboard/TreasuryDashboard';
import { VisibilityAndReissuance } from '../visibilityAndReissuance/VisibilityAndReissuance';
import { axiosInstance } from 'api/apiConfig';
import { useBasicAuth } from '../../hooks/UseBasicAuth';
import useIssuerPages from 'hooks/useIssuerPages';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            menuButton: {
                color: theme.palette.primary.contrastText,
                fontWeight: 'bold',
                fontSize: 18,
                '&:hover': {
                    color: theme.palette.secondary.main,
                },
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            notifBase: {
                '& #client-snackbar': {
                    maxWidth: 400,
                },
                '&.MuiCollapse-wrapperInner': {
                    width: '99%!important',
                },

                pointerEvents: 'all',
                right: 10,
                marginBottom: 4,
            },
            mainContent: {
                minHeight: '100hv',
                width: '100%',
                paddingBottom: 85,
            },
            blurred: {
                filter: 'blur(5px)',
            },
        }),
    { index: 1 }
);

const notificationServiceFromLocalStorage = (): boolean => {
    let state = localStorage.getItem('notifService');
    if (state) {
        if (state === 'true') {
            return true;
        } else if (state === 'false') {
            return false;
        }
    } else {
        localStorage.setItem('notifService', 'true');
        return true;
    }
    return false;
};

export const App: React.FC = () => {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const closeDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };
    const { promiseInProgress } = usePromiseTracker({
        delay:
            (window as any).REACT_APP_PROMISE_TRACKER_DELAY !== undefined
                ? parseInt((window as any).REACT_APP_PROMISE_TRACKER_DELAY!.trim())
                : 500,
    });
    const { isLoggedIn, loginError } = useBasicAuth();
    const [receiveNotifications, setReceiveNotifications] = useState<boolean>(notificationServiceFromLocalStorage());
    const { t } = useTranslation();
    const toggleNotificaitonService = () => {
        if (receiveNotifications) {
            localStorage.setItem('notifService', 'false');
        } else {
            localStorage.setItem('notifService', 'true');
        }
        setReceiveNotifications(!receiveNotifications);
    };
    const { pages } = useIssuerPages();

    const mainContent = (
        <Switch>
            <Route exact path="/" component={LandingPage}></Route>
            <Route path={ROUTES.ASSETS} component={Assets}></Route>
            <Route path={ROUTES.DEMODASH} component={TreasuryDashboard}></Route>
            <Route path={ROUTES.KYCCONTROL} component={KnowYourCustomer}></Route>
            <Route path={ROUTES.INCOMINGREQUESTS} component={IncomingRequests}></Route>
            <Route path={ROUTES.TRANSACTIONDASH} component={TransactionDashboardParent}></Route>
            <Route path={ROUTES.NETWORKTAB} component={NetworkTab}></Route>
            <Route path={ROUTES.VISIBILITYREISSUANCE} component={VisibilityAndReissuance}></Route>
            <Route path={ROUTES.REFARCHITECTURE} component={ReferenceArchitectures}></Route>
        </Switch>
    );

    return (
        <SnackbarProvider
            id="snackBarProvider"
            className="snackBarProvider"
            preventDuplicate
            maxSnack={5}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            classes={{ root: classes.notifBase }}
            action={(k) => (
                <>
                    <SnackbarCloseButton snackKey={k} />
                </>
            )}
        >
            <div className={'App'}>
                <TopNavWrapper
                    navButton={
                        <IconButton
                            className={`menuButton ${classes.menuButton}`}
                            color="primary"
                            onClick={() => {
                                setDrawerOpen(!drawerOpen);
                            }}
                        >
                            <MenuOpenIcon style={{ width: 35, height: 35 }} />
                        </IconButton>
                    }
                    receiveNotifications={receiveNotifications}
                    toggleNotificationService={toggleNotificaitonService}
                ></TopNavWrapper>

                {isLoggedIn ? (
                    <>
                        {promiseInProgress && <Loading />}
                        <div className={`${classes.mainContent} ${promiseInProgress ? classes.blurred : ''}`}>
                            {mainContent}
                        </div>
                    </>
                ) : (
                    <LoginScreen error={loginError} axiosInstance={axiosInstance} />
                )}

                <DrawerNav open={drawerOpen} closeDrawer={closeDrawer}>
                    <NavBar
                        navButtons={pages
                            .filter((page) => typeof page.navButton !== 'undefined')
                            .map((page) => page.navButton!)}
                        receiveNotifications={receiveNotifications}
                        toggleNotificationService={toggleNotificaitonService}
                    />
                </DrawerNav>
                <Assistant documentationUrl={DOCS_URL} openOnPageLoadUrls={[ROUTES.VISIBILITYREISSUANCE]} />
                {receiveNotifications && isLoggedIn && <PollingNotificationService />}
                <Footer copyright="© 2022 R3. All rights reserved.">
                    <a rel="noopener noreferrer" href="/#" title="Terms and Conditions">
                        {t('commonText.termsAndConditions')}
                    </a>
                    <a rel="noopener noreferrer" href="/#" title="Privacy Policy">
                        {t('commonText.rightsReserved')}
                    </a>
                </Footer>
            </div>
        </SnackbarProvider>
    );
};
