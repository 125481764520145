import { ISSUEKYC, LISTKYC } from 'constants/Routes';
import { TabPageMap, TabsWrapper, insertIntoArrayIf, useFeatureFlagContext } from '@r3/cbdc-asset-frontend-core';

import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import { IssueKyc } from './IssueKyc/IssueKyc';
import { ListKyc } from './ListKyc/ListKyc';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const KnowYourCustomer: React.FC = () => {
    const { t } = useTranslation();
    const { isFeatureEnabled } = useFeatureFlagContext();

    const tabPageMap: TabPageMap[] = [
        ...insertIntoArrayIf(isFeatureEnabled('viewMemberAccessStates'), {
            route: LISTKYC,
            pageComponent: ListKyc,
            tabDetails: { icon: <DoneOutlineIcon />, label: t('pageTitle.listKyc') },
        }),
        ...insertIntoArrayIf(isFeatureEnabled('issueMemberAccess'), {
            route: ISSUEKYC,
            pageComponent: IssueKyc,
            tabDetails: { icon: <HowToRegIcon />, label: t('pageTitle.issueKyc') },
        }),
    ];

    return <TabsWrapper pageTitle={t('pageTitle.kycControl')} tabPageMap={tabPageMap} />;
};
