import { AxiosResponse } from 'axios';

export type ResolvedPromise<T = any> = {
    data: T | null;
    error: string | null;
    message?: string | null;
};

/**
 * @remakrs
 * Resolves a promise returned by a HTTP client
 *
 * @param promise the promise to be resolved
 */
export const resolvePromise = async <T = any>(promise: Promise<AxiosResponse>) => {
    try {
        const resolvedPromise = await promise;
        return { data: resolvedPromise.data as T, error: null };
    } catch (error: any) {
        const errorMessage = error.response?.data
            ? error.response.data.message
                ? error.response.data.message
                : error.response.data.status + ' ' + error.response.data.error
            : error;
        return {
            data: null,
            error: errorMessage,
        };
    }
};
