import * as ROUTES from 'constants/Routes';

import { useFeatureFlagContext } from '@r3/cbdc-asset-frontend-core';

export type CheckListAction = {
    description: string;
    url: string;
    steps: string[];
    clickToComplete?: boolean;
};

const url = window.location.href;
let splitUrl = url.substring(url.indexOf('.'), url.length);
splitUrl = splitUrl.substring(0, splitUrl.indexOf('.com') + 4);

const insertIntoArrayIf = function (condition, element) {
    return condition ? [element] : [];
};

const useInteractiveChecklist = () => {
    const { isFeatureEnabled } = useFeatureFlagContext();

    const CHECKLIST_ACTIONS: CheckListAction[] = [
        {
            description: 'Sandbox Guide Intro',
            url: '/',
            steps: [
                'Welcome to the Sandbox Guide. This guide is optional, but we recommend taking a look.',
                'This guide will take you through the various features available on the CBDC Sandbox.',
                'Follow the guide to familiarize yourself with Central Bank and Wholesale Bank features.',
                'You will notice after completing forms and various actions, a "What happened on Corda?" prompt will be shown.',
                'Clicking this button will give you an opportunity to learn about CBDC on Corda.',
                'Navigation buttons on the Sandbox are consistent throughout. Click "Next" to continue.',
                'Click "Back" to retrace or repeat any previous steps.',
                'Click "Next" to continue reading the guide now.',
            ],
            clickToComplete: true,
        },
        {
            description: 'View Central Bank Bonds',
            url: ROUTES.BONDSDASH,
            steps: [
                'Navigate to the Treasury Dashboard page (top bar).',
                'Navigate to Bonds tab.',
                'View the amount of bonds in the Central Bank vault.',
                'Note: These bonds are intended to be used as collateral for CBDC.',
                'Note: Wholesale Banks can request CBDC assets in exchange for bonds.',
                'Note: Wholesale Banks can redeem a CBDC asset and receive bonds in return.',
                'Note: These bonds represent a type of high-quality liquid asset but serve no other purpose currently.',
            ],
        },
        {
            description: 'Define a CBDC asset',
            url: ROUTES.DEFINECBDC,
            steps: [
                'Navigate to the CBDC Manager page (top bar).',
                'Navigate to the Define CBDC tab.',
                'Complete the form. Take note of the asset name you define.',
                'Select the **first** Notary from the list.',
                'Enable the Member Access State requirement for the new CBDC asset.',
                ...insertIntoArrayIf(
                    isFeatureEnabled('distributedInterest'),
                    "For the purpose of the guide, make the new asset 'Interest bearing' and select a desired value and effective date."
                ),
                "You can configure various 'transaction & asset controls' for any new CBDC. This is a powerful feature of programmable assets. (For the purpose of following this guide, we recommend not setting these yet).",
                'Note: Try "What happened on Corda?" after you complete the form.',
            ],
        },

        {
            description: 'View a list of defined CBDC assets',
            url: ROUTES.AVAILABLECBDC,
            steps: [
                'Navigate to the CBDC Manager page (top bar).',
                'Navigate to the Available CBDC tab.',
                'Find and inspect the asset you just defined.',
                "Click the 'Compliance Controls' dropdown to inspect any controls you have added to the asset.",
                'Note: All of the assets defined by this Central Bank are listed here.',
            ],
        },
        ...insertIntoArrayIf(isFeatureEnabled('distributedInterest'), {
            description: 'Update CDBC interest rate (Optional)',
            url: ROUTES.UPDATEINTERESTRATE,
            steps: [
                'Navigate to the Treasury Dashboard (top bar).',
                'Navigate to the Update CBDC Interest Rates tab.',
                'Select the asset you defined from the dropdown list.',
                'Update the interest rate by adding a new interest rate value. The new added interest rate will take effect from the new effective start date specified.',
                'Note: The interest value can be set to negative or positive values, and also zero. Zero is used to end the interest rate. It will come into effect on the effective start date specified, similar to negative and positive rates.',
            ],
            clickToComplete: true,
        }),
        {
            description: 'Issue a Member Access State to WS1',
            url: ROUTES.ISSUEKYC,
            steps: [
                'Navigate to the Member Controls page (top bar).',
                'Navigate to the Issue tab.',
                'Select the newly defined CBDC.',
                'Issue a Member Access State to Wholesale One.',
                'Note: This will allow Wholesale One to hold and transact with the CBDC asset referenced in the state.',
            ],
        },
        {
            description: 'Issue a Member Access State to WS2',
            url: ROUTES.ISSUEKYC,
            steps: [
                'Navigate to Member Controls page (top bar).',
                'Navigate to the Issue tab.',
                'Select the newly defined CBDC asset.',
                'Issue a Member Access State to Wholesale Two.',
            ],
        },
        {
            description: 'View issued Member Access States',
            url: ROUTES.LISTKYC,
            steps: [
                'Navigate to the Member Controls page (top bar).',
                'Navigate to the List/Revoke tab.',
                'View issued Member Access States on the network.',
            ],
        },
        {
            description: 'Issue new CBDC to Wholesale Bank 1',
            url: ROUTES.ISSUECBDC,
            steps: [
                'Navigate to the CBDC Manager page.',
                'Navigate to the Issue CBDC tab.',
                'Select the newly defined CBDC asset.',
                'Issue to Wholesale One.',
            ],
        },
        {
            description: 'Go to Wholesale Bank 1',
            url: '/',
            steps: [
                'Continue the Sandbox Guide on the Wholesale Bank One.',
                'There you will learn how to use the Wholesale Bank interface.',
                'http://wholesaleone' + splitUrl,
                'Return here after you have completed a wholesale transfer and requested a CBDC asset.',
                'Click ( Next ) in the Sandbox Guide to continue.',
            ],
            clickToComplete: true,
        },
        {
            description: 'View Member Access requests (Optional)',
            url: ROUTES.KYCREQUESTS,
            steps: [
                'Navigate to the Incoming Requests page.',
                'Navigate to the Member Access Requests tab.',
                'Here you can view all Member Access requests from the Wholesale Banks.',
                'You can approve or ignore these requests.',
            ],
            clickToComplete: true,
        },
        {
            description: 'Approve CBDC Request',
            url: ROUTES.CBDCREQUESTS,
            steps: [
                'Navigate to the Incoming Requests Page.',
                'Navigate to the CBDC Issuance Requests tab.',
                'Find a request from Wholesale Bank One for the newly created asset.',
                'Approve the request. The bonds will move to the Central Bank vault.',
                'Note: If you deny the request the bonds will be sent back to the Wholesale Bank.',
            ],
        },
        {
            description: 'Approve CBDC Redemption',
            url: ROUTES.REDEEMREQUESTS,
            steps: [
                'Navigate to the Incoming Requests page.',
                'Navigate to the Redemption Requests tab.',
                'Find a request from Wholesale Bank One for the newly created asset.',
                'Approve the request. The bonds will move to the Wholesale Bank One vault.',
                'Note: If you deny the request the bonds will not be moved and the CBDC asset will be sent back to Wholesale Bank One.',
            ],
        },
        {
            description: 'View Central Bank Bonds',
            url: ROUTES.BONDSDASH,
            steps: [
                'Navigate to the Treasury Dashboard page (top bar).',
                'Navigate to the Bonds tab.',
                'View the amount of bonds in the Central Bank vault.',
                'Note: The bonds received from the CBDC issuance request have been added to the bonds vault.',
                'Note: The bonds requested from the CBDC Redemption request have been subtracted from the bonds vault.',
            ],
        },
        {
            description: 'Go to the Wholesale Bank One interface',
            url: '/',
            steps: [
                'Continue the Sandbox Guide on the Wholesale Bank One interface.',
                'There you will see that the Whoelsale Bank has received the requested CBDC.',
                'http://wholesaleone' + splitUrl,
                'Return here after you have responded to a DVP request.',
                'Press ( Next ) in the Sandbox Guide to continue to the Cross Chain Swap steps. ',
            ],
            clickToComplete: true,
        },

        {
            description: 'Define a new CBDC asset on a different Notary',
            url: ROUTES.DEFINECBDC,
            steps: [
                'Navigate to the CBDC Manager page (top bar).',
                'Navigate to the Define CBDC tab.',
                'Complete the form. Take note of the asset name you define.',
                'Select the **second** Notary from the list.',
                '**Disable** the Member Access State requirement for the new CBDC asset.',
                'Note: Member Access States will not be needed to hold this asset by the Wholesale Banks',
            ],
        },
        {
            description: 'Issue the new CBDC to Wholesale Bank 2',
            url: ROUTES.ISSUECBDC,
            steps: [
                'Navigate to the CBDC Manager page.',
                'Navigate to the Issue CBDC tab.',
                'Select the newly defined CBDC asset.',
                'Issue a significant amount of it to **Wholesale Two.**',
                'Note: This issuance does not require that Wholesale Banks have member access states. (Works like cash).',
                'Note: Wholesale Bank One will then be able to request a Cross Chain Swap using the first asset you defined earlier in this Guide.',
            ],
        },
        {
            description: 'Go to the Wholesale Bank One interface',
            url: '/',
            steps: [
                'Continue the Sandbox Guide on the Wholesale Bank One interface.',
                'http://wholesaleone' + splitUrl,
                'Return here after you have completed a Cross Chain Swap.',
                'Press ( Next ) in the Sandbox Guide to continue to the final step. ',
            ],
            clickToComplete: true,
        },
        {
            description: 'Finish!',
            url: '/',
            steps: [
                'You have successfully finished the Sandbox Guide! Well done.',
                'You can now navigate the core functionalities of the Sandbox.',
                'However, there is still plenty to learn.',
                'Take your time to experiment with the various asset controls, a Corda-powered solution to managing who can transact on an asset and how.',
                'The Sandbox asset controls are configurable in many combinations and allow for granular control through programmable assets.',
                'Make use of the educational content, videos and diagrams, access them through the "What Happened On Corda?" prompts.',
                'This same information can be accessed from the "What happens on Corda?" button in the "Assistant" (man icon to the right of the Sandbox Guide), or on any page that has relevant educational content.',
                'The full technical documentation and UI walkthrough guides are also available from the "Assistant".',
                'Note: You can view some analytics, in the Money Supply Tab of the Treasury Dashboard. Which shows you asset circulation over time.',
                'Note: Go to the Corda Transactions Dashboard / Home page to view some of the corda transaction activity on the node',
                'Note: This will show up in the TX Log Table',
            ],
            clickToComplete: true,
        },
    ];

    return { CHECKLIST_ACTIONS };
};

export default useInteractiveChecklist;
