type RefArchAnswerYesNo = 'YES' | 'NO';
type RefArchTier = 'SINGLETIER' | 'MULTITIER';
type RefArchUnit = 'BEARER' | 'BALANCE';

export type ReferenceArchitecture = {
    title: string;
    filePath: string;
    refQuestions: ReferenceArchitectureQuestions;
    docLink?: string;
};

export type ReferenceArchitectureQuestions = {
    //cbLiability is always yes for now
    cbLiability: RefArchAnswerYesNo;
    tier: RefArchTier;
    //peerToPeer is always yes for now
    peerToPeer: RefArchAnswerYesNo;
    bearerBalance: RefArchUnit;
    isPrivate: RefArchAnswerYesNo;
    memberAccess: RefArchAnswerYesNo;
};

//cbLiability and peerToPeer are always yes (from intial implementation)
export const GetReferenceArchitectureFromQuestions = (
    refArchQuestions: ReferenceArchitectureQuestions
): ReferenceArchitecture | undefined => {
    return REFERENCEARCHITECTURES.find(
        (refArch) =>
            refArchQuestions.tier === refArch.refQuestions.tier &&
            refArchQuestions.bearerBalance === refArch.refQuestions.bearerBalance &&
            refArchQuestions.isPrivate === refArch.refQuestions.isPrivate &&
            refArchQuestions.memberAccess === refArch.refQuestions.memberAccess
    );
};

const folderPath = '/referenceArchitectures/';

export const REFERENCEARCHITECTURES: ReferenceArchitecture[] = [
    {
        title: 'Model 1',
        filePath: folderPath + '1.png',
        docLink: 'https://docs-cbdc.r3.com/overview/reference-architecture/architecture-1/',
        refQuestions: {
            cbLiability: 'YES',
            tier: 'SINGLETIER',
            peerToPeer: 'YES',
            bearerBalance: 'BEARER',
            isPrivate: 'YES',
            memberAccess: 'YES',
        },
    },
    {
        title: 'Model 2',
        filePath: folderPath + '2.png',
        docLink: 'https://docs-cbdc.r3.com/overview/reference-architecture/architecture-2/',
        refQuestions: {
            cbLiability: 'YES',
            tier: 'SINGLETIER',
            peerToPeer: 'YES',
            bearerBalance: 'BEARER',
            isPrivate: 'YES',
            memberAccess: 'NO',
        },
    },
    {
        title: 'Model 3',
        filePath: folderPath + '3.png',
        docLink: 'https://docs-cbdc.r3.com/overview/reference-architecture/architecture-3/',
        refQuestions: {
            cbLiability: 'YES',
            tier: 'SINGLETIER',
            peerToPeer: 'YES',
            bearerBalance: 'BEARER',
            isPrivate: 'NO',
            memberAccess: 'YES',
        },
    },
    {
        title: 'Model 4',
        filePath: folderPath + '4.png',
        docLink: 'https://docs-cbdc.r3.com/overview/reference-architecture/architecture-4/',
        refQuestions: {
            cbLiability: 'YES',
            tier: 'SINGLETIER',
            peerToPeer: 'YES',
            bearerBalance: 'BEARER',
            isPrivate: 'NO',
            memberAccess: 'NO',
        },
    },
    {
        title: 'Model 5',
        filePath: folderPath + '5.png',
        refQuestions: {
            cbLiability: 'YES',
            tier: 'SINGLETIER',
            peerToPeer: 'YES',
            bearerBalance: 'BALANCE',
            isPrivate: 'YES',
            memberAccess: 'YES',
        },
    },
    {
        title: 'Model 6',
        filePath: folderPath + '6.png',
        refQuestions: {
            cbLiability: 'YES',
            tier: 'SINGLETIER',
            peerToPeer: 'YES',
            bearerBalance: 'BALANCE',
            isPrivate: 'YES',
            memberAccess: 'NO',
        },
    },
    {
        title: 'Model 7',
        filePath: folderPath + '7.png',
        refQuestions: {
            cbLiability: 'YES',
            tier: 'SINGLETIER',
            peerToPeer: 'YES',
            bearerBalance: 'BALANCE',
            isPrivate: 'NO',
            memberAccess: 'YES',
        },
    },
    {
        title: 'Model 8',
        filePath: folderPath + '8.png',
        refQuestions: {
            cbLiability: 'YES',
            tier: 'SINGLETIER',
            peerToPeer: 'YES',
            bearerBalance: 'BALANCE',
            isPrivate: 'NO',
            memberAccess: 'NO',
        },
    },
    {
        title: 'Model 9',
        filePath: folderPath + '9.png',
        refQuestions: {
            cbLiability: 'YES',
            tier: 'MULTITIER',
            peerToPeer: 'YES',
            bearerBalance: 'BEARER',
            isPrivate: 'YES',
            memberAccess: 'YES',
        },
    },
    {
        title: 'Model 10',
        filePath: folderPath + '10.png',
        refQuestions: {
            cbLiability: 'YES',
            tier: 'MULTITIER',
            peerToPeer: 'YES',
            bearerBalance: 'BEARER',
            isPrivate: 'YES',
            memberAccess: 'NO',
        },
    },
    {
        title: 'Model 11',
        filePath: folderPath + '11.png',
        refQuestions: {
            cbLiability: 'YES',
            tier: 'MULTITIER',
            peerToPeer: 'YES',
            bearerBalance: 'BEARER',
            isPrivate: 'NO',
            memberAccess: 'YES',
        },
    },
    {
        title: 'Model 12',
        filePath: folderPath + '12.png',
        refQuestions: {
            cbLiability: 'YES',
            tier: 'MULTITIER',
            peerToPeer: 'YES',
            bearerBalance: 'BEARER',
            isPrivate: 'NO',
            memberAccess: 'NO',
        },
    },
    {
        title: 'Model 13',
        filePath: folderPath + '13.png',
        refQuestions: {
            cbLiability: 'YES',
            tier: 'MULTITIER',
            peerToPeer: 'YES',
            bearerBalance: 'BALANCE',
            isPrivate: 'YES',
            memberAccess: 'YES',
        },
    },
    {
        title: 'Model 14',
        filePath: folderPath + '14.png',
        refQuestions: {
            cbLiability: 'YES',
            tier: 'MULTITIER',
            peerToPeer: 'YES',
            bearerBalance: 'BALANCE',
            isPrivate: 'YES',
            memberAccess: 'NO',
        },
    },
    {
        title: 'Model 15',
        filePath: folderPath + '15.png',
        refQuestions: {
            cbLiability: 'YES',
            tier: 'MULTITIER',
            peerToPeer: 'YES',
            bearerBalance: 'BALANCE',
            isPrivate: 'NO',
            memberAccess: 'YES',
        },
    },
    {
        title: 'Model 16',
        filePath: folderPath + '16.png',
        refQuestions: {
            cbLiability: 'YES',
            tier: 'MULTITIER',
            peerToPeer: 'YES',
            bearerBalance: 'BALANCE',
            isPrivate: 'NO',
            memberAccess: 'NO',
        },
    },
];
