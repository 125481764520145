import NavButtons, { NavButtonsProps } from '../NavButtons/NavButtons';

import React from 'react';
import useIssuerPages from 'hooks/useIssuerPages';

const NavBar: React.FC<NavButtonsProps> = ({ receiveNotifications, toggleNotificationService }) => {
    const { pages } = useIssuerPages();
    return (
        <div style={{ width: 320 }}>
            <NavButtons
                navButtons={pages
                    .filter((page) => typeof page.navButton !== 'undefined')
                    .map((page) => page.navButton!)}
                receiveNotifications={receiveNotifications}
                toggleNotificationService={toggleNotificationService}
            />
        </div>
    );
};

export default NavBar;
