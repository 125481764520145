export enum InterestGraphNames {
    Actual = 'ACTUAL',
    Expected = 'EXPECTED',
}

export const getLineId = (graphName: string | null) => {
    if (graphName === InterestGraphNames.Actual) {
        return LINE_IDS.IssuedBaseWithVerifiedInterest;
    }
    if (graphName === InterestGraphNames.Expected) {
        return LINE_IDS.IssuedBaseWithExpectedInterest;
    }
    return LINE_IDS.IssuedBase;
};

export const LINE_IDS = {
    IssuedBase: 'Balance',
    IssuedBaseWithExpectedInterest: 'Issued Base + Expected Interest',
    IssuedBaseWithVerifiedInterest: 'Issued base + Verified Interest',
};
