import { Button, Divider, IconButton, Paper, TextField, Typography } from '@material-ui/core';
import { ComplianceRule, ComplianceRuleGroup, ComplianceRuleKey, NumberFormat } from '@r3/cbdc-asset-frontend-core';
import React, { useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import { AddedRulePair } from './AddedRulePair';
import { ComplianceRulesDialog } from './ComplianceRulesDialog';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useTranslation } from 'react-i18next';

type TransactionRulesStepProps = {
    complianceRuleGroup: ComplianceRuleGroup;
    addedRules: AddedRulePair[];
    removeComplianceRule: (index: number) => void;
    updateRuleValue: (event: any, index: number) => void;
    totalRules: number;
    addComplianceRule: (rule: ComplianceRule) => void;
    expiryTimeValid: () => boolean;
    setExpiryHours: (val: string) => void;
    setExpiryMinutes: (val: string) => void;
    setExpiryDays: (val: string) => void;
    expiryDays: string;
    expiryHours: string;
    expiryMinutes: string;
};

const AssetRulesStep: React.FC<TransactionRulesStepProps> = (props) => {
    const {
        complianceRuleGroup,
        addedRules,
        removeComplianceRule,
        updateRuleValue,
        totalRules,
        addComplianceRule,
        expiryTimeValid,
        setExpiryHours,
        setExpiryMinutes,
        setExpiryDays,
        expiryDays,
        expiryHours,
        expiryMinutes,
    } = props;
    const [addRulesDialogOpen, setAddRulesDialogOpen] = useState<boolean>(false);
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {t('defineAsset.configureAssetRules')}
                </Typography>
                <Typography className={`${layoutClasses.text}`} style={{ width: '75%', textAlign: 'center' }}>
                    {complianceRuleGroup.categoryDescription}
                </Typography>
                {addedRules.length === 0 && (
                    <Typography className={`${layoutClasses.text}`} style={{ fontSize: 16 }}>
                        ( Skip this step if no controls are required )
                    </Typography>
                )}

                <Divider className={layoutClasses.summaryDivider} />

                {addedRules.map((addedRule, index) => {
                    if (complianceRuleGroup.rules.findIndex((rule) => rule.key === addedRule.rule.key) === -1) {
                        return <div key={index}></div>;
                    }
                    return (
                        <div key={index}>
                            <div
                                style={{
                                    display: 'flex',
                                    width: '90%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                }}
                            >
                                <div style={{ display: 'flex', margin: 'auto' }}>
                                    <div
                                        style={{
                                            color: 'white',
                                            fontSize: 20,
                                            marginTop: 'auto',
                                            marginBottom: 'auto',
                                            width: 320,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <div style={{ margin: 'auto' }} className="ruleName">
                                            {addedRule.rule.name}
                                        </div>
                                        {addedRule.rule.key === ComplianceRuleKey.Expiry && !expiryTimeValid() && (
                                            <div
                                                style={{
                                                    margin: 'auto',
                                                    marginTop: 4,
                                                    fontSize: 14,
                                                    color: 'red',
                                                }}
                                            >
                                                {'( Minimum expiry time is 30 minutes )'}
                                            </div>
                                        )}
                                    </div>

                                    <IconButton
                                        style={{}}
                                        onClick={() => {
                                            removeComplianceRule(index);
                                        }}
                                        className="deleteRuleButton"
                                    >
                                        <DeleteForeverIcon
                                            style={{
                                                height: 30,
                                                width: 30,
                                                marginTop: 'auto',
                                                marginBottom: 'auto',
                                            }}
                                            color="secondary"
                                        />
                                    </IconButton>
                                </div>
                            </div>

                            {addedRule.rule.key === ComplianceRuleKey.Expiry ? (
                                <div style={{ display: 'flex' }}>
                                    <TextField
                                        error={!expiryTimeValid()}
                                        id="DaysInput"
                                        label={'Days'}
                                        type="number"
                                        className={` ${layoutClasses.dayInput}  ${layoutClasses.formInputSmall}`}
                                        style={{ marginRight: 30 }}
                                        value={expiryDays}
                                        inputProps={{
                                            step: 1,
                                        }}
                                        onChange={(event) => {
                                            let val = Math.max(0, parseFloat(event.target.value)).toString();
                                            setExpiryDays(val);
                                        }}
                                        variant="filled"
                                        required
                                    />
                                    <TextField
                                        error={!expiryTimeValid()}
                                        id="HoursInput"
                                        label={'Hours'}
                                        type="number"
                                        className={` ${layoutClasses.dayInput}  ${layoutClasses.formInputSmall} `}
                                        style={{ marginRight: 30 }}
                                        value={expiryHours}
                                        inputProps={{
                                            step: 1,
                                        }}
                                        onChange={(event) => {
                                            let val = Math.max(
                                                Math.min(parseFloat(event.target.value), 23),
                                                0
                                            ).toString();
                                            setExpiryHours(val);
                                        }}
                                        variant="filled"
                                        required
                                    />
                                    <TextField
                                        error={!expiryTimeValid()}
                                        id="MinutesInput"
                                        label={'Minutes'}
                                        type="number"
                                        className={` ${layoutClasses.dayInput}  ${layoutClasses.formInputSmall} `}
                                        style={{ marginRight: 30 }}
                                        value={expiryMinutes}
                                        inputProps={{
                                            step: 1,
                                        }}
                                        onChange={(event) => {
                                            let val = Math.min(
                                                Math.max(0, parseFloat(event.target.value)),
                                                59
                                            ).toString();
                                            setExpiryMinutes(val);
                                        }}
                                        variant="filled"
                                        required
                                    />
                                </div>
                            ) : (
                                <TextField
                                    id={'RuleInput' + addedRule.rule.name.replace(/\s/g, '')}
                                    className={` ${layoutClasses.formInput} ${layoutClasses.formInputSmall}`}
                                    label={
                                        parseFloat(addedRule.value) <= 0 || addedRule.value.length <= 0
                                            ? 'Value must be greater than 0'
                                            : addedRule.rule.name
                                    }
                                    onChange={(event) => {
                                        updateRuleValue(event, index);
                                    }}
                                    name="ruleInput"
                                    value={NumberFormat.addThousandSeparators(parseFloat(addedRule.value))}
                                    required
                                    variant="filled"
                                    data-testid="rule_value_input"
                                    style={{ width: 350, marginLeft: 'auto', marginRight: 'auto' }}
                                    error={parseFloat(addedRule.value) <= 0 || addedRule.value.length <= 0}
                                />
                            )}

                            <Divider
                                className={layoutClasses.summaryDivider}
                                style={{
                                    width: 300,
                                    color: 'white',
                                    backgroundColor: 'white',
                                    height: 0.2,
                                    margin: 'auto',
                                }}
                            />
                        </div>
                    );
                })}

                <Button
                    variant="outlined"
                    className={`${layoutClasses.button} ${layoutClasses.buttonAutoSize}`}
                    startIcon={<AddIcon />}
                    onClick={() => {
                        setAddRulesDialogOpen(true);
                    }}
                    disabled={addedRules.length === totalRules}
                    data-testid={'add_rule_btn'}
                    id="AddRuleButton"
                >
                    Add Control
                </Button>
                <ComplianceRulesDialog
                    setAddRulesDialogOpen={setAddRulesDialogOpen}
                    open={addRulesDialogOpen}
                    addComplianceRule={addComplianceRule}
                    rules={complianceRuleGroup}
                    addedRules={addedRules}
                />
            </Paper>
        </div>
    );
};

export default AssetRulesStep;
