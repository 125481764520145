import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useRadioStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            label: {
                color: 'white',
            },
            labelSelected: {
                color: theme.palette.secondary.main,
            },
            checkBox: {
                marginRight: 20,
                '& .MuiIconButton-label': {
                    color: 'white',
                },
            },
            checkBoxSelected: {
                marginRight: 20,
                '& .MuiIconButton-label': {
                    color: theme.palette.secondary.main + ' !important',
                },
            },
            root: {
                display: 'flex',
            },
            formControl: {
                margin: theme.spacing(3),
            },
        }),
    { index: 1 }
);
