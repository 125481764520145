import { Divider, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@material-ui/core';

import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useTranslation } from 'react-i18next';

type SelectParticipantStepProps = {
    selectedParticipant: string;
    setSelectedParticipant: (party: string) => void;
    participants: string[];
    title: string;
    warningText?: string;
};

const SelectParticipantStep: React.FC<SelectParticipantStepProps> = (props) => {
    const { selectedParticipant, setSelectedParticipant, participants, title, warningText } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();

    const handleParticipantChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedParticipant(event.target.value as string);
    };

    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>{title}</Typography>
                <Divider className={layoutClasses.summaryDivider} />
                {warningText && <Typography className={`${layoutClasses.text}`}>{warningText}</Typography>}
                {participants.length > 0 && (
                    <FormControl
                        id="SelectParticipant"
                        variant="outlined"
                        className={`${layoutClasses.selectInput} ${layoutClasses.columnNoPadding}`}
                    >
                        <InputLabel id="SelectLabel">{t('commonText.participant')}</InputLabel>
                        <Select
                            labelId="SelectLabel"
                            value={selectedParticipant}
                            onChange={handleParticipantChange}
                            data-testid={'participant-select'}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {participants
                                .filter((p) => !p.toLowerCase().includes('cb'))
                                .map((participant, i) => {
                                    return (
                                        <MenuItem key={participant + i} value={participant}>
                                            {participant}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                )}
            </Paper>
        </div>
    );
};

export default SelectParticipantStep;
