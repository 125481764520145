import { AppConfig } from '@r3/cbdc-asset-frontend-core';

export const defaultTheme: AppConfig = {
    appType: 'centralbank',
    appName: 'Central Bank',
    title: 'Central Bank',
    defaultIcons: 'true',
    feedbackFormUrl:
        'https://forms.office.com/Pages/ResponsePage.aspx?id=Lh--pBAtlUGHzXNqyptnLJ2vWFCTYNBEhdZZwJu2CjlUQklTTVRSNDBTVkJaNDA1UkNDTjlWTjE1RS4u&embed=true',
    backgroundColour: '#192231',
    primaryColourMain: '#24344d',
    primaryColourDark: '#192231',
    secondaryColourMain: '#75c7ea',
    wholesaleBankColour: '#ffc48a',
    centralBankColour: '#75c7ea',
    centralBankName: 'Central Bank',
    wholesaleBankOneName: 'Wholesale Bank One',
    wholesaleBankTwoName: 'Wholesale Bank Two',
    wholesaleBankThreeName: 'Wholesale Bank Three',
    borderRadius: 10,
    nivoPieTheme: '',
};
