import { useCallback, useEffect, useState } from 'react';

import { ParticipantsResponse } from '@r3/cbdc-asset-frontend-core';
import { ResolvedPromise } from '../api/resolvePromise';
import { getOurOwnIdentity } from '../api/nodeDetailsApi';

export default function useGetOurOwnIdentity(fetchDefault?: boolean) {
    const [identity, setIdentity] = useState<string>('');

    const getOwnIdentity = useCallback(async () => {
        const participantsData: ResolvedPromise = await getOurOwnIdentity();
        if (participantsData.error) {
        } else {
            let participants = participantsData.data as ParticipantsResponse;
            participants.participants = participants.participants.filter(
                (p) => !p.toLowerCase().includes('bond') && !p.toLowerCase().includes('notary')
            );
            setIdentity(participants.participants[0]);
        }
    }, []);

    useEffect(() => {
        if (fetchDefault) {
            getOwnIdentity();
        }
    }, [fetchDefault, getOwnIdentity]);

    return { identity, getOwnIdentity };
}
