import { Divider, Fade, Paper, SvgIcon, Typography } from '@material-ui/core';
import {
    NumberFormat,
    RedemptionRequest,
    TransactionRequestState,
    useInteractiveChecklistContext,
} from '@r3/cbdc-asset-frontend-core';
import React, { useCallback, useEffect, useState } from 'react';
import { approveRedemptionRequest, denyRedemptionRequest, requestAllRedemptionRequests } from 'api/redemptionApi';

import { ReactComponent as BondsIcon } from 'icons/bonds.svg';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton } from '@material-ui/core';
import { InfoWidget } from 'components/infoModal/InfoWidget/InfoWidget';
import { ListHeader } from 'components/commonComponents/ListHeader/ListHeader';
import { RedemptionRequestDialog } from './RedempationRequestDialog';
import { ResolvedPromise } from 'api/resolvePromise';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import useFilterRequests from 'hooks/FilterRequests';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useListStyles } from '@r3/cbdc-asset-frontend-core';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const RedemptionRequests = () => {
    const listClasses = useListStyles();
    const layoutClasses = useLayoutStyles();
    const [redemptionRequestsByRequestor, setRedemptionRequestsByRequestor] =
        useState<Map<string, RedemptionRequest[]>>();
    const [collapsedKeys, setCollapesedKeys] = useState<string[]>([]);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState<string>('');
    const [selectedRedemptionRequest, setSelectedRedemptionRequest] = useState<RedemptionRequest | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [showInfoWidget, setShowInfoWidget] = useState<boolean>(false);
    const checkListContext = useInteractiveChecklistContext();
    const location = useLocation();
    const { filterSwitches, displayRequestType } = useFilterRequests();

    const loadRedemptionRequests = useCallback(async () => {
        const redemptionRequestsResponse: ResolvedPromise = await requestAllRedemptionRequests();
        if (redemptionRequestsResponse.error) {
            enqueueSnackbar(t('error.gettingRedemptionRequests', { error: redemptionRequestsResponse.error }), {
                variant: 'error',
            });
            setError(
                redemptionRequestsResponse.error.length > 0 ? redemptionRequestsResponse.error : t('error.networkError')
            );
        } else {
            if (redemptionRequestsResponse.data.length <= 0) {
                const warningText = t('redemptionRequests.noRequests');
                enqueueSnackbar(warningText, {
                    variant: 'info',
                });
                setError((text) => text + (text.length > 0 ? '. ' : '') + warningText);
            }
            let groups = (redemptionRequestsResponse.data as RedemptionRequest[]).groupBy('requestorParty') as Map<
                string,
                RedemptionRequest[]
            >;
            setRedemptionRequestsByRequestor(groups);
            setCollapesedKeys(groups.size < 2 ? [] : Array.from(groups.keys()));
        }
    }, [t, enqueueSnackbar]);

    useEffect(() => {
        loadRedemptionRequests();
    }, [loadRedemptionRequests]);

    const submitApproval = async () => {
        if (!selectedRedemptionRequest) {
            return;
        }
        const approvalResponse: ResolvedPromise = await approveRedemptionRequest(selectedRedemptionRequest.requestId);

        if (approvalResponse.error) {
            enqueueSnackbar(t('error.approveRedemptionRequest', { error: approvalResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.approveRedemptionRequest'), {
                variant: 'success',
            });
            checkListContext?.completeStep(location.pathname);
        }
    };

    const submitDeny = async () => {
        if (!selectedRedemptionRequest) {
            return;
        }
        const denyResponse: ResolvedPromise = await denyRedemptionRequest(selectedRedemptionRequest.requestId);

        if (denyResponse.error) {
            enqueueSnackbar(t('error.denyRedemptionRequest', { error: denyResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.denyRedemptionRequest'), {
                variant: 'success',
            });
        }
    };

    const openInfoModal = (redemptionRequest: RedemptionRequest) => {
        setSelectedRedemptionRequest(redemptionRequest);
        setModalOpen(true);
    };

    const handleClose = () => {
        setSelectedRedemptionRequest(null);
        setModalOpen(false);
    };

    const approve = async () => {
        handleClose();
        await submitApproval();
        loadRedemptionRequests();
        setShowInfoWidget(true);
    };

    const deny = async () => {
        handleClose();
        await submitDeny();
        loadRedemptionRequests();
        setShowInfoWidget(true);
    };

    const handlePublicKeyClick = (key: string) => {
        if (collapsedKeys.includes(key)) {
            setCollapesedKeys((keys) => keys.filter((x) => x !== key));
        } else {
            setCollapesedKeys((keys) => keys.concat([key]));
        }
    };

    return (
        <>
            <Fade in={true}>
                <div className={`${layoutClasses.componentWrapper} `}>
                    <Paper elevation={5} className={`${layoutClasses.column} `}>
                        {error.length > 0 ? (
                            <ListHeader
                                text={error}
                                refreshAction={() => {
                                    setError('');
                                    loadRedemptionRequests();
                                }}
                            />
                        ) : (
                            <>
                                <ListHeader text={'Redemption Requests List'} refreshAction={loadRedemptionRequests} />
                                {filterSwitches}
                                <Divider className={listClasses.divider} />
                                <div className={listClasses.list}>
                                    {redemptionRequestsByRequestor !== undefined &&
                                        redemptionRequestsByRequestor.mapGroups((requestor, items) => {
                                            const publickKeyElement: JSX.Element = (
                                                <div
                                                    className={`${listClasses.sortKey} requestingPartyGrouping`}
                                                    data-testid={'holder_filter'}
                                                >
                                                    <div>
                                                        <p
                                                            className="stateHolderName"
                                                            data-testid={'holder_filter_name'}
                                                        >
                                                            <b>{t('cbdcRequests.requestingParty') + ':'} </b>
                                                            {requestor}
                                                        </p>
                                                    </div>
                                                    <IconButton
                                                        className={`${listClasses.sortKeyButton} collapseButton`}
                                                        onClick={() => handlePublicKeyClick(requestor)}
                                                        data-testid={'holder_filter_expand'}
                                                    >
                                                        {collapsedKeys.includes(requestor) ? (
                                                            <ExpandMoreIcon color="secondary" />
                                                        ) : (
                                                            <ExpandLessIcon color="secondary" />
                                                        )}
                                                    </IconButton>
                                                </div>
                                            );

                                            if (collapsedKeys.includes(requestor)) {
                                                return <div key={requestor}>{publickKeyElement} </div>;
                                            }

                                            return (
                                                <div key={requestor}>
                                                    {publickKeyElement}
                                                    {items
                                                        .filter((req) =>
                                                            displayRequestType(
                                                                req.requestState as TransactionRequestState
                                                            )
                                                        )
                                                        .map((request) => {
                                                            let statusColor = 'white';

                                                            if (request.requestState === 'REQUESTED') {
                                                                statusColor = 'yellow';
                                                            } else if (request.requestState === 'APPROVED') {
                                                                statusColor = 'lawngreen';
                                                            } else if (request.requestState === 'DENIED') {
                                                                statusColor = 'red';
                                                            }
                                                            return (
                                                                <div
                                                                    data-testid={'request_container'}
                                                                    key={request.requestId}
                                                                    className={`${listClasses.item} ${listClasses.clickableItem} redemptionRequest`}
                                                                    onClick={() => openInfoModal(request)}
                                                                >
                                                                    <div>
                                                                        <div
                                                                            className={`${listClasses.text} assetName`}
                                                                        >
                                                                            {t('defineAsset.assetName') + ': '}
                                                                            {request.token.tokenName}
                                                                        </div>
                                                                        <div
                                                                            style={{ display: 'flex' }}
                                                                            className="bondsOffered"
                                                                        >
                                                                            <Typography style={{ fontSize: 14 }}>
                                                                                {'Bond Value Offered'}
                                                                            </Typography>
                                                                            <SvgIcon style={{ width: 16, height: 16 }}>
                                                                                <BondsIcon />
                                                                            </SvgIcon>
                                                                            <Typography
                                                                                style={{ fontSize: 14 }}
                                                                                className="bondsOfferedAmount"
                                                                            >
                                                                                {': ' +
                                                                                    NumberFormat.addThousandSeparators(
                                                                                        request.requestedAmount
                                                                                    )}
                                                                            </Typography>
                                                                        </div>
                                                                        <div
                                                                            className={`${listClasses.text} requestStatus`}
                                                                        >
                                                                            <span>{t('commonText.status') + ': '}</span>
                                                                            <span style={{ color: statusColor }}>
                                                                                {request.requestState}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <TouchAppIcon
                                                                        id="TouchIcon"
                                                                        color="secondary"
                                                                        className={listClasses.requestIcon}
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            );
                                        })}
                                </div>
                                <Divider className={listClasses.divider} />
                                {selectedRedemptionRequest && modalOpen && (
                                    <RedemptionRequestDialog
                                        open={modalOpen}
                                        request={selectedRedemptionRequest}
                                        approve={approve}
                                        onClose={handleClose}
                                        decline={deny}
                                    />
                                )}
                            </>
                        )}
                    </Paper>
                </div>
            </Fade>
            <InfoWidget showing={showInfoWidget} />
        </>
    );
};
