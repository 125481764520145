import { DialogAction, DialogActions, DialogBody, DialogWindow, useDialogStyles } from '@r3/cbdc-asset-frontend-core';
import { Divider, Typography } from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import React from 'react';
import { useTranslation } from 'react-i18next/';

export interface SimpleDialogProps {
    open: boolean;
    kycId: string;
    requester: string;
    assetName: string;
    revoke: () => void;
    onClose: () => void;
}

export function KycRevokeDialog(props: SimpleDialogProps) {
    const { onClose, revoke, kycId, requester, assetName, open } = props;
    const { t } = useTranslation();
    const classes = useDialogStyles();

    return (
        <DialogWindow title={t('listKyc.resolveKycRequest')} open={open} onClose={onClose}>
            <Divider className={classes.divider} />
            <DialogBody>
                <Typography gutterBottom>
                    {t('defineAsset.assetName') + ': '}
                    {assetName}
                </Typography>
                <Typography gutterBottom>
                    {t('commonText.kycId') + ': '}
                    {kycId}
                </Typography>
                <Typography gutterBottom>
                    {t('listKyc.requester') + ' '}
                    {requester}
                </Typography>
            </DialogBody>
            <DialogActions>
                <DialogAction text={'Revoke'} action={revoke} icon={<HighlightOffIcon />} />
            </DialogActions>
        </DialogWindow>
    );
}
