import { FormControlLabel, Radio } from '@material-ui/core';

import { InterestRateApplicationState } from '@r3/cbdc-asset-frontend-core';
import { useRadioStyles } from 'materialStyles/radioStyles';

type RadioProps = {
    selected: boolean;
    state: InterestRateApplicationState;
    labelText?: string;
};

const InterestRateRadioItem: React.FC<RadioProps> = ({ state, selected, labelText }) => {
    const radioClasses = useRadioStyles();
    return (
        <FormControlLabel
            key={state}
            id={state}
            className={`${radioClasses.label} ${selected ? radioClasses.labelSelected : ''} interestRateSelectLabel`}
            value={state}
            control={
                <Radio
                    className={`${
                        selected ? radioClasses.labelSelected : radioClasses.label
                    } interestRateSelectRadioButton`}
                />
            }
            label={labelText ?? state}
            data-testid={'interest_radio_button'}
        />
    );
};

export default InterestRateRadioItem;
