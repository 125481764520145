import { AVAILABLECBDC, DEFINECBDC, ISSUECBDC, TRANSFERCBDC, UPDATEINTERESTRATECBDCMANAGER } from 'constants/Routes';
import { TabPageMap, TabsWrapper, insertIntoArrayIf, useFeatureFlagContext } from '@r3/cbdc-asset-frontend-core';

import CallMadeRoundedIcon from '@material-ui/icons/CallMadeRounded';
import { CreateAsset } from 'components/cbdcManager/CreateAsset/CreateAsset';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import { IssueCBDC } from 'components/cbdcManager/IssueCBDC/IssueCBDC';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import React from 'react';
import { TransferAssets } from '../TransferAssets/TransferAssets';
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';
import UpdateIcon from '@material-ui/icons/Update';
import UpdateInterestRate from 'components/treasuryDashboard/UpdateInterestRate/UpdateInterestRate';
import { ViewAssets } from 'components/cbdcManager/ViewAssets/ViewAssets';
import { useTranslation } from 'react-i18next';

export const Assets: React.FC = () => {
    const { t } = useTranslation();
    const { isFeatureEnabled } = useFeatureFlagContext();

    const tabPageMap: TabPageMap[] = [
        ...insertIntoArrayIf(isFeatureEnabled('viewAssets'), {
            route: AVAILABLECBDC,
            pageComponent: ViewAssets,
            tabDetails: { icon: <DashboardIcon />, label: t('pageTitle.availableAssets') },
        }),
        ...insertIntoArrayIf(isFeatureEnabled('defineCBDC'), {
            route: DEFINECBDC,
            pageComponent: CreateAsset,
            tabDetails: { icon: <FiberNewIcon />, label: t('pageTitle.defineCBDC') },
        }),
        ...insertIntoArrayIf(isFeatureEnabled('updateInterestRate') && isFeatureEnabled('distributedInterest'), {
            route: UPDATEINTERESTRATECBDCMANAGER,
            pageComponent: UpdateInterestRate,
            tabDetails: {
                icon: (
                    <div style={{ display: 'flex' }}>
                        <UpdateIcon style={{ width: 15, height: 15 }} /> <TrendingUpRoundedIcon />
                    </div>
                ),
                label: t('pageTitle.interestRateConfig'),
            },
        }),
        ...insertIntoArrayIf(isFeatureEnabled('issueCBDC'), {
            route: ISSUECBDC,
            pageComponent: IssueCBDC,
            tabDetails: { icon: <MonetizationOnIcon />, label: t('pageTitle.issueCBDC') },
        }),
        ...insertIntoArrayIf(isFeatureEnabled('transferCBDC'), {
            route: TRANSFERCBDC,
            pageComponent: TransferAssets,
            tabDetails: { icon: <CallMadeRoundedIcon />, label: 'Transfer CBDC' },
        }),
    ];

    return <TabsWrapper pageTitle={t('pageTitle.assets')} tabPageMap={tabPageMap} />;
};
