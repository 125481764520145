import { DialogAction, DialogActions, DialogBody, DialogWindow } from '@r3/cbdc-asset-frontend-core';

import React from 'react';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next/';

export interface SimpleDialogProps {
    open: boolean;
    kycId: string;
    requester: string;
    assetName: string;
    approve: () => void;
    onClose: () => void;
}

export function KycRequestDialogue(props: SimpleDialogProps) {
    const { onClose, approve, kycId, requester, assetName, open } = props;
    const { t } = useTranslation();

    return (
        <DialogWindow title={t('listKyc.resolveKycRequest')} open={open} onClose={onClose}>
            <DialogBody>
                <Typography gutterBottom>
                    {t('defineAsset.assetName') + ': '}
                    {assetName}
                </Typography>
                <Typography gutterBottom>
                    {t('commonText.kycId') + ': '}
                    {kycId}
                </Typography>
                <Typography gutterBottom>
                    {t('listKyc.requester') + ' '}
                    {requester}
                </Typography>
            </DialogBody>
            <DialogActions>
                <DialogAction text={t('commonText.approve')} action={approve} icon={<ThumbUpIcon />} />
            </DialogActions>
        </DialogWindow>
    );
}
