import {
    ComplianceRuleData,
    InterestRateApplicationState,
    InterestRateState,
    InterestRateValue,
    TimeUnit,
    useLayoutStyles,
} from '@r3/cbdc-asset-frontend-core';
import DateExtensions, { DAY_IN_SECONDS, MINUTE_IN_SECONDS } from 'utils/DateExtensions';
import { Divider, Paper, Typography } from '@material-ui/core';

import InterestRateSetValues from 'components/cbdcManager/CreateAsset/interestRate/InterestRateSetValues';
import { useTranslation } from 'react-i18next';

type Props = {
    interestRateState: InterestRateState | undefined;
    setInterestState: (interestRateState: InterestRateState | undefined) => void;
    interestRateComplianceRule: ComplianceRuleData | undefined;
};

const InterestRateUpdateStep: React.FC<Props> = ({
    interestRateState,
    setInterestState,
    interestRateComplianceRule,
}) => {
    const layoutClasses = useLayoutStyles();
    const { t: text } = useTranslation();

    const lastInterestStartDate = interestRateComplianceRule
        ? new Date((JSON.parse(interestRateComplianceRule.value) as InterestRateValue[])[0].effectiveDate)
        : undefined;

    //Set the default / min effective date to latest interest rate start date + 1 day and 1 minute
    const minStartDate: Date | undefined = lastInterestStartDate
        ? DateExtensions.datePlusDays(
              new Date(lastInterestStartDate).setSeconds(lastInterestStartDate.getSeconds() + MINUTE_IN_SECONDS),
              1
          )
        : undefined;

    //The backend will only allow a new interest rate to be set if its at least is 1 day and 1 minute later than previous
    const validDate: boolean | undefined =
        lastInterestStartDate && minStartDate
            ? DateExtensions.moreThanOrEqualToTimeBetween(
                  lastInterestStartDate,
                  interestRateState?.values[0].effectiveDate
                      ? new Date(interestRateState?.values[0].effectiveDate)
                      : minStartDate,
                  DAY_IN_SECONDS + MINUTE_IN_SECONDS,
                  TimeUnit.Seconds
              )
            : undefined;
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column}`}>
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {text('interestRates.addNewInterestRate')}
                </Typography>
                <Typography className={`${layoutClasses.text}`} style={{ fontSize: 16 }}>
                    {text('interestRates.interestRateExplanation')}
                </Typography>
                <Typography className={`${layoutClasses.text}`} style={{ fontSize: 16 }}>
                    {text('interestRates.setToZeroExplanation')}
                </Typography>
                <Divider className={layoutClasses.summaryDivider} />
                {lastInterestStartDate && (
                    <>
                        <Typography className={`${layoutClasses.text}`} style={{ fontSize: 16 }}>
                            {text('interestRates.lastStartDate', {
                                lastStartDate: lastInterestStartDate.toLocaleString('en-GB'),
                            })}
                        </Typography>
                    </>
                )}

                <InterestRateSetValues
                    allowZeroValue
                    interestRateState={interestRateState}
                    setInterestState={setInterestState}
                    interestRateApplyState={InterestRateApplicationState.APPLIED}
                    minDate={
                        minStartDate
                            ? minStartDate.getTime() > new Date().getTime()
                                ? minStartDate
                                : currentDateWithTimeFrom(minStartDate)
                            : undefined
                    }
                    hideTimeSelect
                    inheritTimeFromMinDate
                />

                {validDate === false && (
                    <Typography style={{ color: 'red', marginTop: 0 }}>
                        {text('interestRates.effectiveStartDateUpdateInvalid', {
                            lastStartDate: lastInterestStartDate?.toLocaleDateString('en-GB'),
                        })}
                    </Typography>
                )}
            </Paper>
        </div>
    );
};

export default InterestRateUpdateStep;

const currentDateWithTimeFrom = (date: Date) => {
    const currentDate = new Date();
    currentDate.setHours(date.getHours());
    currentDate.setMinutes(date.getMinutes());
    currentDate.setSeconds(date.getSeconds());
    return currentDate;
};
