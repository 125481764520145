export const LOGIN = '/login';
export const DASHBOARD = '/dashboard';
export const DEMODASH = '/demoDashboard';

export const ASSETDASH = DEMODASH + '/cbdcDashboard';
export const BONDSDASH = DEMODASH + '/bonds';
export const MONEYSUPPLY = DEMODASH + '/moneySupply';
export const UPDATEINTERESTRATE = DEMODASH + '/interestRateUpdate';

export const KYCCONTROL = '/kycControl';
export const ISSUEKYC = KYCCONTROL + '/issueKYC';
export const LISTKYC = KYCCONTROL + '/listKyc';

export const ASSETS = '/assets';
export const DEFINECBDC = ASSETS + '/defineCBDC';
export const ISSUECBDC = ASSETS + '/issueCBDC';
export const AVAILABLECBDC = ASSETS + '/availableCBDC';
export const TRANSFERCBDC = ASSETS + '/transferCBDC';

export const UPDATEINTERESTRATECBDCMANAGER = ASSETS + '/interestRateUpdate';

export const INCOMINGREQUESTS = '/incomingRequests';

export const CBDCREQUESTS = INCOMINGREQUESTS + '/cbdcRequests';
export const REDEEMREQUESTS = INCOMINGREQUESTS + '/redeemRequests';
export const KYCREQUESTS = INCOMINGREQUESTS + '/kycRequests';

export const TRANSACTIONCONTROLS = '/transactionControls';
export const ASSETCONTROLS = '/assetControls';

export const TRANSACTIONDASH = '/transactionDashboard';

export const NETWORKTAB = '/networkTab';

export const VISIBILITYREISSUANCE = '/visibilityAndReissuance';

export const REFARCHITECTURE = '/referenceArchitectures';
