import { ColorOperations, LineData } from '@r3/cbdc-asset-frontend-core';
import { VictoryAxis, VictoryBrushContainer, VictoryChart, VictoryGroup, VictoryLine, VictoryScatter } from 'victory';

import React from 'react';
import { getOrdinalColorScale } from '@nivo/colors';
import { useAppConfigContext } from '@r3/cbdc-asset-frontend-core';
import { useMediaQuery } from 'react-responsive';

const FALLBACK_COLOR = '#deebf7';
const LINE_INTERPOLATION = 'monotoneX';

interface BrushComponentProps {
    data: LineData[];
    updateGraph: (rangeStart: Date, rangeEnd: Date) => void;
}

export const BrushComponent: React.FC<BrushComponentProps> = (props) => {
    const { data, updateGraph } = props;
    const appConfigContext = useAppConfigContext();

    const handleBrush = (domain) => {
        updateGraph(domain.x[0], domain.x[1]);
    };

    const isSmallScreen = useMediaQuery({ maxWidth: 1500 });
    let brushHeight = 150;
    let fontSize = 14;

    if (isSmallScreen) {
        brushHeight = 190;
        fontSize = 18;
    }

    if (data.length <= 0) {
        return <></>;
    }

    let colors = ColorOperations.getColourShades(appConfigContext?.appConfig.secondaryColourMain ?? FALLBACK_COLOR);
    let presetTheme: any = appConfigContext?.appConfig.nivoPieTheme
        ? { scheme: appConfigContext.appConfig.nivoPieTheme, size: 3 }
        : undefined;
    if (presetTheme !== undefined) {
        const getColor = getOrdinalColorScale(presetTheme, 'id');
        colors = data.map((d) => getColor(d));
    }

    return (
        <div style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto', marginTop: 12 }}>
            <VictoryChart
                padding={{ top: 0, left: 50, right: 50, bottom: 30 }}
                width={1600}
                height={brushHeight}
                scale={{ x: 'time' }}
                containerComponent={
                    <VictoryBrushContainer
                        handleStyle={{ stroke: '#ffffff', fill: 'transparent' }}
                        responsive={true}
                        brushDimension="x"
                        defaultBrushArea="disable"
                        onBrushDomainChange={(e) => {
                            handleBrush(e);
                        }}
                    />
                }
            >
                <VictoryAxis
                    style={{
                        ticks: { fill: '#ffffff' },
                        tickLabels: { fill: '#ffffff', fontSize: fontSize },
                    }}
                    tickFormat={(x) => new Date(x).toLocaleDateString()}
                />
                {data.map((d, ind) => {
                    return (
                        <VictoryGroup key={d.id}>
                            <VictoryLine
                                style={{
                                    data: { stroke: colors[ind] },
                                }}
                                data={d.data}
                                interpolation={LINE_INTERPOLATION}
                            />
                            <VictoryScatter data={d.data} size={4} style={{ data: { fill: colors[ind] } }} />
                        </VictoryGroup>
                    );
                })}
            </VictoryChart>
        </div>
    );
};
