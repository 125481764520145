import { useCallback, useState } from 'react';

import { Asset } from '@r3/cbdc-asset-frontend-core';
import { ResolvedPromise } from '../api/resolvePromise';
import { requestAllAssetDefinitions } from '../api/assetDefinitionApi';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function useGetAssetDefinitions() {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [assets, setAssets] = useState<Asset[]>([]);
    const [assetsError, setError] = useState<string>('');

    const getAllAssets = useCallback(
        async (dontTrack?: boolean): Promise<boolean> => {
            let assetsData: ResolvedPromise;
            if (dontTrack) {
                assetsData = await requestAllAssetDefinitions(true);
            } else {
                assetsData = await requestAllAssetDefinitions();
            }
            if (assetsData.error) {
                if (!dontTrack) {
                    enqueueSnackbar(t('error.gettingAssets', { error: assetsData.error }), {
                        variant: 'error',
                    });
                    setError(t('error.networkError'));
                }
                return false;
            } else {
                if (!assetsData.data) {
                    setAssets([]);
                    return true;
                }
                if (assetsData.data.length <= 0 && !dontTrack) {
                    const warningText = t('viewAssets.noAssets');
                    enqueueSnackbar(warningText, {
                        variant: 'info',
                    });

                    setError((text) => text + (text.length > 0 ? '. ' : '') + warningText);
                }
                setAssets(assetsData.data as Asset[]);
                return true;
            }
        },
        [enqueueSnackbar, t]
    );

    const getAssetNamebyId = useCallback(
        (assetId: string): string => {
            let selectedAsset: Asset = { tokenDecimals: 0, tokenIdentifier: '', tokenName: '', party: '' };
            assets.forEach((asset) => {
                if (assetId === asset.tokenIdentifier) {
                    selectedAsset = asset;
                }
            });

            return selectedAsset.tokenName;
        },
        [assets]
    );

    const getAssetbyName = (assetId: string): Asset => {
        let selectedAsset: Asset = { tokenDecimals: 0, tokenIdentifier: '', tokenName: '', party: '' };
        assets.forEach((asset) => {
            if (assetId === asset.tokenName) {
                selectedAsset = asset;
            }
        });

        return selectedAsset;
    };

    return { getAssetbyName, getAssetNamebyId, getAllAssets, assetsError, assets };
}
