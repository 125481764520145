import { ColorOperations, useAppConfigContext, useDashboardStyles } from '@r3/cbdc-asset-frontend-core';
import React, { useState } from 'react';

import { NivoColourSchemes } from '../../../constants/AppConfig';
import { ResponsivePie } from '@nivo/pie';
import { Theme } from '@nivo/core';

type PieProps = {
    data: any[];
    handlePieClick: (event: any) => void;
    altColour: boolean;
    pieMarginsTopBottom?: number;
    pieMarginLeft?: number;
    pieMarginRight?: number;
    pieTheme?: Theme;
    legendEnabled?: boolean;
    radialLabelOffset?: number;
    radialLabelLength?: number;
    radialLabelsXLength?: number;
};

const radialLabelFormat = (label: string) => {
    if (!label.includes('-')) {
        return label;
    }

    const splitLabel = label.split('-');
    if (splitLabel[splitLabel.length - 1].includes('< 1%')) {
        return label;
    }

    let formated = label.substr(0, label.lastIndexOf('-'));
    return formated;
};

export const DashBoardResponsivePie: React.FC<PieProps> = ({
    data,
    handlePieClick,
    altColour,
    pieMarginsTopBottom = 100,
    pieMarginLeft = 0,
    pieMarginRight = 40,
    pieTheme = DashBoardPieTheme,
    legendEnabled = true,
    radialLabelLength = 36,
    radialLabelOffset = 20,
    radialLabelsXLength = 30,
}) => {
    const classes = useDashboardStyles();
    const [hoveringLegend, setHoveringLegend] = useState<boolean>(false);
    const appConfigContext = useAppConfigContext();

    let presetTheme: string | null = appConfigContext?.appConfig.nivoPieTheme
        ? appConfigContext.appConfig.nivoPieTheme
        : null;

    if (presetTheme && !NivoColourSchemes.includes(presetTheme)) {
        presetTheme = null;
    }

    let pieColours: string[] = appConfigContext
        ? ColorOperations.getColourShades(appConfigContext.appConfig.secondaryColourMain)
        : ['white'];

    return (
        <>
            {legendEnabled && (
                <div
                    style={{ color: 'white', cursor: 'pointer' }}
                    onMouseEnter={() => {
                        setHoveringLegend(true);
                    }}
                    onMouseLeave={() => {
                        setHoveringLegend(false);
                    }}
                >
                    Hover here for legend
                </div>
            )}

            <ResponsivePie
                fit={true}
                theme={pieTheme}
                data={data}
                isInteractive={true}
                animate={true}
                motionConfig="wobbly"
                activeOuterRadiusOffset={12}
                activeInnerRadiusOffset={8}
                transitionMode="pushIn"
                margin={{
                    top: pieMarginsTopBottom,
                    right: pieMarginRight,
                    bottom: pieMarginsTopBottom,
                    left: pieMarginLeft,
                }}
                startAngle={0}
                sortByValue={true}
                innerRadius={0.6}
                padAngle={1.5}
                enableArcLabels={false}
                cornerRadius={8}
                colors={presetTheme ? { scheme: presetTheme as any } : pieColours}
                borderColor="#949494"
                enableArcLinkLabels={!hoveringLegend}
                arcLinkLabel={(d) => ` ${radialLabelFormat(d.label as string)}`}
                arcLinkLabelsTextColor="#ffffff"
                arcLinkLabelsOffset={radialLabelOffset}
                arcLinkLabelsDiagonalLength={radialLabelLength}
                arcLinkLabelsStraightLength={radialLabelsXLength}
                arcLinkLabelsThickness={3}
                arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
                arcLabel={(d) => `${d.id.toString().toUpperCase()}`}
                arcLabelsRadiusOffset={0.45}
                arcLabelsSkipAngle={11}
                arcLabelsTextColor="#333333"
                onClick={(event) => handlePieClick(event)}
                tooltip={(input) => {
                    return <div className={classes.pieTooltip}>{input.datum.label}</div>;
                }}
                legends={
                    hoveringLegend
                        ? [
                              {
                                  anchor: 'top-left',
                                  direction: 'column',
                                  justify: false,
                                  translateX: -6,
                                  translateY: -90,
                                  itemWidth: 100,
                                  itemHeight: 20,
                                  itemsSpacing: 10,
                                  symbolSize: 20,
                                  itemDirection: 'left-to-right',
                                  itemTextColor: '#ffffff',
                                  itemOpacity: 1,
                                  effects: [
                                      {
                                          on: 'hover',
                                          style: {
                                              itemOpacity: 1,
                                          },
                                      },
                                  ],
                              },
                          ]
                        : []
                }
            />
        </>
    );
};

export const DashBoardPieTheme: Theme = {
    textColor: '#fafafa',
    fontSize: 15,
    axis: {
        domain: {
            line: {
                stroke: '#777777',
                strokeWidth: 1,
            },
        },
        ticks: {
            line: {
                stroke: '#777777',
                strokeWidth: 1,
            },
        },
    },
    grid: {
        line: {
            stroke: '#dddddd',
            strokeWidth: 2,
        },
    },
};

export const LandingPagePieTheme: Theme = {
    textColor: '#fafafa',
    fontSize: 13,
    axis: {
        domain: {
            line: {
                stroke: '#777777',
                strokeWidth: 1,
            },
        },
        ticks: {
            line: {
                stroke: '#777777',
                strokeWidth: 1,
            },
        },
    },
    grid: {
        line: {
            stroke: '#dddddd',
            strokeWidth: 2,
        },
    },
};

export const LandingPagePieThemeSmall: Theme = {
    textColor: '#fafafa',
    fontSize: 12,
    axis: {
        domain: {
            line: {
                stroke: '#777777',
                strokeWidth: 1,
            },
        },
        ticks: {
            line: {
                stroke: '#777777',
                strokeWidth: 1,
            },
        },
    },
    grid: {
        line: {
            stroke: '#dddddd',
            strokeWidth: 2,
        },
    },
};
