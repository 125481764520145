import {
    CBDCIssuanceRequest,
    DialogAction,
    DialogActions,
    DialogBody,
    DialogWindow,
    NumberFormat,
} from '@r3/cbdc-asset-frontend-core';
import { SvgIcon, Typography } from '@material-ui/core';

import { ReactComponent as BondsIcon } from 'icons/bonds.svg';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { useTranslation } from 'react-i18next/';

export interface SimpleDialogProps {
    open: boolean;
    request: CBDCIssuanceRequest;
    approve: () => void;
    decline: () => void;
    onClose: () => void;
}

export function CBDCRequestDialogue(props: SimpleDialogProps) {
    const { onClose, approve, request, open, decline } = props;
    const { t } = useTranslation();

    return (
        <DialogWindow
            title={t('cbdcRequests.resolveCBDCIssuanceRequest') + ' : ' + request.requestState}
            onClose={onClose}
            open={open}
        >
            <DialogBody>
                <Typography gutterBottom id="requestId">
                    {t('cbdcRequests.requestId') + ':  '}
                    {request.requestId}
                </Typography>
                <Typography gutterBottom id="requestor">
                    {t('listKyc.requester') + '   '}
                    {request.requestorParty}
                </Typography>
                <Typography gutterBottom id="assetName">
                    {t('defineAsset.assetName') + ':  '}
                    {request.token.tokenName}
                </Typography>
                <Typography gutterBottom id="amount">
                    {t('commonText.amount') + ':  '}
                    {NumberFormat.addThousandSeparators(request.requestedAmount)}
                </Typography>
                <div style={{ display: 'flex' }}>
                    <Typography gutterBottom id="valueInBonds">
                        {t('commonText.value') + '  in bonds'}
                    </Typography>
                    <SvgIcon style={{ width: 18, height: 18 }}>
                        <BondsIcon />
                    </SvgIcon>
                    <Typography gutterBottom>
                        {':  ' + NumberFormat.addThousandSeparators(request.requestedAmount)}
                    </Typography>
                </div>
            </DialogBody>

            {!(request.requestState === 'APPROVED' || request.requestState === 'DENIED') && (
                <DialogActions>
                    <DialogAction text={t('commonText.approve')} action={approve} icon={<ThumbUpIcon />} />
                    <DialogAction text={t('commonText.deny')} action={decline} icon={<NotInterestedIcon />} />
                </DialogActions>
            )}
        </DialogWindow>
    );
}
