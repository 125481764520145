import { AppConfig, useAppConfigContext } from '@r3/cbdc-asset-frontend-core';
import React, { useEffect, useState } from 'react';
import { Theme, ThemeProvider, createMuiTheme } from '@material-ui/core';

import { App } from '../app/App';
import { defaultTheme } from 'constants/Theme';

export const ThemeController: React.FC = ({ children }) => {
    const appConfigContext = useAppConfigContext();
    const [customTheme, setCustomTheme] = useState<Theme | undefined>(undefined);
    useEffect(() => {
        if (appConfigContext) {
            setCustomTheme(getThemeFromConfig(appConfigContext.appConfig));
        }
    }, [appConfigContext]);

    console.log(customTheme);

    return (
        <ThemeProvider theme={customTheme ?? getThemeFromConfig(defaultTheme)}>
            {children} <App />
        </ThemeProvider>
    );
};

const getThemeFromConfig = (themeJson: AppConfig): Theme => {
    const theme = createMuiTheme({
        palette: {
            action: {
                disabledBackground: 'grey',
                disabled: 'grey',
                disabledOpacity: 0.6,
            },
            background: {
                default: themeJson.backgroundColour,
            },
            primary: {
                main: themeJson.primaryColourMain,
                dark: themeJson.primaryColourDark,
            },
            secondary: {
                main: themeJson.secondaryColourMain,
            },
        },
        shape: {
            borderRadius: themeJson.borderRadius,
        },
        typography: {
            fontFamily: ['Open Sans', 'sans-serif'].join(','),
        },
        spacing: 4,
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    '*::-webkit-scrollbar': {
                        width: '0.7em',
                    },
                    '*::-webkit-scrollbar-track': {
                        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '*::-webkit-scrollbar-thumb': {
                        backgroundColor: themeJson.secondaryColourMain,
                        outline: '2px solid grey',
                    },
                },
            },
        },
    });

    return theme;
};
