import { AssetResponse, NumberFormat, ResponsivePieData } from '@r3/cbdc-asset-frontend-core';
import { useCallback, useState } from 'react';

export function useDashboardPieData(pieSize: 'BIG' | 'SMALL' = 'BIG') {
    const [uniqueAssets, setUniqueAssets] = useState<string[]>([]);
    const [assetsInVault, setAssetsInVault] = useState<number>(0);
    const [pieData, setPieData] = useState<ResponsivePieData[]>([]);
    const updatePieData = useCallback(
        (vaultAssets: AssetResponse[], filterAssets: string[]) => {
            let responsePieData: ResponsivePieData[] = [];
            let assetsInVault = 0;

            vaultAssets.forEach((asset) => {
                let toAdd: ResponsivePieData = {
                    id: asset.tokenDefinition.tokenName,
                    label: asset.tokenDefinition.tokenName.toUpperCase(),
                    value: parseFloat(asset.totalAmount.toFixed(asset.tokenDecimals)),
                    color: 'white',
                };
                assetsInVault += asset.totalAmount;
                setUniqueAssets((uniqueAssets) => uniqueAssets.concat([asset.tokenDefinition.tokenName]));

                if (filterAssets.includes(asset.tokenDefinition.tokenName)) {
                    responsePieData.push(toAdd);
                    return;
                }
            });
            setAssetsInVault(assetsInVault);
            let totalToDisplay = 0;
            responsePieData.forEach((d) => {
                totalToDisplay += d.value;
            });

            let belowOnePercentSizeMultiplier = pieSize === 'BIG' ? 5 : 5.8;

            responsePieData.forEach((d, index) => {
                responsePieData[index].label =
                    responsePieData[index].label +
                    ' :  ' +
                    NumberFormat.addThousandSeparators(responsePieData[index].value).toString();
                let percentage = ((responsePieData[index].value / assetsInVault) * 100).toFixed(2);
                if (responsePieData[index].value < totalToDisplay / 100) {
                    responsePieData[index].label = responsePieData[index].label + ' - < 1%';
                    responsePieData[index].value +=
                        (totalToDisplay / 100) * belowOnePercentSizeMultiplier - responsePieData[index].value;
                } else {
                    responsePieData[index].label = responsePieData[index].label + ` - ${percentage}%`;
                }

                if (parseFloat(percentage) > 1 && parseFloat(percentage) < 3.5) {
                    responsePieData[index].value += (totalToDisplay / 100) * 5 - responsePieData[index].value;
                }
            });
            setPieData(responsePieData);
        },
        [pieSize]
    );

    return { uniqueAssets, assetsInVault, pieData, updatePieData };
}
