import { TimeSeries, TokenDataQuery } from '@r3/cbdc-asset-frontend-core';

import apiCall from './apiCall';
import { axiosInstance } from './apiConfig';
import { resolvePromise } from './resolvePromise';

/**
 * @remark
 * Used to get the money supply graph points for a specific tokenDefinition
 *
 * @param tokenIdentifier the tokenIdentifier of the tokenDefinition
 *
 * @returns
 * AxiosResponse which resolves in graph point data or error
 */
export const getMoneySupplyData = async (
    tokenIdentifier: string,
    nDatapoints: number = 100,
    includeActualInterest: boolean = false,
    includeExpectedInterest: boolean = false
) => {
    return resolvePromise<TimeSeries>(
        axiosInstance.post(
            `/api/report/moneySupply`,
            {
                tokenIdentifier: tokenIdentifier,
                nDatapoints: nDatapoints,
            },
            {
                params: {
                    includeActualInterest,
                    includeExpectedInterest,
                },
            }
        )
    );
};

export const getMoneySupplyWithInterest = async (tokenIdentifier: string, from?: Date, to?: Date) =>
    apiCall({
        method: 'post',
        path: '/api/report/cb/moneySupplyWithInterest',
        params: {
            tokenIdentifier,
            txTimeFrom: from,
            txTimeTo: to,
        },
    });

export const getMoneySupplyWithExpectedInterest = async (tokenIdentifier: string, from?: Date, to?: Date) =>
    apiCall({
        method: 'post',
        path: '/api/report/cb/moneySupplyWithExpectedInterest',
        params: {
            tokenIdentifier,
            txTimeFrom: from,
            txTimeTo: to,
        },
    });

/**
 * @remark
 * User to request corda tx logs
 *
 * @param query used to create a query object for the api (See TokenDataQuery definition for more details)
 * @returns
 * AxiosResponse which resolves in treasury logs or error
 */
export const requestTokenData = (query: TokenDataQuery) =>
    apiCall({
        method: 'post',
        path: '/api/report/treasuryLogs',
        params: query,
    });
