import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useDatePickerStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            datePickerWrapper: {
                width: '100%',
                margin: 'auto',
                borderRadius: theme.shape.borderRadius,
                paddingTop: 16,
                paddingBottom: 16,
                marginBottom: 12,
                backgroundColor: theme.palette.primary.main,
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
            },
            datePicker: {
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 8,
                marginBottom: 8,
                width: 130,
                height: 40,
                padding: 10,
                borderRadius: theme.shape.borderRadius,
                fontSize: 14,
                borderStyle: 'solid',
                fontWeight: 'normal',

                '& label': {
                    color: theme.palette.primary.contrastText,
                    fontSize: 14,
                },

                '& button': {
                    color: theme.palette.secondary.main,
                },
            },
            large: {
                height: 56,
                fontSize: 16,
            },
            datePickerWithTime: {
                width: 180,
            },
            textLow: {
                paddingBottom: 3,
            },
            datePickerLabel: {
                color: theme.palette.primary.contrastText,
            },
            error: {
                borderColor: 'red',
            },
        }),
    { index: 1 }
);
