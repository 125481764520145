import {
    Asset,
    ComplianceRuleKey,
    InterestRateState,
    InterestRateValue,
    StepperFormStep,
    TimeUnit,
    useInteractiveChecklistContext,
} from '@r3/cbdc-asset-frontend-core';
import DateExtensions, { DAY_IN_SECONDS, MINUTE_IN_SECONDS } from 'utils/DateExtensions';
import React, { useEffect, useState } from 'react';

import { Fade } from '@material-ui/core';
import InterestRateOverviewStep from './InterestRateOverviewStep';
import InterestRateUpdateStep from './InterestRateUpdateStep';
import InterestRateUpdateSummary from './InterestRateUpdateOverview';
import { ResolvedPromise } from 'api/resolvePromise';
import SelectAssetStep from 'components/commonComponents/GenericFormSteps/SelectAssetStep';
import { StepperForm } from '@r3/cbdc-asset-frontend-core';
import { updateComplianceRule } from 'api/assetDefinitionApi';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const UpdateInterestRate: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>(undefined);
    const [interestRateState, setInterestState] = useState<InterestRateState | undefined>(undefined);
    const [error, setError] = useState<string>('');
    const { assets, getAllAssets, assetsError } = useGetAssetDefinitions();
    const checkListContext = useInteractiveChecklistContext();
    const { t } = useTranslation();

    const handleReset = () => {
        setSelectedAsset(undefined);
        setInterestState(undefined);
        getAllAssets();
    };

    useEffect(() => {
        getAllAssets();
    }, [getAllAssets]);

    useEffect(() => {
        setError(assetsError);
    }, [assetsError]);

    const submitUpdate = async () => {
        if (!selectedAsset || !interestRateState) return;
        const response: ResolvedPromise = await updateComplianceRule(selectedAsset.tokenIdentifier, {
            key: ComplianceRuleKey.InterestRate,
            value: JSON.stringify(interestRateState.values),
        });
        if (response.error) {
            enqueueSnackbar(t('error.updatingInterestRate', { error: response.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.updatingInterestRate'), {
                variant: 'success',
            });
        }
        checkListContext?.completeStep(window.location.pathname);
        handleReset();
    };

    const getSelectedAsset = (assetName: string): Asset | undefined => {
        return assets.find((asset) => assetName === asset.tokenName);
    };

    const handleAssetChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let asset = getSelectedAsset(event.target.value as string);
        setSelectedAsset(asset);
        setInterestState(undefined);
    };

    const interestRateComplianceRule = selectedAsset?.complianceRules!
        ? selectedAsset.complianceRules.find((rule) => rule.key === ComplianceRuleKey.InterestRate)
        : undefined;

    const steps: StepperFormStep[] = [
        {
            title: t('issueAsset.selectAsset'),
            component: (
                <SelectAssetStep
                    headerText={t('interestRates.selectAssetToUpdateText')}
                    assetName={selectedAsset?.tokenName ?? undefined}
                    handleAssetChange={handleAssetChange}
                    assets={assets}
                />
            ),
            isNextStepDisabled: () => {
                if (!selectedAsset) {
                    return true;
                }
                if (selectedAsset.tokenName.length > 0) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('interestRates.interestOverviewStep'),
            component: (
                <InterestRateOverviewStep
                    assetName={selectedAsset ? selectedAsset.tokenName : ''}
                    interestRateComplianceRule={interestRateComplianceRule}
                />
            ),
            isNextStepDisabled: () => false,
        },
        {
            title: t('interestRates.updateInterestRate'),
            component: (
                <InterestRateUpdateStep
                    interestRateState={interestRateState}
                    setInterestState={setInterestState}
                    interestRateComplianceRule={interestRateComplianceRule}
                />
            ),
            isNextStepDisabled: () => {
                if (interestRateState === undefined) {
                    return true;
                }
                const interestRateValue = interestRateState.values[0].yearlyInterest;
                if (Number.isNaN(interestRateValue)) {
                    return true;
                }
                if (
                    interestRateComplianceRule &&
                    !DateExtensions.moreThanOrEqualToTimeBetween(
                        new Date(
                            (JSON.parse(interestRateComplianceRule.value) as InterestRateValue[])[0].effectiveDate
                        ),
                        new Date(interestRateState.values[0].effectiveDate),
                        DAY_IN_SECONDS + MINUTE_IN_SECONDS,
                        TimeUnit.Seconds
                    )
                ) {
                    return true;
                }
                return false;
            },
        },
        {
            title: t('interestRates.updateSummaryStep'),
            component: <InterestRateUpdateSummary interestRateState={interestRateState} />,
            isNextStepDisabled: () => false,
        },
    ];

    return (
        <>
            <Fade in={true}>
                <StepperForm
                    submit={submitUpdate}
                    steps={steps}
                    submitButtonText={'Update'}
                    handleReset={handleReset}
                    renderError={error}
                />
            </Fade>
        </>
    );
};

export default UpdateInterestRate;
