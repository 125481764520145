import { Divider, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Typography } from '@material-ui/core';

import React from 'react';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useRadioStyles } from 'materialStyles/radioStyles';
import { useTranslation } from 'react-i18next';

type SelectNotariesStepProps = {
    notaries: string[];
    setSelectedNotaries: (val: string) => void;
    selectedNotary: string;
};

const SelectNotariesStep: React.FC<SelectNotariesStepProps> = (props) => {
    const { notaries, setSelectedNotaries, selectedNotary } = props;
    const layoutClasses = useLayoutStyles();
    const radioClasses = useRadioStyles();
    const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedNotaries(event.target.value);
    };

    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {t('selectNotaries.selectNotaries')}
                </Typography>
                <Divider className={layoutClasses.summaryDivider} />

                <Typography className={`${layoutClasses.sectionLabel} `} style={{ fontSize: 16 }}>
                    {'The CBDC asset will be tied to this notary.'}
                </Typography>
                <Typography className={`${layoutClasses.sectionLabel} `} style={{ fontSize: 16, marginBottom: 24 }}>
                    {'Assets on different notaries can be swapped using the Cross Chain Swap feature.'}
                </Typography>

                <FormControl component="fieldset">
                    <RadioGroup aria-label="gender" name="gender1" value={selectedNotary} onChange={handleChange}>
                        {notaries.map((notary) => {
                            let selected = notary === selectedNotary;
                            return (
                                <FormControlLabel
                                    key={notary}
                                    id={notary}
                                    className={`${radioClasses.label} ${
                                        selected ? radioClasses.labelSelected : ''
                                    } notarySelectLabel`}
                                    value={notary}
                                    control={
                                        <Radio
                                            className={`${
                                                selected ? radioClasses.labelSelected : radioClasses.label
                                            } notarySelectRadioButton`}
                                        />
                                    }
                                    label={notary}
                                    data-testid={'notary_radio_button'}
                                />
                            );
                        })}
                    </RadioGroup>
                </FormControl>
            </Paper>
        </div>
    );
};

export default SelectNotariesStep;
