import { CustomerId } from '@r3/cbdc-asset-frontend-core';
import apiCall from './apiCall';

/**
 * @remark
 * Used to issue a kyc state to a party on the network
 *
 * @param party name of the party
 *
 * @returns
 * AxiosResponse which resolves in success or error
 */
export const kycIssueTo = async (tokenIdentifier: string, party: string) =>
    apiCall({
        method: 'post',
        path: `/api/kyc/issueTo`,
        params: {
            tokenIdentifier: tokenIdentifier,
            party: party,
        },
    });

/**
 * @remark
 * Issue a kyc state as a response to a kyc request
 *
 * @param issuer name of the issuer (self)
 * @param authorisedParty name of the party (party issuing to)
 * @param id id
 *
 * @returns
 * AxiosResponse which resolves in success or error
 */
export const issueKycFromRequest = async (id: CustomerId) =>
    apiCall({
        method: 'post',
        path: `/api/kyc/issueFromRequest`,
        params: {
            id: id.id,
            externalid: id.externalId,
        },
    });

/**
 * @remark
 * Used to get all kyc states
 *
 * @returns
 * AxiosResponse which resolves in kyc object array or error
 */
export const requestAllKycStates = async (dontTrack?: boolean) =>
    apiCall({
        method: 'get',
        path: `/api/kyc`,
        dontTrackRequest: dontTrack,
    });

/**
 * @remark
 * Used to get all kyc requests
 *
 * @returns
 *  * AxiosResponse which resolves kyc request object array or error
 */
export const requestAllKycRequests = async (dontTrack?: boolean) =>
    apiCall({
        method: 'get',
        path: `/api/kyc/getRequests`,
        dontTrackRequest: dontTrack,
    });

/**
 * @remark
 * Used to revoke a kyc from a network participant
 *
 * @param id the id of the kyc request
 *
 * @returns
 * AxiosResponse which resolves success or error
 */
export const revokeKyc = async (id: CustomerId) =>
    apiCall({
        method: 'post',
        path: `/api/kyc/revoke`,
        params: { id: id },
    });
