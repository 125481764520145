import * as ROUTES from './Routes';

import { InfoModalPage } from '../components/infoModal/InfoModal';

const folderDir = '/embeddedDiagrams/';

export const DOCS_URL = 'https://docs-cbdc.r3.com/';

export const GetModalInfoData = (url: string): InfoModalPage[] => {
    let modalpages: InfoModalPage[] = [];
    let modalPair = INFO_MODAL_PAGES.find((x) => x.url === url);
    if (modalPair) {
        modalPair.pages.forEach((page) => {
            if (modalPair?.docsLink) {
                page.docsLink = modalPair?.docsLink;
            }
        });

        return modalPair.pages;
    }
    return modalpages;
};

type InfoModalPages = {
    url: string;
    pages: InfoModalPage[];
    docsLink: string;
};

//to add a video page: add a new object to the infoMOdalPages array
//headerText: the title of the video
//videoId: youtube video id
// { headerText: 'Request CBDC Video Overview', videoId: 'rokGy0huYEA' }

export const INFO_MODAL_PAGES: InfoModalPages[] = [
    {
        url: '/',
        pages: [
            { headerText: 'Sandbox Applications Video Introduction', videoId: 'aNTDRXJFBpE' },
            { headerText: 'Documentation Video Introduction', videoId: 'dVAVErI5yns' },
        ],
        docsLink: DOCS_URL,
    },
    {
        url: ROUTES.VISIBILITYREISSUANCE,
        pages: [
            { headerText: 'Visibility Video Overview', videoId: 'u8kujq_Ks_c' },
            { headerText: 'Reissuance Video Overview', videoId: 'lkbHXalH5Ds' },
            { headerText: 'Reissuance High Level', imageUrl: folderDir + 'reissuancehighlevel.png' },
            {
                headerText: 'Reissuance Corda Flow Sequence, step 1 - 2',
                imageUrl: folderDir + 'reissuancelowlevel1.png',
            },
            {
                headerText: 'Reissuance Corda Flow Sequence, step 3 - 4',
                imageUrl: folderDir + 'reissuancelowlevel2.png',
            },
        ],
        docsLink: DOCS_URL + 'multi-asset-transactions/reissuance/',
    },
    {
        url: ROUTES.DEFINECBDC,
        pages: [
            { headerText: 'Controls Video Overview', videoId: '8MIQE6Nv32U' },

            { headerText: 'Define CBDC Type High Level', imageUrl: folderDir + 'assetdefinitionhighlevel.png' },
            { headerText: 'Define CBDC Type Corda Flow Sequence', imageUrl: folderDir + 'assetdefinitionlowlevel.png' },
        ],
        docsLink: DOCS_URL + 'asset-lifecycle-operations/issuance/#defining-an-asset',
    },
    {
        url: ROUTES.ISSUECBDC,
        pages: [
            { headerText: 'Issue CBDC High Level', imageUrl: folderDir + 'issuecbdchighlevel.png' },
            { headerText: 'Issue CBDC Corda Flow Sequence', imageUrl: folderDir + 'issuecbdclowlevel.png' },
        ],
        docsLink: DOCS_URL + 'asset-lifecycle-operations/issuance/#issuing-defined-assets',
    },
    {
        url: ROUTES.CBDCREQUESTS,
        pages: [
            {
                headerText: 'Approve/Deny CBDC Issuance High Level',
                imageUrl: folderDir + 'approvedenycbdcissuancehighlevel.png',
            },
            {
                headerText: 'Approve CBDC Issuance Corda Flow Sequence',
                imageUrl: folderDir + 'approvecbdcissuancelowlevel.png',
            },
            {
                headerText: 'Deny CBDC Issuance Corda Flow Sequence',
                imageUrl: folderDir + 'denycbdcissuancelowlevel.png',
            },
        ],
        docsLink: DOCS_URL + 'asset-lifecycle-operations/issuance/#issuing-defined-assets',
    },
    {
        url: ROUTES.ISSUEKYC,
        pages: [
            {
                headerText: 'Issue Member Access High Level',
                imageUrl: folderDir + 'issuememberaccesshighlevel.png',
            },
            {
                headerText: 'Issue Member Access Corda Flow Sequence',
                imageUrl: folderDir + 'issuememberaccesslowlevel.png',
            },
        ],
        docsLink: DOCS_URL + 'controls/member-access-controls/#issuing-a-member-access-state',
    },
    {
        url: ROUTES.KYCREQUESTS,
        pages: [
            {
                headerText: 'Approve Member Access High Level',
                imageUrl: folderDir + 'approvememberaccesshighlevel.png',
            },
            {
                headerText: 'Approve Member Access Corda Flow Sequence',
                imageUrl: folderDir + 'approvememberaccesslowlevel.png',
            },
        ],
        docsLink: DOCS_URL + 'controls/member-access-controls/#issuing-a-member-access-state',
    },
    {
        url: ROUTES.REDEEMREQUESTS,
        pages: [
            {
                headerText: 'Approve/Deny Redemption High Level',
                imageUrl: folderDir + 'approvedenyredemptionhighlevel.png',
            },
            {
                headerText: 'Approve Redemption Corda Flow Sequence',
                imageUrl: folderDir + 'approveredemptionlowlevel.png',
            },
            {
                headerText: 'Deny Redemption Corda Flow Sequence',
                imageUrl: folderDir + 'denyredemptionlowlevel.png',
            },
        ],
        docsLink: DOCS_URL + 'asset-lifecycle-operations/redemption/#approving-a-bond-backed-redemption-request',
    },
    {
        url: ROUTES.LISTKYC,
        pages: [
            {
                headerText: 'Revoke Member Access High Level',
                imageUrl: folderDir + 'revokememberaccesshighlevel.png',
            },
            {
                headerText: 'Revoke Member Access Corda Flow Sequence',
                imageUrl: folderDir + 'revokememberaccesslowlevel.png',
            },
        ],
        docsLink: DOCS_URL + 'controls/member-access-controls/#revoking-a-member-access-state',
    },
];

// const infoModalPairs: InfoModalPair[] = [
//     // {
//     //     url: ROUTES.DEFINECBDC,
//     //     highLevelImgUrl: folderDir + 'assetdefinitionhighlevel.png',
//     //     lowLevelImgUrl: folderDir + 'assetdefinitionlowlevel.png',
//     //     docsLink: DOCUMENTATIONURL + 'asset-lifecycle-operations/issuance/#defining-an-asset',
//     // },
//     // {
//     //     url: ROUTES.ISSUECBDC,
//     //     highLevelImgUrl: folderDir + 'issuecbdchighlevel.png',
//     //     lowLevelImgUrl: folderDir + 'issuecbdclowlevel.png',
//     //     docsLink: DOCUMENTATIONURL + 'asset-lifecycle-operations/issuance/#issuing-defined-assets',
//     // },
//     // {
//     //     url: ROUTES.CBDCREQUESTS,
//     //     highLevelImgUrl: folderDir + 'approvedenycbdcissuancehighlevel.png',
//     //     lowLevelImgUrl: folderDir + 'approvecbdcissuancelowlevel.png',
//     //     lowLevelImgTwoUrl: folderDir + 'denycbdcissuancelowlevel.png',
//     //     docsLink: DOCUMENTATIONURL + 'asset-lifecycle-operations/issuance/#issuing-defined-assets',
//     // },
//     // {
//     //     url: ROUTES.ISSUEKYC,
//     //     highLevelImgUrl: folderDir + 'issuememberaccesshighlevel.png',
//     //     lowLevelImgUrl: folderDir + 'issuememberaccesslowlevel.png',
//     //     docsLink: DOCUMENTATIONURL + 'controls/member-access-controls/#issuing-a-member-access-state',
//     // },
//     // {
//     //     url: ROUTES.KYCREQUESTS,
//     //     highLevelImgUrl: folderDir + 'approvememberaccesshighlevel.png',
//     //     lowLevelImgUrl: folderDir + 'approvememberaccesslowlevel.png',
//     //     docsLink: DOCUMENTATIONURL + 'controls/member-access-controls/#issuing-a-member-access-state',
//     // },
//     // {
//     //     url: ROUTES.REDEEMREQUESTS,
//     //     highLevelImgUrl: folderDir + 'approvedenyredemptionhighlevel.png',
//     //     lowLevelImgUrl: folderDir + 'approveredemptionlowlevel.png',
//     //     lowLevelImgTwoUrl: folderDir + 'denyredemptionlowlevel.png',
//     //     docsLink:
//     //         DOCUMENTATIONURL + 'asset-lifecycle-operations/redemption/#approving-a-bond-backed-redemption-request',
//     // },
//     // {
//     //     url: ROUTES.LISTKYC,
//     //     highLevelImgUrl: folderDir + 'revokememberaccesshighlevel.png',
//     //     lowLevelImgUrl: folderDir + 'revokememberaccesslowlevel.png',
//     //     docsLink: DOCUMENTATIONURL + 'controls/member-access-controls/#revoking-a-member-access-state',
//     // },
// ];
