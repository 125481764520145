import { Divider, Paper, Typography } from '@material-ui/core';

import { CurrencyInput } from 'components/commonComponents/CurrencyInput/CurrencyInput';
import React from 'react';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useTranslation } from 'react-i18next';

type EnterAmountStepProps = {
    decimalPlaces: number;
    assetAmount: string;
    setAssetAmount: (val: string) => void;
};

const EnterAmountStep: React.FC<EnterAmountStepProps> = (props) => {
    const { decimalPlaces, assetAmount, setAssetAmount } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>{t('issueAsset.enterAmount')}</Typography>
                <Divider className={layoutClasses.summaryDivider} />
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {t('issueAsset.decimal')} {decimalPlaces}
                </Typography>
                <CurrencyInput
                    invalidInput={parseFloat(assetAmount) < 10 || assetAmount.length <= 0}
                    decimalPlaces={decimalPlaces}
                    setValue={setAssetAmount}
                    testId="amount-to-issue"
                    label={
                        parseFloat(assetAmount) < 10 || assetAmount.length <= 0
                            ? 'Value must be 10 or more'
                            : 'Asset Amount'
                    }
                />
            </Paper>
        </div>
    );
};

export default EnterAmountStep;
