import { FeaturePage } from '@r3/cbdc-asset-frontend-core';

export const FEATURE_PAGES: FeaturePage[] = [
    { feature: { featureName: 'distributedInterest', label: 'Distributed Interest', enabled: true } },
    {
        feature: { featureName: 'cbdcManager', label: 'CBDC Manager', enabled: true },
        subFeatures: [
            { featureName: 'viewAssets', label: 'View CBDCs', enabled: true },
            { featureName: 'defineCBDC', label: 'Define CBDC', enabled: true },
            { featureName: 'updateInterestRate', label: 'Update Interest Rates  ', enabled: true },
            { featureName: 'issueCBDC', label: 'Issue CBDC', enabled: true },
            { featureName: 'transferCBDC', label: 'Transfer CBDC', enabled: true },
        ],
    },
    {
        feature: { featureName: 'accessControls', label: 'Access Controls', enabled: true },
        subFeatures: [
            { featureName: 'viewMemberAccessStates', label: 'View/Revoke Member Access', enabled: true },
            { featureName: 'issueMemberAccess', label: 'Issue Member Access', enabled: true },
        ],
    },
    {
        feature: {
            featureName: 'requests',
            label: 'Requests',
            enabled: true,
        },
        subFeatures: [
            { featureName: 'issuanceRequests', label: 'Issuance Requests', enabled: true },
            { featureName: 'redemptionRequests', label: 'Redemption Requests', enabled: true },
            { featureName: 'memberAccessRequests', label: 'Member Requests', enabled: true },
        ],
    },
    { feature: { featureName: 'treasuryDashboard', label: 'Treasury Dashboard', enabled: true } },
    { feature: { featureName: 'txDashboard', label: 'TX Dashboard', enabled: true } },
    { feature: { featureName: 'referenceModels', label: 'Reference Models', enabled: true } },
    { feature: { featureName: 'visibilityAndReissuance', label: 'Visibility and Reissuance', enabled: true } },
];
