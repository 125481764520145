import axios from 'axios';

const prefix = window.location.href.includes('https') ? 'https://' : 'http://';
/**
 * This is the axios instance configuration (HTTP CLIENT)
 */
export const axiosInstance = axios.create({
    baseURL: `${prefix}${
        (window as any).REACT_APP_APIHOST !== undefined ? (window as any).REACT_APP_APIHOST!.trim() : ''
    }`,
    timeout: parseInt(
        (window as any).REACT_APP_HTTP_REQUEST_TIMEOUT !== undefined
            ? (window as any).REACT_APP_HTTP_REQUEST_TIMEOUT.trim()
            : '20000'
    ),
});
