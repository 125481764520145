import { Divider, Paper, Typography } from '@material-ui/core';
import { SummedAsset, useLayoutStyles } from '@r3/cbdc-asset-frontend-core';

import TransactionSummary from 'components/commonComponents/SummaryComponents/TranascationSummary';
import { useTranslation } from 'react-i18next';

type TransferSummaryStepProps = {
    assetAmount: string;
    selectedParticipant: string;
    selectedAsset: SummedAsset;
};

const TransferSummaryStep: React.FC<TransferSummaryStepProps> = (props) => {
    const { assetAmount, selectedParticipant, selectedAsset } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {t('transferAssets.summaryMessage')}
                </Typography>
                <Divider className={layoutClasses.summaryDivider} />
                <Typography className={`${layoutClasses.text}`} style={{ marginTop: 12, marginBottom: 12 }}>
                    {t('transferAssets.receivingParty') + ': '} {selectedParticipant}
                </Typography>
                <TransactionSummary
                    asset={selectedAsset}
                    toSpend={assetAmount.length === 0 ? 0 : parseFloat(assetAmount)}
                />
            </Paper>
        </div>
    );
};

export default TransferSummaryStep;
