import { ComplianceRuleData } from '@r3/cbdc-asset-frontend-core';
import apiCall from './apiCall';

/**
 * @remark
 * Used to define a new type of CBDC asset
 *
 * @param tokenIdentifier name of the asset
 * @param tokenDecimals how many decimal places will be present on the asset
 *
 * @returns
 * AxiosResponse which resolves in new asset definition object or error
 */
export const defineAsset = async (tokenName: string, tokenDecimals: number, complianceRules: ComplianceRuleData[]) =>
    apiCall({
        method: 'post',
        path: '/api/assetDefinitions/define',
        params: {
            tokenName: tokenName,
            tokenDecimals: tokenDecimals,
            complianceRules: complianceRules,
        },
    });

/**
 * @remark
 * Used to get all of the compliance rules that can be used to defining a new asset
 *
 *
 * @returns
 * Axios Response which resolves in a compliance rule object array or error
 */
export const requestAllComplianceRules = async () =>
    apiCall({
        method: 'get',
        path: '/api/assetDefinitions/complianceRules',
    });

/**
 * @remark
 * Used to get all participants on the network
 *
 * @returns
 * AxiosResponse which resolves in asset definition object array or error
 */
export const requestAllAssetDefinitions = async (dontTrack?: boolean) =>
    apiCall({
        method: 'get',
        path: '/api/assetDefinitions',
        dontTrackRequest: dontTrack,
    });

export const updateComplianceRule = (tokenIdentifier: string, complianceRule: ComplianceRuleData) =>
    apiCall({
        method: 'put',
        path: '/api/assetDefinitions/update',
        params: {
            tokenIdentifier: tokenIdentifier,
            complianceRule: complianceRule,
        },
    });
