import { CancelToken } from 'axios';
import apiCall from './apiCall';

/**
 * @remark
 * Used to get all CBDC issuance requests
 *
 * @returns
 * AxiosResponse which resolved in an error or repsonse data
 */
export const requestAllIssuanceRequests = async (dontTrack?: boolean, cancelToken?: CancelToken) =>
    apiCall({
        method: 'get',
        path: '/api/issuanceRequest',
        cancelToken: cancelToken,
        dontTrackRequest: dontTrack,
    });

/**
 * @remark
 * Used to approve a cbdc issuance request
 *
 * @param requestId
 * The id of the request
 *
 * @returns
 * Axios Response which resolves in new approved issuance request object or error
 */
export const approveCBDCIssuanceRequest = async (requestId: string) =>
    apiCall({
        method: 'post',
        path: `/api/issuanceRequest/${requestId}/approve`,
    });

/**
 * @remark
 * Used to deny a cbdc issuance request
 *
 * @param requestId
 * The id of the request
 *
 * @returns
 * Axios Response which resolves in new denied issuance request object or error
 */
export const denyCBDCIssuanceRequest = async (requestId: string) =>
    apiCall({
        method: 'post',
        path: `/api/issuanceRequest/${requestId}/deny`,
    });
