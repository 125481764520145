import {
    ComplianceRuleKey,
    InterestRateApplicationState,
    InterestRateState,
    NumberFormat,
    useLayoutStyles,
} from '@r3/cbdc-asset-frontend-core';
import { Divider, Paper, Typography } from '@material-ui/core';

import { AddedRulePair } from './AddedRulePair';
import { useTranslation } from 'react-i18next';

type AssetSummaryStepProps = {
    assetName: string;
    decimalPlaces: number;
    addedRules: AddedRulePair[];
    isMemberAccessRequired: boolean;
    expiryDays: string;
    expiryHours: string;
    expiryMinutes: string;
    selectedNotary: string;
    interestRateState: InterestRateState | undefined;
};

const AssetSummaryStep: React.FC<AssetSummaryStepProps> = (props) => {
    const {
        assetName,
        decimalPlaces,
        addedRules,
        isMemberAccessRequired,
        expiryDays,
        expiryHours,
        expiryMinutes,
        selectedNotary,
        interestRateState,
    } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`} data-testid="summary-message">
                    {t('defineAsset.summaryMessage')}
                </Typography>

                <Divider className={layoutClasses.summaryDivider} />

                <Typography className={`${layoutClasses.text}`} data-testid="summary-message">
                    {`${t('defineAsset.assetName')}: `} {assetName}
                </Typography>

                <Typography className={`${layoutClasses.text}`} data-testid="summary-message">
                    {`${t('defineAsset.assetDecimal')}: `} {decimalPlaces}
                </Typography>

                <Divider className={layoutClasses.summaryDivider} />

                <Typography className={`${layoutClasses.sectionLabel}`} data-testid="summary-message">
                    {addedRules.length > 0 ||
                    isMemberAccessRequired ||
                    selectedNotary.length > 0 ||
                    interestRateState?.applicationState === InterestRateApplicationState.APPLIED
                        ? 'Compliance controls configured'
                        : 'No compliance controls configured'}
                </Typography>

                <Divider className={layoutClasses.summaryDivider} style={{ width: 300 }} />
                <Typography className={`${layoutClasses.text}`} data-testid="summary-message">
                    {t('defineAsset.notary') + ': ' + selectedNotary}
                </Typography>
                {interestRateState?.applicationState === InterestRateApplicationState.APPLIED && (
                    <>
                        <Divider className={layoutClasses.summaryDivider} style={{ width: 300 }} />
                        <Typography className={`${layoutClasses.text}`} data-testid="summary-message">
                            {t('interestRates.interestValue') +
                                ': ' +
                                interestRateState.values[0].yearlyInterest +
                                ' %'}
                        </Typography>
                        <Typography className={`${layoutClasses.text}`} data-testid="summary-message">
                            {t('interestRates.effectiveStartDate') +
                                ': ' +
                                new Date(interestRateState.values[0].effectiveDate).toLocaleString('en-GB')}
                        </Typography>
                    </>
                )}

                {isMemberAccessRequired && (
                    <Typography className={`${layoutClasses.text}`} data-testid="summary-message">
                        Member Access State is required to hold this CBDC
                    </Typography>
                )}
                {addedRules.map((rule, index) => {
                    return (
                        <Typography className={`${layoutClasses.text}`} key={index} data-testid="summary-message">
                            {rule.rule.name}:{' '}
                            {rule.rule.key === ComplianceRuleKey.Expiry
                                ? `${expiryDays}d : ${expiryHours}h : ${expiryMinutes}m`
                                : NumberFormat.addThousandSeparators(rule.value)}
                        </Typography>
                    );
                })}
            </Paper>
        </div>
    );
};

export default AssetSummaryStep;
