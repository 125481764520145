import {
    ComplianceRule,
    ComplianceRuleGroup,
    ComplianceRuleKey,
    InterestRateApplicationState,
    InterestRateState,
    StepperFormStep,
    useFeatureFlagContext,
    useInteractiveChecklistContext,
} from '@r3/cbdc-asset-frontend-core';
import React, { useCallback, useEffect, useState } from 'react';
import { defineAsset, requestAllComplianceRules } from 'api/assetDefinitionApi';

import { AddedRulePair } from './AddedRulePair';
import AssetBasicDetailsStep from './AssetBasicDetails';
import AssetRulesStep from './AssetRulesStep';
import AssetSummaryStep from './AssetSummary';
import { Fade } from '@material-ui/core';
import InterestRateStep from './interestRate/InterestRateStep';
import MemberAccessRuleStep from './MemberAccessRuleStep';
import { ResolvedPromise } from 'api/resolvePromise';
import SelectNotariesStep from './SelectNotariesStep';
import { StepperForm } from '@r3/cbdc-asset-frontend-core';
import TransactionRulesStep from './TransactionRulesStep';
import { insertIntoArrayIf } from 'utils/insertIntoArrayIf';
import { useGetAllNotaries } from 'hooks/GetAllNotaries';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import useGetOurOwnIdentity from 'hooks/GetOurOwnIdentity';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const CreateAsset: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [assetName, setAssetName] = useState<string>('');
    const [decimalPlaces, setDecimalPlaces] = useState<number>(0);
    const { t } = useTranslation();
    const { assets, getAllAssets } = useGetAssetDefinitions();
    const [complianceRuleGroups, setComplianceRuleGroups] = useState<ComplianceRuleGroup[]>([]);
    const [interestRateState, setInterestState] = useState<InterestRateState | undefined>(undefined);
    const [totalRules, setTotalRules] = useState<number>(0);
    const [addedRules, setAddedRules] = useState<AddedRulePair[]>([]);
    const [isMemberAccessRequired, setIsMemberAccessRequired] = useState<boolean>(true);
    const [expiryDays, setExpiryDays] = useState<string>('0');
    const [expiryHours, setExpiryHours] = useState<string>('0');
    const [expiryMinutes, setExpiryMinutes] = useState<string>('30');
    const checkListContext = useInteractiveChecklistContext();
    const location = useLocation();
    const [selectedNotary, setSelectedNotary] = useState<string>('');
    const { notaries, getAllNotaries } = useGetAllNotaries();
    const { isFeatureEnabled } = useFeatureFlagContext();

    const handleReset = () => {
        setAssetName('');
        setDecimalPlaces(0);
        setAddedRules([]);
        getAllAssets();
        setSelectedNotary('');
        setInterestState(undefined);
    };

    const getComplianceRules = useCallback(async () => {
        const complianceRulesResponse: ResolvedPromise = await requestAllComplianceRules();
        if (complianceRulesResponse.error) {
            enqueueSnackbar(t('Could not get compliance controls: ' + complianceRulesResponse.error), {
                variant: 'error',
            });
        } else {
            let complianceRuleGroups = complianceRulesResponse.data as ComplianceRuleGroup[];
            setTotalRules(complianceRuleGroups[0].rules.length + complianceRuleGroups[1].rules.length);
            setComplianceRuleGroups(complianceRuleGroups);
            setAddedRules([]);
        }
    }, [enqueueSnackbar, t]);

    const addComplianceRule = (rule: ComplianceRule) => {
        setAddedRules((rules) =>
            rules.concat([{ rule: rule, value: rule.key === ComplianceRuleKey.Expiry ? '00:30' : '0' }])
        );
    };

    const removeComplianceRule = (index: number) => {
        let addedRulesCopy = [...addedRules];
        addedRulesCopy.splice(index, 1);
        setAddedRules(addedRulesCopy);
    };

    const updateRuleValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        let addedRulesCopy = [...addedRules];
        let toUpdate = addedRulesCopy[index];
        if (addedRules[index].rule.key === ComplianceRuleKey.Expiry) {
            toUpdate.value = event.target.value;
        } else {
            let value: string;
            if (event.target.value.length <= 0) {
                value = '0';
            } else {
                value = event.target.value
                    .replace(/,/g, '')
                    .replace(/[^0-9]/g, '')
                    .replace(/^0+/, '');
            }

            toUpdate.value = value;
        }
        setAddedRules(addedRulesCopy);
    };

    const saveAsset = async () => {
        addedRules.forEach((rule) => {
            if (rule.rule.key === ComplianceRuleKey.Expiry) {
                rule.value = `P${expiryDays}DT${expiryHours}H${expiryMinutes}M`;
            }
        });
        if (isMemberAccessRequired) {
            addedRules.push({ rule: complianceRuleGroups[2].rules[0], value: 'true' });
        }
        //Add selected Notary
        addedRules.push({ rule: complianceRuleGroups[0].rules[2], value: selectedNotary });

        //Add interest rate rule
        if (interestRateState?.applicationState === InterestRateApplicationState.APPLIED) {
            addedRules.push({
                rule: complianceRuleGroups[0].rules[3],
                value: JSON.stringify(interestRateState.values),
            });
        }

        const defineAssetResponse: ResolvedPromise = await defineAsset(
            assetName,
            decimalPlaces,
            addedRules.map((x) => {
                return { key: x.rule.key, value: x.value };
            })
        );

        if (defineAssetResponse.error) {
            enqueueSnackbar(t('error.defineAsset', { error: defineAssetResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.defineAsset'), {
                variant: 'success',
            });
            checkListContext?.completeStep(location.pathname);
        }

        handleReset();
    };

    useEffect(() => {
        getAllAssets(true);
        getComplianceRules();
        getAllNotaries();
    }, [getAllAssets, getComplianceRules, getAllNotaries]);

    const expiryTimeValid = (): boolean => {
        if (parseFloat(expiryDays) > 0) {
            return true;
        }

        if (expiryMinutes.length > 0 && expiryHours.length > 0) {
            if (parseFloat(expiryHours) > 0) {
                return true;
            }

            if (parseFloat(expiryMinutes) >= 30) {
                return true;
            }
            if (parseFloat(expiryHours) <= 0 && parseFloat(expiryMinutes) < 30) {
                return false;
            }
        }
        return false;
    };

    const validName = (name: string) => {
        for (let asset of assets) {
            if (asset.tokenName === name) {
                return false;
            }
        }
        return true;
    };

    const steps: StepperFormStep[] = [
        {
            title: t('defineAsset.assetNameAndDecimal'),
            component: (
                <AssetBasicDetailsStep
                    decimalPlaces={decimalPlaces}
                    setDecimalPlaces={setDecimalPlaces}
                    assetName={assetName}
                    setAssetName={setAssetName}
                    validName={validName}
                />
            ),
            isNextStepDisabled: () => {
                if (assetName.length > 0 && validName(assetName)) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('defineAsset.selectNotaries'),
            component: (
                <SelectNotariesStep
                    notaries={notaries}
                    setSelectedNotaries={setSelectedNotary}
                    selectedNotary={selectedNotary}
                />
            ),
            isNextStepDisabled: () => {
                if (selectedNotary.length > 0) {
                    return false;
                }

                return true;
            },
        },

        {
            title: t('defineAsset.memberAccessRule'),
            component: (
                <MemberAccessRuleStep
                    isMemberAccessRequired={isMemberAccessRequired}
                    setIsMemberAccessRequired={setIsMemberAccessRequired}
                    complianceRuleGroup={complianceRuleGroups[2]}
                />
            ),
            isNextStepDisabled: () => {
                return false;
            },
        },
        ...insertIntoArrayIf(isFeatureEnabled('distributedInterest'), {
            title: t('interestRates.interestRateStep'),
            component: <InterestRateStep interestRateState={interestRateState} setInterestState={setInterestState} />,
            isNextStepDisabled: () => {
                if (interestRateState === undefined) {
                    return false;
                }
                if (interestRateState.applicationState === InterestRateApplicationState.NOT_APPLIED) {
                    return false;
                }
                if (interestRateState.applicationState === InterestRateApplicationState.NOT_SELECTED) {
                    return true;
                }
                const interestRateValue = interestRateState.values[0].yearlyInterest;
                if (interestRateValue === 0) {
                    return true;
                }

                return false;
            },
        }),
        {
            title: t('defineAsset.setTransactionRules'),
            component: (
                <TransactionRulesStep
                    complianceRuleGroup={complianceRuleGroups[0]}
                    addedRules={addedRules}
                    removeComplianceRule={removeComplianceRule}
                    updateRuleValue={updateRuleValue}
                    totalRules={totalRules}
                    addComplianceRule={addComplianceRule}
                />
            ),
            isNextStepDisabled: () => {
                for (const rule of addedRules) {
                    if (complianceRuleGroups[0].rules.findIndex((r) => r.key === rule.rule.key) === -1) {
                        continue;
                    }
                    if (parseFloat(rule.value) <= 0 || rule.value.length <= 0) {
                        return true;
                    }
                }
                return false;
            },
        },
        {
            title: t('defineAsset.setAssetRules'),
            component: (
                <AssetRulesStep
                    complianceRuleGroup={complianceRuleGroups[1]}
                    addedRules={addedRules}
                    removeComplianceRule={removeComplianceRule}
                    updateRuleValue={updateRuleValue}
                    totalRules={totalRules}
                    addComplianceRule={addComplianceRule}
                    expiryTimeValid={expiryTimeValid}
                    setExpiryDays={setExpiryDays}
                    setExpiryHours={setExpiryHours}
                    setExpiryMinutes={setExpiryMinutes}
                    expiryDays={expiryDays}
                    expiryHours={expiryHours}
                    expiryMinutes={expiryMinutes}
                />
            ),
            isNextStepDisabled: () => {
                for (const rule of addedRules) {
                    if (rule.rule.key === ComplianceRuleKey.Expiry) {
                        if (!expiryTimeValid() || rule.value.length <= 0) {
                            return true;
                        }
                    } else if (parseFloat(rule.value) <= 0 || rule.value.length <= 0) {
                        return true;
                    }
                }
                return false;
            },
        },
        {
            title: t('defineAsset.confirmCreation'),
            component: (
                <AssetSummaryStep
                    assetName={assetName}
                    decimalPlaces={decimalPlaces}
                    addedRules={addedRules}
                    expiryDays={expiryDays}
                    expiryHours={expiryHours}
                    expiryMinutes={expiryMinutes}
                    isMemberAccessRequired={isMemberAccessRequired}
                    selectedNotary={selectedNotary}
                    interestRateState={interestRateState}
                />
            ),
            isNextStepDisabled: () => {
                return false;
            },
        },
    ];

    return (
        <>
            <Fade in={true}>
                <StepperForm
                    submit={saveAsset}
                    steps={steps}
                    submitButtonText={t('commonText.submit')}
                    handleReset={handleReset}
                />
            </Fade>
        </>
    );
};
