import { PointTooltipProps } from '@nivo/line';
import { NumberFormat, useDashboardStyles } from '@r3/cbdc-asset-frontend-core';
import { FunctionComponent } from 'react';
import { LINE_IDS } from './moneySupplyGraphDetails';

const getDateLabel = (serieId: string | number) => {
    switch (serieId) {
        case LINE_IDS.IssuedBaseWithExpectedInterest:
        case LINE_IDS.IssuedBaseWithVerifiedInterest:
            return 'Apply Time';
        default:
            return 'Update Time';
    }
};

const getValueLabel = (serieId: string | number) => {
    switch (serieId) {
        case LINE_IDS.IssuedBaseWithExpectedInterest:
            return 'Issued Base with Expected Interest';
        case LINE_IDS.IssuedBaseWithVerifiedInterest:
            return 'Issued Base with Verified Interest';
        default:
            return 'Issued Base';
    }
};

const MoneySupplyLineTooltip: FunctionComponent<PointTooltipProps> = ({ point }) => {
    const classes = useDashboardStyles();
    const serieId = point.serieId;

    return (
        <div className={classes.pieTooltip} style={{ display: 'flex', flexDirection: 'column', marginTop: 120 }}>
            <div>
                {getValueLabel(serieId)}: {NumberFormat.addThousandSeparators(point.data.y.toString())}
            </div>{' '}
            <div>
                {getDateLabel(serieId)}: {point.data.x.toLocaleString()}
            </div>
        </div>
    );
};

export default MoneySupplyLineTooltip;
