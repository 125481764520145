import { Button } from '@material-ui/core';
import { PartialBy } from '@r3/cbdc-asset-frontend-core';
import React from 'react';
import { ReferenceArchitectureQuestions } from '../../constants/ReferenceArchitectures';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useRefArchStyles } from '../../materialStyles/refArchStyles';

type RefArchQuestionOption<T extends keyof ReferenceArchitectureQuestions> = {
    optionText: string;
    option: PartialBy<ReferenceArchitectureQuestions, T>;
    disabled?: boolean;
};

type RefArchQuestionProps<T extends keyof ReferenceArchitectureQuestions> = {
    text: string;
    optionOne: RefArchQuestionOption<T>;
    optionTwo: RefArchQuestionOption<T>;
    refArchQuestions: ReferenceArchitectureQuestions;
    updateQuestions: (kv: PartialBy<ReferenceArchitectureQuestions, T>) => void;
};

const RefArchQuestion = <T extends keyof ReferenceArchitectureQuestions>(props: RefArchQuestionProps<T>) => {
    const { text, optionOne, optionTwo, updateQuestions, refArchQuestions } = props;
    const classes = useRefArchStyles();
    const layoutClasses = useLayoutStyles();
    return (
        <div className={`${classes.question}`} data-testid={'question'}>
            <div className={`${classes.questionText}`}>{text}</div>
            <div className={`${layoutClasses.buttonGroup}`}>
                <Button
                    className={`${layoutClasses.button} ${layoutClasses.buttonAutoSize}  ${
                        refArchQuestions[Object.keys(optionOne.option)[0]] === Object.values(optionOne.option)[0]
                            ? layoutClasses.buttonSelected
                            : ''
                    } ${classes.answerButton}`}
                    variant="outlined"
                    color="primary"
                    disabled={optionOne.disabled}
                    onClick={() => {
                        updateQuestions(optionOne.option);
                    }}
                >
                    {optionOne.optionText}
                </Button>
                <Button
                    className={`${layoutClasses.button} ${layoutClasses.buttonAutoSize}  ${
                        refArchQuestions[Object.keys(optionTwo.option)[0]] === Object.values(optionTwo.option)[0]
                            ? layoutClasses.buttonSelected
                            : ''
                    }  ${classes.answerButton}`}
                    variant="outlined"
                    color="primary"
                    disabled={optionTwo.disabled}
                    onClick={() => {
                        updateQuestions(optionTwo.option);
                    }}
                >
                    {optionTwo.optionText}
                </Button>
            </div>
        </div>
    );
};

export default RefArchQuestion;
