export const YoutubeEmbedded = ({ embedId }) => (
    <div
        className="video-responsive"
        style={{ overflow: 'hidden', marginBottom: 32, marginTop: 32, height: '100%', width: '100%' }}
    >
        <iframe
            style={{ left: 0, top: 0, height: '100%', width: '100%' }}
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    </div>
);
