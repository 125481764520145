import {
    Avatar,
    Dialog,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Slide,
    Typography,
} from '@material-ui/core';
import { ComplianceRule, ComplianceRuleGroup, ComplianceRuleKey } from '@r3/cbdc-asset-frontend-core';

import AddIcon from '@material-ui/icons/Add';
import { AddedRulePair } from './AddedRulePair';
import React from 'react';
import RemoveIcon from '@material-ui/icons/Remove';
import { TransitionProps } from '@material-ui/core/transitions';
import { useDialogStyles } from '@r3/cbdc-asset-frontend-core';

export const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type ComplianceRulesDialogProps = {
    setAddRulesDialogOpen: (open: boolean) => void;
    open: boolean;
    rules: ComplianceRuleGroup;
    addedRules: AddedRulePair[];
    addComplianceRule: (rule: ComplianceRule) => void;
};

export const ComplianceRulesDialog: React.FC<ComplianceRulesDialogProps> = (props) => {
    const { setAddRulesDialogOpen, open, addedRules, rules, addComplianceRule } = props;
    const dialogStyles = useDialogStyles();

    return (
        <Dialog
            onClose={() => {
                setAddRulesDialogOpen(false);
            }}
            aria-labelledby="simple-dialog-title"
            open={open}
            TransitionComponent={Transition}
            classes={{ paper: dialogStyles.dialogue }}
            data-testid={'dialog_options'}
        >
            <DialogTitle
                style={{ marginTop: 16, marginBottom: 2, marginLeft: 'auto', marginRight: 'auto' }}
                id="simple-dialog-title"
            >
                Select Rules to Add
            </DialogTitle>

            {/* Temp fix to filter out INTEREST_RATE compliance rule from the list of transaction controls 
                We need to refactor this later to use the ComplianceRuleKey enum*/}
            <List>
                {rules.rules
                    .filter(
                        (rule) =>
                            rule.key !== ComplianceRuleKey.PermittedNotaries &&
                            (rule.key as string) !== ComplianceRuleKey.InterestRate
                    )
                    .map((rule) => {
                        if (addedRules.find((x) => x.rule.name === rule.name)) {
                            return <div key={rule.name}></div>;
                        }
                        return (
                            <ListItem
                                id={`RuleOption${rule.name.replace(/\s/g, '')}`}
                                className={`${dialogStyles.listItem} ruleOption`}
                                autoFocus
                                button
                                onClick={() => {
                                    addComplianceRule(rule);
                                }}
                                data-testid={'rule_option'}
                                key={rule.name}
                            >
                                <ListItemAvatar>
                                    <Avatar className={dialogStyles.avatar}>
                                        <AddIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <>
                                            <Typography style={{ marginLeft: 16 }} variant="h5">
                                                {rule.name}
                                            </Typography>
                                            <Divider
                                                className={dialogStyles.divider}
                                                style={{ marginTop: 6, marginBottom: 6 }}
                                            />
                                        </>
                                    }
                                    secondary={
                                        <Typography style={{ marginLeft: 16 }} variant="body2">
                                            {rule.description}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        );
                    })}

                <Divider className={dialogStyles.divider} style={{ marginTop: 30, marginBottom: 10 }} />
                <ListItem
                    id="CloseRulesDialog"
                    data-testid={'rule_option_close'}
                    className={dialogStyles.listItem}
                    autoFocus
                    button
                    onClick={() => {
                        setAddRulesDialogOpen(false);
                    }}
                    key={'close'}
                >
                    <ListItemAvatar>
                        <Avatar className={dialogStyles.avatar}>
                            <RemoveIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography style={{ marginLeft: 16 }} variant="h5">
                                Close
                            </Typography>
                        }
                    />
                </ListItem>
            </List>
        </Dialog>
    );
};
