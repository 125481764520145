import { Divider, Paper, Switch, Typography } from '@material-ui/core';

import { ComplianceRuleGroup } from '@r3/cbdc-asset-frontend-core';
import React from 'react';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useTranslation } from 'react-i18next';

type MemberAccessRuleStepProps = {
    isMemberAccessRequired: boolean;
    setIsMemberAccessRequired: (bool: boolean) => void;
    complianceRuleGroup: ComplianceRuleGroup;
};

const MemberAccessRuleStep: React.FC<MemberAccessRuleStepProps> = (props) => {
    const { isMemberAccessRequired, setIsMemberAccessRequired, complianceRuleGroup } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {t('defineAsset.configureMemberAccess')}
                </Typography>

                <Typography className={`${layoutClasses.text}`} style={{ width: '85%', textAlign: 'center' }}>
                    {complianceRuleGroup.rules[0].description}
                </Typography>
                <Divider className={layoutClasses.summaryDivider} />
                <Typography className={`${layoutClasses.text}`} style={{ marginBottom: 16 }}>
                    {t('defineAsset.memberAccessOnByDefault')}
                </Typography>

                {isMemberAccessRequired ? (
                    <Typography className={`${layoutClasses.text}`} style={{ marginBottom: 6 }}>
                        {t('defineAsset.memberAccessRequired')}
                    </Typography>
                ) : (
                    <Typography className={`${layoutClasses.text}`} style={{ marginBottom: 6 }}>
                        {t('defineAsset.memberAccessNotRequired')}
                    </Typography>
                )}
                <Switch
                    checked={isMemberAccessRequired}
                    onChange={() => {
                        setIsMemberAccessRequired(!isMemberAccessRequired);
                    }}
                    name="checkedB"
                    color="secondary"
                    id="MemberAccessSwitch"
                    data-testid={'member-access-switch'}
                />
            </Paper>
        </div>
    );
};

export default MemberAccessRuleStep;
