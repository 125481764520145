import { Divider, Switch } from '@material-ui/core';

import { AssetResponse } from '@r3/cbdc-asset-frontend-core';
import React from 'react';
import { useSwitchFilterStyles } from '@r3/cbdc-asset-frontend-core';
import { useTranslation } from 'react-i18next';

type DashboardFilterProps = {
    assetSummary: AssetResponse[];
    uniqueAssets: string[];
    filterAssets: string[];
    updateFilter: (name: string) => void;
};

export const DashboardFilter: React.FC<DashboardFilterProps> = (props) => {
    const filterClasses = useSwitchFilterStyles();
    const { assetSummary, uniqueAssets, filterAssets, updateFilter } = props;
    const { t } = useTranslation();

    return (
        <div className={filterClasses.filter}>
            <div className={filterClasses.filterLabels}>
                <div className={filterClasses.filterlabelName}>{t('dashBoard.assetType')}</div>
                <div className={filterClasses.filterLabelSwitch}>{t('dashBoard.display')}</div>
            </div>
            <Divider className={filterClasses.filterDivider} />
            {assetSummary
                .filter((asset) => uniqueAssets.includes(asset.tokenDefinition.tokenName))
                .map((asset) => {
                    return (
                        <div key={asset.tokenDefinition.tokenIdentifier}>
                            <div className={filterClasses.filterItem}>
                                <div className={filterClasses.filterName}>{asset.tokenDefinition.tokenName}</div>
                                <div className={filterClasses.filterSwitch}>
                                    <Switch
                                        checked={filterAssets.includes(asset.tokenDefinition.tokenName)}
                                        onChange={() => updateFilter(asset.tokenDefinition.tokenName)}
                                        name="checkedB"
                                        color="secondary"
                                    />
                                </div>
                            </div>
                            <Divider className={filterClasses.filterDivider} />
                        </div>
                    );
                })}
        </div>
    );
};
