import 'react-datepicker/dist/react-datepicker.css';

import { Divider, FormControl, Paper, RadioGroup, Typography } from '@material-ui/core';
import { InterestRateApplicationState, InterestRateState, useLayoutStyles } from '@r3/cbdc-asset-frontend-core';

import InterestRateRadioItem from './InterestRateRadioItem';
import InterestRateSetValues from './InterestRateSetValues';
import enGB from 'date-fns/locale/en-GB';
import { registerLocale } from 'react-datepicker';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LOCALE = 'enGb';

registerLocale(LOCALE, enGB);

type Props = {
    interestRateState: InterestRateState | undefined;
    setInterestState: (interestRateState: InterestRateState | undefined) => void;
};

const InterestRateStep: React.FC<Props> = ({ setInterestState, interestRateState }) => {
    const layoutClasses = useLayoutStyles();
    const { t: text } = useTranslation();
    const [interestRateApplyState, setInterestRateApplyState] = useState<InterestRateApplicationState>(
        interestRateState?.applicationState ?? InterestRateApplicationState.NOT_APPLIED
    );
    const handleInterestRateApplyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInterestRateApplyState(event.target.value as InterestRateApplicationState);
        if (interestRateState) {
            setInterestState({
                ...interestRateState,
                applicationState: event.target.value as InterestRateApplicationState,
            });
        }
    };
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {text('interestRates.interestRateSelect')}
                </Typography>
                <Typography className={`${layoutClasses.text}`} style={{ fontSize: 16 }}>
                    {text('interestRates.interestRateExplanation')}
                </Typography>
                <Typography className={`${layoutClasses.text}`} style={{ fontSize: 16 }}>
                    {text('interestRates.interestRateExplanationTwo')}
                </Typography>

                <Divider className={layoutClasses.summaryDivider} />
                <FormControl component="fieldset">
                    <RadioGroup
                        name="interestRate"
                        value={interestRateApplyState}
                        onChange={handleInterestRateApplyChange}
                    >
                        <InterestRateRadioItem
                            state={InterestRateApplicationState.APPLIED}
                            selected={interestRateApplyState === InterestRateApplicationState.APPLIED}
                            labelText={text('interestRates.interestBearing')}
                        />
                        <InterestRateRadioItem
                            state={InterestRateApplicationState.NOT_APPLIED}
                            selected={interestRateApplyState === InterestRateApplicationState.NOT_APPLIED}
                            labelText={text('interestRates.notInterestBearing')}
                        />
                    </RadioGroup>
                </FormControl>
                {interestRateApplyState === InterestRateApplicationState.APPLIED && (
                    <InterestRateSetValues
                        interestRateState={interestRateState}
                        setInterestState={setInterestState}
                        interestRateApplyState={interestRateApplyState}
                    />
                )}
            </Paper>
        </div>
    );
};

export default InterestRateStep;
