import { ASSETDASH, BONDSDASH, MONEYSUPPLY, UPDATEINTERESTRATE } from '../../constants/Routes';
import { FeatureWrapper, Header, useFeatureFlagContext } from '@r3/cbdc-asset-frontend-core';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { Paper, Tab, Tabs } from '@material-ui/core';

import { AssetDashboard } from './AssetDashboard/AssetDashboard';
import { BondsDashboard } from './BondsDashboard/BondsDashboard';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { MoneySupply } from './MoneySupply/MoneySupply';
import React from 'react';
import { RedirectWithTabIndex } from '@r3/cbdc-asset-frontend-core';
import TimelineIcon from '@material-ui/icons/Timeline';
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';
import UpdateIcon from '@material-ui/icons/Update';
import UpdateInterestRate from './UpdateInterestRate/UpdateInterestRate';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import { useTabStyles } from '@r3/cbdc-asset-frontend-core';
import { useTranslation } from 'react-i18next';

export const TreasuryDashboard: React.FC = () => {
    const tabClasses = useTabStyles();
    const { t } = useTranslation();
    const location = useLocation<RedirectWithTabIndex>();
    const { isFeatureEnabled } = useFeatureFlagContext();
    const tabs = [
        <Tab
            className={`${tabClasses.tab} tab`}
            classes={{ wrapper: tabClasses.tabText }}
            label={t('pageTitle.bondsDashboard')}
            icon={<WebAssetIcon />}
            value={BONDSDASH}
            component={Link}
            to={BONDSDASH}
        />,
        <Tab
            className={`${tabClasses.tab} tab`}
            classes={{ wrapper: tabClasses.tabText }}
            label={t('pageTitle.assetDashboard')}
            icon={<MonetizationOnIcon />}
            value={ASSETDASH}
            component={Link}
            to={ASSETDASH}
        />,
        <Tab
            className={`${tabClasses.tab} tab`}
            classes={{ wrapper: tabClasses.tabText }}
            label={t('pageTitle.moneySupply')}
            icon={<TimelineIcon />}
            value={MONEYSUPPLY}
            component={Link}
            to={MONEYSUPPLY}
        />,
    ];
    if (isFeatureEnabled('distributedInterest')) {
        tabs.splice(
            2,
            0,
            <Tab
                className={`${tabClasses.tab} tab`}
                classes={{ wrapper: tabClasses.tabText }}
                label={t('pageTitle.interestRateConfig')}
                icon={
                    <div style={{ display: 'flex' }}>
                        <UpdateIcon style={{ width: 15, height: 15 }} /> <TrendingUpRoundedIcon />
                    </div>
                }
                value={UPDATEINTERESTRATE}
                component={Link}
                to={UPDATEINTERESTRATE}
            />
        );
    }

    return (
        <>
            <Header>{t('pageTitle.treasuryDashboard')}</Header>
            <Paper elevation={2} className={`${tabClasses.wrapper} `}>
                <Tabs
                    className={`${tabClasses.root} tabs`}
                    classes={{ flexContainer: tabClasses.tabContainer }}
                    value={location.pathname}
                >
                    {tabs.map((tab) => tab)}
                </Tabs>
                <Switch>
                    <Route path={ASSETDASH} component={AssetDashboard} />
                    <Route path={MONEYSUPPLY} component={MoneySupply} />
                    <Route path={BONDSDASH} component={BondsDashboard} />
                    <FeatureWrapper featureName="distributedInterest">
                        <Route path={UPDATEINTERESTRATE} component={UpdateInterestRate} />
                    </FeatureWrapper>
                </Switch>
            </Paper>
        </>
    );
};
