import { Asset, InterestLog, LineData, TimePoint } from '@r3/cbdc-asset-frontend-core';
import { AssetList, AssetWithTotal } from './AssetList';
import { DASHBOARDANIMATIONENTERMS, DASHBOARDANIMATIONEXITMS } from 'constants/Animations';
import { Divider, IconButton, Slide } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useMoneySupplyData, { parseDate } from './useMoneySupplyData';

import { BrushComponent } from './BrushComponent';
import DashboardResponsiveLine from '../DashboardComponents/DashboardResponsiveLine';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { LINE_IDS } from './moneySupplyGraphDetails';
import MoneySupplyLineTooltip from './MoneySupplyLineTooltip';
import { getMoneySupplyData } from 'api/financialReportApi';
import { useDashboardStyles } from '@r3/cbdc-asset-frontend-core';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';

export const MoneySupply: React.FC = () => {
    const { assetData, interestData, fetchMoneySupply } = useMoneySupplyData();
    const [graphData, setGraphData] = useState<LineData[]>([]);
    const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>(undefined);
    const { assets, getAllAssets } = useGetAssetDefinitions();
    const [assetsWithTotal, setAssetsWithTotal] = useState<AssetWithTotal[]>([]);
    const classes = useDashboardStyles();
    const layoutClasses = useLayoutStyles();
    const [nDataPoints, setNDataPoints] = useState<number>(50);

    const getAssetsWithTotals = useCallback(async () => {
        let newAssetsWithTotal: AssetWithTotal[] = [];
        for await (let asset of assets) {
            const response = await getMoneySupplyData(asset.tokenIdentifier, 1);
            if (response.data == null) {
                return;
            }
            parseDate(response.data.data, 'x');
            if (response.data.data.length <= 0) {
                newAssetsWithTotal.push({ asset: asset, total: 0 });
            } else {
                newAssetsWithTotal.push({ asset: asset, total: response.data.data[0].y });
            }
        }
        newAssetsWithTotal.sort((a, b) => b.total - a.total);
        setAssetsWithTotal(newAssetsWithTotal);
    }, [assets]);

    const getGraphData = useCallback((assets: TimePoint[], interestData: InterestLog[]): LineData[] => {
        let result: LineData[] = [{ id: LINE_IDS.IssuedBase, data: assets }];
        if (interestData.length > 0) {
            result.push({
                id: LINE_IDS.IssuedBaseWithVerifiedInterest,
                data: interestData.map((d) => ({
                    x: d.effectiveStartDate,
                    y: d.principleAmount + d.interestRateApplied,
                })),
            });
        }
        return result;
    }, []);

    const brushComponentData: LineData[] = useMemo(
        () => getGraphData(assetData, interestData),
        [assetData, interestData, getGraphData]
    );

    useEffect(() => {
        getAllAssets(true);
    }, [getAllAssets]);

    useEffect(() => {
        if (selectedAsset != null) {
            fetchMoneySupply(selectedAsset, nDataPoints);
        }
    }, [selectedAsset, nDataPoints, fetchMoneySupply]);

    useEffect(() => {
        getAssetsWithTotals();
    }, [assets, getAssetsWithTotals]);

    useEffect(() => {
        setGraphData(getGraphData(assetData, interestData));
    }, [assetData, interestData, getGraphData]);

    const handleBrush = (startDate: Date, endDate: Date) => {
        const filteredAssets = assetData.filter((a) => a.x >= startDate && a.x <= endDate);
        const filteredInterestData = interestData.filter(
            (a) => a.effectiveStartDate >= startDate && a.effectiveStartDate <= endDate
        );
        setGraphData(getGraphData(filteredAssets, filteredInterestData));
    };

    return (
        <>
            {assets.length > 0 && (
                <div className={layoutClasses.componentWrapper}>
                    <Slide
                        direction="right"
                        in={true}
                        timeout={{ enter: DASHBOARDANIMATIONENTERMS, exit: DASHBOARDANIMATIONEXITMS }}
                    >
                        <div
                            className={`${layoutClasses.column} ${layoutClasses.columnSmall}`}
                            style={{ height: 900, minWidth: 300 }}
                        >
                            <AssetList
                                assetsWithTotal={assetsWithTotal}
                                setSelectedAsset={setSelectedAsset}
                                selectedAsset={selectedAsset}
                            />
                        </div>
                    </Slide>

                    <Slide
                        direction="left"
                        in={true}
                        timeout={{ enter: DASHBOARDANIMATIONENTERMS, exit: DASHBOARDANIMATIONEXITMS }}
                    >
                        <div
                            style={{
                                overflow: 'visible',
                                paddingTop: 12,
                                height: 900,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            className={`${layoutClasses.column} ${layoutClasses.columnFill} ${layoutClasses.columnNoPadding} `}
                        >
                            <div>
                                {assetData.length <= 0 ? (
                                    <div style={{ color: 'white', fontSize: 22 }}>
                                        {' '}
                                        No supply over time data for: {selectedAsset?.tokenName}
                                    </div>
                                ) : (
                                    <div style={{ color: 'white', fontSize: 22 }}>
                                        Supply over time for: {selectedAsset?.tokenName}
                                    </div>
                                )}{' '}
                                <div
                                    style={{
                                        width: 320,
                                        display: 'flex',
                                        color: 'white',
                                        alignContent: 'space-evenly',
                                        justifyContent: 'space-evenly',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                    }}
                                >
                                    <div style={{ marginTop: 12, fontSize: 14 }}> History length (data points) :</div>
                                    <IconButton
                                        style={{ color: 'lightblue' }}
                                        onClick={() => {
                                            setNDataPoints(Math.max(10, nDataPoints - 10));
                                        }}
                                    >
                                        <KeyboardArrowLeftIcon />
                                    </IconButton>
                                    <div style={{ marginTop: 12, fontSize: 14 }}>{nDataPoints}</div>
                                    <IconButton
                                        style={{ color: 'lightblue' }}
                                        onClick={() => {
                                            setNDataPoints(Math.max(10, nDataPoints + 10));
                                        }}
                                    >
                                        <KeyboardArrowRightIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div
                                className={` ${classes.nivoWrapperLine}`}
                                style={{ height: 620, overflow: 'visible', marginTop: 0 }}
                            >
                                <DashboardResponsiveLine data={graphData} pointTooltip={MoneySupplyLineTooltip} />
                                <BrushComponent data={brushComponentData} updateGraph={handleBrush} />
                                <Divider className={classes.divider} />
                            </div>
                        </div>
                    </Slide>
                </div>
            )}
        </>
    );
};
