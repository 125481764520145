import { useCallback, useState } from 'react';

import { Bond } from '@r3/cbdc-asset-frontend-core';
import { ResolvedPromise } from '../api/resolvePromise';
import { requestAllBonds } from '../api/bondsApi';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const emptyBond: Bond = {
    usdValue: 0,
};

export default function useGetBondsSummary() {
    const [bonds, setBonds] = useState<Bond>(emptyBond);
    const [bondsError, setBondsError] = useState<string>('');
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const getAllBondsData = useCallback(
        async (dontTrack?: boolean) => {
            const bondsResponse: ResolvedPromise = await requestAllBonds(dontTrack);
            if (bondsResponse.error) {
                const errorText = t('error.gettingBonds', { error: bondsResponse.error });
                enqueueSnackbar(errorText, {
                    variant: 'error',
                });
                setBondsError((text) => text + (text.length > 0 ? '. ' : '') + errorText);
                return;
            } else {
                let bonds = bondsResponse.data as Bond;
                if (bonds.usdValue <= 0) {
                    const warningText = t('dashBoard.noBonds');
                    enqueueSnackbar(warningText, {
                        variant: 'info',
                    });
                    setBondsError((text) => text + (text.length > 0 ? '. ' : '') + warningText);
                    return;
                }
                bonds.usdValue = parseFloat(bonds.usdValue.toFixed(3));
                setBonds(bonds);
            }
        },
        [enqueueSnackbar, t]
    );

    return { bonds, getAllBondsData, bondsError };
}
