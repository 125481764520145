import { Divider, Fade, IconButton, Paper } from '@material-ui/core';
import { KycState, useInteractiveChecklistContext } from '@r3/cbdc-asset-frontend-core';
import { useEffect, useState } from 'react';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { InfoWidget } from 'components/infoModal/InfoWidget/InfoWidget';
import { KycRevokeDialog } from './KycRevokeDialog';
import { ListHeader } from 'components/commonComponents/ListHeader/ListHeader';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import { revokeKyc } from 'api/kycApi';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import useGetKycStates from 'hooks/useGetKycStates';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useListStyles } from '@r3/cbdc-asset-frontend-core';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const ListKyc = () => {
    const listClasses = useListStyles();
    const layoutClasses = useLayoutStyles();
    const [collapsedKeys, setCollapesedKeys] = useState<string[]>([]);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const checkListContext = useInteractiveChecklistContext();
    const location = useLocation();
    const [selectedKycState, setSelectedKycState] = useState<KycState | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [showInfoWidget, setShowInfoWidget] = useState<boolean>(false);
    const { getAssetNamebyId, getAllAssets, assetsError } = useGetAssetDefinitions();
    const { kycStatesByPublicId, getKycStates, error: kycError } = useGetKycStates();

    useEffect(() => {
        checkListContext?.completeStep(location.pathname);
        let keysToCollapse =
            kycStatesByPublicId !== undefined && kycStatesByPublicId.size > 1
                ? Array.from(kycStatesByPublicId.keys())
                : [];
        setCollapesedKeys(keysToCollapse);
    }, [kycStatesByPublicId, checkListContext, location.pathname]);

    useEffect(() => {
        getKycStates();
        getAllAssets(true);
    }, [getKycStates, getAllAssets]);

    const handlePublicKeyClick = (key: string) => {
        if (collapsedKeys.includes(key)) {
            setCollapesedKeys((keys) => keys.filter((x) => x !== key));
        } else {
            setCollapesedKeys((keys) => keys.concat([key]));
        }
    };

    const openRevokeWindow = (kycState: KycState) => {
        setSelectedKycState(kycState);
        setModalOpen(true);
    };

    const handleClose = () => {
        setSelectedKycState(null);
        setModalOpen(false);
    };

    const revoke = async () => {
        handleClose();
        await submitRevoke();
        setShowInfoWidget(true);
    };

    const submitRevoke = async () => {
        if (!selectedKycState) {
            return;
        }
        const revokeResponse = await revokeKyc(selectedKycState.id);

        if (revokeResponse.error) {
            enqueueSnackbar(t('error.revokeKyc', { error: revokeResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.revokeKyc'), {
                variant: 'success',
            });
            getKycStates();
        }
    };

    return (
        <>
            <Fade in={true}>
                <div className={`${layoutClasses.componentWrapper} `}>
                    <Paper elevation={5} className={`${layoutClasses.column} `}>
                        {kycError.length > 0 || assetsError.length > 0 ? (
                            <ListHeader
                                text={kycError.length > 0 ? kycError : assetsError}
                                refreshAction={() => {
                                    getKycStates();
                                    getAllAssets();
                                }}
                            />
                        ) : (
                            <>
                                <ListHeader text={t('listKyc.listOfStatesLabel')} refreshAction={getKycStates} />
                                <Divider className={listClasses.divider} />
                                <div className={listClasses.list}>
                                    {kycStatesByPublicId !== undefined &&
                                        kycStatesByPublicId.mapGroups((publicKey, items) => {
                                            const publickKeyElement: JSX.Element = (
                                                <div
                                                    className={`${listClasses.sortKey} kycIssuerGrouping`}
                                                    data-testid={'holder_filter'}
                                                >
                                                    <div>
                                                        <p
                                                            className="stateHolderName"
                                                            data-testid={'holder_filter_name'}
                                                        >
                                                            <b>{t('listKyc.stateHolderName')} </b>
                                                            {items[0].authorisedParty}
                                                        </p>
                                                        <p className="stateHolderId" data-testid={'holder_filter_id'}>
                                                            <b>{t('listKyc.stateHolderId')}</b> {publicKey}
                                                        </p>
                                                    </div>
                                                    <IconButton
                                                        className={`${listClasses.sortKeyButton} expandButton`}
                                                        onClick={() => handlePublicKeyClick(publicKey)}
                                                        data-testid={'holder_filter_expand'}
                                                    >
                                                        {collapsedKeys.includes(publicKey) ? (
                                                            <ExpandMoreIcon color="secondary" />
                                                        ) : (
                                                            <ExpandLessIcon color="secondary" />
                                                        )}
                                                    </IconButton>
                                                </div>
                                            );

                                            if (collapsedKeys.includes(publicKey)) {
                                                return <div key={publicKey}>{publickKeyElement} </div>;
                                            }

                                            return (
                                                <div key={publicKey}>
                                                    {publickKeyElement}
                                                    {items.map((kycState) => {
                                                        return (
                                                            <div
                                                                key={kycState.id.id}
                                                                className={`${listClasses.item} ${listClasses.clickableItem} kycState`}
                                                                data-testid={'kyc_state'}
                                                                onClick={() => openRevokeWindow(kycState)}
                                                            >
                                                                <div>
                                                                    <div className={`${listClasses.text} assetName`}>
                                                                        {t('defineAsset.assetName') + ': '}
                                                                        {getAssetNamebyId(kycState.tokenIdentifier)}
                                                                    </div>
                                                                    <div className={`${listClasses.text} assetId`}>
                                                                        {t('commonText.assetId') + ': '}
                                                                        {kycState.tokenIdentifier}
                                                                    </div>
                                                                    <div
                                                                        className={`${listClasses.text} memberAccessId`}
                                                                    >
                                                                        {t('commonText.kycId') + ': '}
                                                                        {kycState.id.id}
                                                                    </div>
                                                                </div>
                                                                <TouchAppIcon
                                                                    color="secondary"
                                                                    className={`${listClasses.requestIcon} requestIcon`}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}
                                </div>
                                <Divider className={listClasses.divider} />
                                {selectedKycState && modalOpen && (
                                    <KycRevokeDialog
                                        open={modalOpen}
                                        requester={selectedKycState.authorisedParty}
                                        kycId={selectedKycState.id.id}
                                        assetName={getAssetNamebyId(selectedKycState.tokenIdentifier)}
                                        revoke={revoke}
                                        onClose={handleClose}
                                    />
                                )}
                            </>
                        )}
                    </Paper>
                </div>
            </Fade>
            <InfoWidget showing={showInfoWidget} />
        </>
    );
};
