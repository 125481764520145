import { ComplianceRuleKey, EMPTY_SUMMED_STATE, StepperFormStep, SummedAsset } from '@r3/cbdc-asset-frontend-core';
import React, { useEffect, useMemo, useState } from 'react';

import AssetTransforms from 'utils/AssetTransforms';
import EnterAmountStep from './EnterAmountStep';
import { Fade } from '@material-ui/core';
import { ResolvedPromise } from 'api/resolvePromise';
import SelectAssetStep from './SelectAssetStep';
import SelectParticipantStep from 'components/commonComponents/GenericStepperFormComponents/SelectParticipantStep';
import { StepperForm } from '@r3/cbdc-asset-frontend-core';
import TransferSummaryStep from './TransferSummaryStep';
import { transferAssetsTo } from 'api/transactApi';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import useGetAssetSummary from 'hooks/GetAssetSummary';
import useGetKycStates from 'hooks/useGetKycStates';
import useGetParticipantsOnNetwork from 'hooks/GetParticipantsOnNetwork';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

type Props = {
    ignoreInfoModal?: boolean;
    onSuccessfulSubmit?: () => void;
};

export const TransferAssets: React.FC<Props> = ({ onSuccessfulSubmit, ignoreInfoModal = false }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [selectedAsset, setSelectedAsset] = useState<SummedAsset>(EMPTY_SUMMED_STATE);
    const [selectedParticipant, setSelectedParticipant] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [assetsForTransfer, setAssetsForTransfer] = useState<SummedAsset[]>([]);
    const { participants, getParticipants, participantsError } = useGetParticipantsOnNetwork();
    const [assetAmount, setAssetAmount] = useState<string>('0');
    const { kycStates, getKycStates } = useGetKycStates();
    const { assets, getAllAssets, assetsError, getAssetNamebyId } = useGetAssetDefinitions();
    const { getAssetSummary, assetSummary, summaryError } = useGetAssetSummary();

    const { t } = useTranslation();

    const handleReset = () => {
        setSelectedParticipant('');
        setAssetAmount('');
        setSelectedAsset(EMPTY_SUMMED_STATE);
        getAssetSummary();
    };

    useEffect(() => {
        setError((text) => text + (text.length > 0 ? '. ' : '') + participantsError);
    }, [participantsError]);

    const submitTransfer = async () => {
        const issuanceResponse: ResolvedPromise = await transferAssetsTo(
            selectedAsset.tokenIdentifier,
            selectedParticipant,
            assetAmount
        );

        if (issuanceResponse.error) {
            enqueueSnackbar(t('error.transferringAsset', { error: issuanceResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.transferringAsset'), {
                variant: 'success',
            });

            if (onSuccessfulSubmit) {
                onSuccessfulSubmit();
            }
        }

        handleReset();
    };

    useEffect(() => {
        setError(assetsError);
    }, [assetsError]);

    useEffect(() => {
        setError(summaryError);
    }, [summaryError]);

    useEffect(() => {
        setSelectedParticipant('');
    }, [selectedAsset]);

    useEffect(() => {
        if (assets.length > 0 && assetSummary.length > 0) {
            setError('');
        }
        setAssetsForTransfer(AssetTransforms.summedAssetsFromSummary(assetSummary, assets));
        // eslint-disable-next-line
    }, [JSON.stringify(assetSummary), JSON.stringify(assets)]);

    useEffect(() => {
        getParticipants();
        getKycStates();
        getAllAssets();
        getAssetSummary();
    }, [getParticipants, getKycStates, getAllAssets, getAssetSummary]);

    const handleAssetChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const tokenIdentifier: string = event.target.value as string;
        const asset = assetsForTransfer.find((obj) => obj.tokenIdentifier === tokenIdentifier);
        if (asset) {
            setSelectedAsset(asset);
        } else {
            setSelectedAsset(EMPTY_SUMMED_STATE);
        }
    };

    const invalidAssetAmountInput = (): boolean => {
        if (
            assetAmount.length <= 0 ||
            parseFloat(assetAmount) <= 0 ||
            selectedAsset.availableToTransact < parseFloat(assetAmount)
        ) {
            return true;
        }

        if (
            selectedAsset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue) &&
            parseFloat(selectedAsset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue)!.value) <
                parseFloat(assetAmount)
        ) {
            return true;
        }

        return false;
    };

    const complyingParticipants = useMemo((): string[] => {
        const allParticipants = participants.participants;
        if (selectedAsset.complianceRules?.findIndex((rule) => rule.key === ComplianceRuleKey.MemberAccess) === -1) {
            return allParticipants;
        } else {
            return allParticipants.filter(
                (p) =>
                    kycStates.findIndex(
                        (kyc) =>
                            getAssetNamebyId(kyc.tokenIdentifier) === selectedAsset.tokenName &&
                            p === kyc.authorisedParty
                    ) > -1
            );
        }
    }, [selectedAsset, kycStates, participants]);

    const selectedAssetRequiresKyc = useMemo((): boolean => {
        if (!selectedAsset.complianceRules) {
            return false;
        }
        return selectedAsset.complianceRules?.findIndex((rule) => rule.key === ComplianceRuleKey.MemberAccess) > -1;
    }, [selectedAsset]);

    const steps: StepperFormStep[] = [
        {
            title: t('transferAssets.selectAssetStep'),
            component: (
                <SelectAssetStep
                    selectedAsset={selectedAsset}
                    handleAssetChange={handleAssetChange}
                    assetsForTransfer={assetsForTransfer}
                />
            ),
            isNextStepDisabled: () => {
                if (selectedAsset.tokenName.length > 0 && selectedAsset.availableToTransact > 0) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('commonText.selectParticipantStep'),
            component: (
                <SelectParticipantStep
                    title={
                        complyingParticipants.length > 0
                            ? t('transferAssets.selectParticipant')
                            : 'No  available participant on the Corda network to transfer to.'
                    }
                    selectedParticipant={selectedParticipant}
                    setSelectedParticipant={setSelectedParticipant}
                    participants={complyingParticipants}
                    warningText={
                        selectedAssetRequiresKyc
                            ? 'This asset requires a Member Access State to hold it.'
                            : 'This asset does not require a Member Access State to hold it.'
                    }
                />
            ),
            isNextStepDisabled: () => {
                if (selectedParticipant.length > 0) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('transferAssets.amountToTransferStep'),
            component: (
                <EnterAmountStep
                    selectedAsset={selectedAsset}
                    assetAmount={assetAmount}
                    setAssetAmount={setAssetAmount}
                    invalidAssetAmountInput={invalidAssetAmountInput}
                />
            ),
            isNextStepDisabled: () => {
                if (assetAmount.length > 0 && !invalidAssetAmountInput()) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('transferAssets.confirmTransferStep'),
            component: (
                <TransferSummaryStep
                    assetAmount={assetAmount}
                    selectedAsset={selectedAsset}
                    selectedParticipant={selectedParticipant}
                />
            ),
            isNextStepDisabled: () => {
                return false;
            },
        },
    ];

    return (
        <>
            <Fade in={true}>
                <StepperForm
                    submit={submitTransfer}
                    steps={steps}
                    renderError={error}
                    submitButtonText={t('commonText.transfer')}
                    handleReset={handleReset}
                    ignoreInfoModal={ignoreInfoModal}
                />
            </Fade>
        </>
    );
};
