import { ComplianceRuleData, InterestRateComplianceControl, useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { Paper, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

type Props = {
    assetName: string;
    interestRateComplianceRule: ComplianceRuleData | undefined;
};

const InterestRateOverviewStep: React.FC<Props> = ({ assetName, interestRateComplianceRule }) => {
    const layoutClasses = useLayoutStyles();
    const { t: text } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper}`}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {interestRateComplianceRule
                        ? text('interestRates.assetInterestRateOverview', { assetName: assetName })
                        : text('interestRates.noInterestRatesConfigured', { assetName: assetName })}
                </Typography>
                {interestRateComplianceRule && (
                    <InterestRateComplianceControl rule={interestRateComplianceRule} showLabel={false} />
                )}
            </Paper>
        </div>
    );
};

export default InterestRateOverviewStep;
