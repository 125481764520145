import 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

import { DASHBOARDANIMATIONENTERMS, DASHBOARDANIMATIONEXITMS } from 'constants/Animations';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Divider, Slide, TextField } from '@material-ui/core';
import { EnhancedTable, TxLogData } from './Table';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { Refreshable } from 'types/refreshable';
import { TokenDataQuery } from '@r3/cbdc-asset-frontend-core';
import { TransactionDialog } from './TransactionDialog';
import { enGB } from 'date-fns/esm/locale';
import { requestTokenData } from 'api/financialReportApi';
import { useDatePickerStyles } from 'materialStyles/datePickerStyles';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useSwitchFilterStyles } from '@r3/cbdc-asset-frontend-core';

registerLocale('enGb', enGB);

enum FilterInputType {
    NUMBER,
    STRING,
    NONE,
    BOOLEAN,
    DATE,
}

type TableFilter = {
    name: string;
    inputType: FilterInputType;
    enabled: boolean;
    inputValue: string | number | null | boolean | Date;
};

function createTableFilter(name: string, inputType: FilterInputType, enabled: boolean = false): TableFilter {
    let inputValue;
    if (inputType === FilterInputType.NUMBER) {
        inputValue = 0;
    } else if (inputType === FilterInputType.STRING) {
        inputValue = '';
    } else if (inputType === FilterInputType.BOOLEAN) {
        inputValue = false;
    } else if (inputType === FilterInputType.DATE) {
        inputValue = new Date();
    } else {
        inputValue = null;
    }
    return { name, inputType, enabled, inputValue };
}

const TxTableFilters: TableFilter[] = [
    createTableFilter('amountMin', FilterInputType.NUMBER),
    createTableFilter('amountMax', FilterInputType.NUMBER),
    createTableFilter('usageCountMin', FilterInputType.NUMBER),
    createTableFilter('usageCountMax', FilterInputType.NUMBER),
];

interface TransactionDashboardProps {
    stateToQuery?: 'CBDCToken' | 'KYCReferenceState';
}

export const TransactionDashboard = forwardRef(
    ({ stateToQuery = 'CBDCToken' }: TransactionDashboardProps, ref: React.Ref<Refreshable>) => {
        const datePickerClasses = useDatePickerStyles();
        const layoutClasses = useLayoutStyles();
        const filterClasses = useSwitchFilterStyles();

        const [tableFilters, setTableFilters] = useState<TableFilter[]>(TxTableFilters);
        const [rows, setRows] = useState<TxLogData[]>([]);
        const [amountFrom, setAmountFrom] = useState<number | null>(0);
        const [amountTo, setAmountTo] = useState<number | null>(1000000000);
        const [usageCountMin, setUsageCountMin] = useState<number | null>(0);
        const [usageCountMax, setUsageCountMax] = useState<number | null>(10000);

        const [sortField] = useState<string>('txTime');
        const [sortOrder] = useState<'DESC' | 'ASC'>('DESC');
        const [txFromDate, setTxFromDate] = useState<Date | null>(new Date('01/01/2021'));
        const [txToDate, setTxToDate] = useState<Date | null>(new Date());
        const [issueFromDate, setIssueFromDate] = useState<Date | null>(new Date('01/01/2021'));
        const [issueToDate, setIssueToDate] = useState<Date | null>(new Date());
        const [page, setPage] = useState<number>(0);
        const [totalResults, setTotalResults] = useState<number>(rows.length);
        const [modalOpen, setModalOpen] = useState<boolean>(false);
        const [selectedRow, setSelectedRow] = useState<TxLogData | null>(null);

        const fetchTableData = async () => {
            let queryParams: TokenDataQuery;
            if (stateToQuery.includes('KYC')) {
                queryParams = {
                    state: stateToQuery,
                    txTimeFrom: txFromDate,
                    txTimeTo: txToDate,
                    issueDateFrom: null,
                    issueDateTo: null,
                    amountMin: null,
                    amountMax: null,
                    usageCountMin: null,
                    usageCountMax: null,
                    consumed: null,
                    startPage: 1,
                    pageSize: 10000,
                    sortField: null,
                    sortOrder: sortOrder,
                };
            } else {
                queryParams = {
                    state: stateToQuery,
                    txTimeFrom: txFromDate,
                    txTimeTo: txToDate,
                    issueDateFrom: issueFromDate,
                    issueDateTo: issueToDate,
                    amountMin: amountFrom! * 100,
                    amountMax: amountTo! * 100,
                    usageCountMin: usageCountMin,
                    usageCountMax: usageCountMax,
                    consumed: null,
                    startPage: 1,
                    pageSize: 10000,
                    sortField: sortField,
                    sortOrder: sortOrder,
                };
            }

            const dataResponse = await requestTokenData(queryParams);

            if (dataResponse.error) {
            } else {
                let data = dataResponse.data as TxLogData[];
                data.forEach((d) => {
                    if (d.command === null) {
                        d.command = 'Spent';
                    }
                    if (d.command.includes('MoveToOr')) {
                        d.command = 'Redemption';
                    }
                    if (d.command.includes('IssueToken')) {
                        d.command = 'Issuance';
                    }
                    if (d.command.includes('MoveTokenCommand')) {
                        d.command = d.command.replace('MoveTokenCommand', 'Transfer Asset');
                    }
                    if (d.command.includes('IssueKYCFromRequestCommand')) {
                        d.command = 'Issue Member Access From Request';
                    }
                    for (const p in d) {
                        if (d[p] === null) {
                            d[p] = 'N/A';
                        }
                    }
                });
                setRows(data);
            }
        };

        useEffect(() => {
            tableFilters.forEach((filter) => {
                if (filter.name.includes('amountMin')) {
                    filter.inputValue = amountFrom;
                } else if (filter.name.includes('amountMax')) {
                    filter.inputValue = amountTo;
                } else if (filter.name.includes('usageCountMin')) {
                    filter.inputValue = usageCountMin;
                } else if (filter.name.includes('usageCountMax')) {
                    filter.inputValue = usageCountMax;
                }
            });
            // eslint-disable-next-line
        }, []);

        const resetDate = () => {
            setTxToDate(new Date());
            setIssueToDate(new Date());
        };

        useImperativeHandle(ref, () => ({
            refresh() {
                resetDate();
            },
        }));

        useEffect(() => {
            resetDate();
        }, [stateToQuery]);

        useEffect(() => {
            fetchTableData();
            setPage(0);
            // eslint-disable-next-line
        }, [
            JSON.stringify(tableFilters),
            txFromDate,
            txToDate,
            issueToDate,
            issueFromDate,
            stateToQuery,
            txToDate,
            issueToDate,
        ]);

        useEffect(() => {
            setTotalResults(rows.length);
        }, [rows]);

        const updateFilterInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
            event.preventDefault();
            const value = event.target.value;
            let tempFilters = [...tableFilters];
            tempFilters[index].inputValue = value;
            switch (tempFilters[index].name) {
                case 'amountMin': {
                    setAmountFrom(parseFloat(parseFloat(value).toFixed(2)));
                    break;
                }
                case 'amountMax': {
                    setAmountTo(parseFloat(parseFloat(value).toFixed(2)));
                    break;
                }
                case 'usageCountMin': {
                    setUsageCountMin(parseInt(value));
                    break;
                }
                case 'usageCountMax': {
                    setUsageCountMax(parseInt(value));
                    break;
                }
                default: {
                    break;
                }
            }
            setTableFilters(tempFilters);
        };

        const handleTableRowClick = (row: TxLogData) => {
            setSelectedRow(row);
        };

        const closeModal = () => {
            setSelectedRow(null);
            setModalOpen(false);
        };

        useEffect(() => {
            if (selectedRow) {
                setModalOpen(true);
            }
        }, [selectedRow]);

        const filters = (): JSX.Element[] => {
            return tableFilters.map((filter, index) => {
                return (
                    <div key={filter.name + index}>
                        <div className={filterClasses.filterItem} style={{ flexFlow: 'wrap', display: 'flex' }}>
                            <div className={filterClasses.filterName}>{filter.name.toUpperCase()}</div>
                            <div className={filterClasses.filterInput}>
                                {(filter.inputType === FilterInputType.NUMBER ||
                                    filter.inputType === FilterInputType.STRING) && (
                                    <TextField
                                        type={filter.inputType === FilterInputType.NUMBER ? 'number' : 'text'}
                                        color="secondary"
                                        value={filter.inputValue}
                                        onChange={(event) => {
                                            updateFilterInput(event, index);
                                        }}
                                    />
                                )}
                            </div>
                            <div className={filterClasses.filterSwitchInput}></div>
                        </div>
                        <Divider className={filterClasses.filterDivider} />
                    </div>
                );
            });
        };

        return (
            <>
                <TransactionDialog open={modalOpen} row={selectedRow} onClose={closeModal} />
                <div className={layoutClasses.componentWrapper}>
                    <Slide
                        direction="right"
                        in={true}
                        timeout={{ enter: DASHBOARDANIMATIONENTERMS, exit: DASHBOARDANIMATIONEXITMS }}
                    >
                        <div
                            className={`${layoutClasses.column} ${layoutClasses.columnSmallMed}`}
                            style={{ minWidth: 345 }}
                        >
                            <div className={filterClasses.filter}>
                                <div className={filterClasses.filterLabels} style={{ marginTop: -20 }}>
                                    <div className={filterClasses.filterlabelName}>{'Transaction Date'}</div>
                                </div>
                                <div className={datePickerClasses.datePickerWrapper}>
                                    <div>
                                        <div className={datePickerClasses.datePickerLabel}>From Date</div>
                                        <DatePicker
                                            className={datePickerClasses.datePicker}
                                            selected={txFromDate}
                                            onChange={(date) => setTxFromDate(date as Date)}
                                            locale="enGb"
                                        />
                                    </div>
                                    <div>
                                        <div className={datePickerClasses.datePickerLabel}>To Date</div>
                                        <DatePicker
                                            className={datePickerClasses.datePicker}
                                            selected={txToDate}
                                            onChange={(date) => setTxToDate(date as Date)}
                                            locale="enGb"
                                        />
                                    </div>
                                </div>

                                {!stateToQuery.includes('KYC') && (
                                    <>
                                        <div className={filterClasses.filterLabels}>
                                            <div className={filterClasses.filterlabelName}>{'Issue Date'}</div>
                                        </div>
                                        <div className={datePickerClasses.datePickerWrapper}>
                                            <div>
                                                <div className={datePickerClasses.datePickerLabel}>From Date</div>
                                                <DatePicker
                                                    className={datePickerClasses.datePicker}
                                                    selected={issueFromDate}
                                                    onChange={(date) => setIssueFromDate(date as Date)}
                                                    locale="enGb"
                                                />
                                            </div>
                                            <div>
                                                <div className={datePickerClasses.datePickerLabel}>To Date</div>
                                                <DatePicker
                                                    className={datePickerClasses.datePicker}
                                                    selected={issueToDate}
                                                    onChange={(date) => setIssueToDate(date as Date)}
                                                    locale="enGb"
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}

                                {!stateToQuery.includes('KYC') && (
                                    <>
                                        <div className={filterClasses.filterLabels}>
                                            <div className={filterClasses.filterlabelName}>{'Filter'}</div>

                                            <div className={filterClasses.filterLabelSwitch}>{'Input'}</div>
                                        </div>
                                        <Divider className={filterClasses.filterDivider} />
                                        {filters()}{' '}
                                    </>
                                )}
                            </div>
                        </div>
                    </Slide>
                    <Slide
                        direction="left"
                        in={true}
                        timeout={{ enter: DASHBOARDANIMATIONENTERMS, exit: DASHBOARDANIMATIONEXITMS }}
                    >
                        <div
                            className={`${layoutClasses.column} ${layoutClasses.columnNoPadding} ${layoutClasses.columnFill}   `}
                        >
                            <EnhancedTable
                                onClickRow={handleTableRowClick}
                                totalResults={totalResults}
                                page={page}
                                setPage={setPage}
                                data={rows}
                            />
                        </div>
                    </Slide>
                </div>
            </>
        );
    }
);
