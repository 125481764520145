import {
    CBDCIssuanceRequest,
    NumberFormat,
    TransactionRequestState,
    useInteractiveChecklistContext,
} from '@r3/cbdc-asset-frontend-core';
import { Divider, Fade, Paper } from '@material-ui/core';
import { approveCBDCIssuanceRequest, denyCBDCIssuanceRequest, requestAllIssuanceRequests } from 'api/issuanceApi';
import { useCallback, useEffect, useState } from 'react';

import { CBDCRequestDialogue } from './CBDCRequestDialogue';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton } from '@material-ui/core';
import { InfoWidget } from 'components/infoModal/InfoWidget/InfoWidget';
import { ListHeader } from 'components/commonComponents/ListHeader/ListHeader';
import { ResolvedPromise } from 'api/resolvePromise';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import useFilterRequests from 'hooks/FilterRequests';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useListStyles } from '@r3/cbdc-asset-frontend-core';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const CBDCRequests = () => {
    const listClasses = useListStyles();
    const layoutClasses = useLayoutStyles();
    const [issuancesRequestByRequestor, setIssuancesRequestByRequestor] =
        useState<Map<string, CBDCIssuanceRequest[]>>();
    const [collapsedKeys, setCollapesedKeys] = useState<string[]>([]);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState<string>('');
    const [selectedIssuanceRequest, setSelectedIssuanceRequest] = useState<CBDCIssuanceRequest | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [showInfoWidget, setShowInfoWidget] = useState<boolean>(false);
    const checkListContext = useInteractiveChecklistContext();
    const location = useLocation();
    const { filterSwitches, displayRequestType } = useFilterRequests();

    const loadIssuanceRequests = useCallback(async () => {
        const issuanceRequestsResponse: ResolvedPromise = await requestAllIssuanceRequests();
        if (issuanceRequestsResponse.error) {
            enqueueSnackbar(t('error.gettingIssuanceRequests', { error: issuanceRequestsResponse.error }), {
                variant: 'error',
            });
            setError(
                issuanceRequestsResponse.error.length > 0 ? issuanceRequestsResponse.error : t('error.networkError')
            );
        } else {
            if (issuanceRequestsResponse.data.length <= 0) {
                const warningText = t('cbdcRequests.noRequests');
                enqueueSnackbar(warningText, {
                    variant: 'info',
                });
                setError((text) => text + (text.length > 0 ? '. ' : '') + warningText);
            }
            let groupedRequests = (issuanceRequestsResponse.data as CBDCIssuanceRequest[]).groupBy(
                'requestorParty'
            ) as Map<string, CBDCIssuanceRequest[]>;
            setIssuancesRequestByRequestor(groupedRequests);
            setCollapesedKeys(groupedRequests.size < 2 ? [] : Array.from(groupedRequests.keys()));
        }
    }, [t, enqueueSnackbar]);

    useEffect(() => {
        loadIssuanceRequests();
    }, [loadIssuanceRequests]);

    const submitApproval = async () => {
        if (!selectedIssuanceRequest) {
            return;
        }
        const approvalResponse: ResolvedPromise = await approveCBDCIssuanceRequest(selectedIssuanceRequest.requestId);

        if (approvalResponse.error) {
            enqueueSnackbar(t('error.approveCBDCIssuance', { error: approvalResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.approveCBDCIssuance'), {
                variant: 'success',
            });
            checkListContext?.completeStep(location.pathname);
        }
    };

    const submitDeny = async () => {
        if (!selectedIssuanceRequest) {
            return;
        }
        const denyResponse: ResolvedPromise = await denyCBDCIssuanceRequest(selectedIssuanceRequest.requestId);

        if (denyResponse.error) {
            enqueueSnackbar(t('error.denyCBDCIssuance', { error: denyResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.denyCBDCIssuance'), {
                variant: 'success',
            });
        }
    };

    const openInfoModal = (issuanceRequest: CBDCIssuanceRequest) => {
        setSelectedIssuanceRequest(issuanceRequest);
        setModalOpen(true);
    };

    const handleClose = () => {
        setSelectedIssuanceRequest(null);
        setModalOpen(false);
    };

    const approve = async () => {
        handleClose();
        await submitApproval();
        loadIssuanceRequests();
        setShowInfoWidget(true);
    };

    const deny = async () => {
        handleClose();
        await submitDeny();
        loadIssuanceRequests();
        setShowInfoWidget(true);
    };

    const handlePublicKeyClick = (key: string) => {
        if (collapsedKeys.includes(key)) {
            setCollapesedKeys((keys) => keys.filter((x) => x !== key));
        } else {
            setCollapesedKeys((keys) => keys.concat([key]));
        }
    };

    return (
        <>
            <Fade in={true}>
                <div className={`${layoutClasses.componentWrapper} `}>
                    <Paper elevation={5} className={`${layoutClasses.column} `}>
                        {error.length > 0 ? (
                            <ListHeader
                                text={error}
                                refreshAction={() => {
                                    setError('');
                                    loadIssuanceRequests();
                                }}
                            />
                        ) : (
                            <>
                                <ListHeader
                                    text={t('cbdcRequests.issuanceRequests')}
                                    refreshAction={loadIssuanceRequests}
                                />
                                {filterSwitches}
                                <Divider className={listClasses.divider} />
                                <div className={listClasses.list}>
                                    {issuancesRequestByRequestor != null &&
                                        issuancesRequestByRequestor.mapGroups((requestor, requests) => {
                                            const publickKeyElement: JSX.Element = (
                                                <div
                                                    className={`${listClasses.sortKey} requestingPartyGrouping`}
                                                    data-testid={'holder_filter'}
                                                >
                                                    <div>
                                                        <p
                                                            className="stateHolderName"
                                                            data-testid={'holder_filter_name'}
                                                        >
                                                            <b>{t('cbdcRequests.requestingParty') + ':'} </b>
                                                            {requestor}
                                                        </p>
                                                    </div>
                                                    <IconButton
                                                        className={`${listClasses.sortKeyButton} expandButton`}
                                                        onClick={() => handlePublicKeyClick(requestor)}
                                                        data-testid={'holder_filter_expand'}
                                                    >
                                                        {collapsedKeys.includes(requestor) ? (
                                                            <ExpandMoreIcon color="secondary" />
                                                        ) : (
                                                            <ExpandLessIcon color="secondary" />
                                                        )}
                                                    </IconButton>
                                                </div>
                                            );

                                            if (collapsedKeys.includes(requestor)) {
                                                return <div key={requestor}>{publickKeyElement} </div>;
                                            }

                                            return (
                                                <div key={requestor}>
                                                    {publickKeyElement}
                                                    {(requests as CBDCIssuanceRequest[])
                                                        .filter((req) =>
                                                            displayRequestType(
                                                                req.requestState as TransactionRequestState
                                                            )
                                                        )
                                                        .map((request, sIndex) => {
                                                            let statusColor = 'white';

                                                            if (request.requestState === 'REQUESTED') {
                                                                statusColor = 'yellow';
                                                            } else if (request.requestState === 'APPROVED') {
                                                                statusColor = 'lawngreen';
                                                            } else if (request.requestState === 'DENIED') {
                                                                statusColor = 'red';
                                                            }
                                                            return (
                                                                <div
                                                                    data-testid={'request_container'}
                                                                    key={requestor + '_' + sIndex}
                                                                    className={`${listClasses.item} ${listClasses.clickableItem} cbdcRequest`}
                                                                    onClick={() => openInfoModal(request)}
                                                                >
                                                                    <div>
                                                                        <div
                                                                            className={`${listClasses.text} assetName`}
                                                                        >
                                                                            {t('defineAsset.assetName') + ': '}
                                                                            {request.token.tokenName}
                                                                        </div>
                                                                        <div
                                                                            className={`${listClasses.text} assetAmount`}
                                                                        >
                                                                            {t('commonText.amount') + ': '}
                                                                            {NumberFormat.addThousandSeparators(
                                                                                request.requestedAmount
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            className={`${listClasses.text} requestStatus`}
                                                                        >
                                                                            <span>{t('commonText.status') + ': '}</span>
                                                                            <span style={{ color: statusColor }}>
                                                                                {request.requestState}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <TouchAppIcon
                                                                        color="secondary"
                                                                        className={`${listClasses.requestIcon} touchIcon`}
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            );
                                        })}
                                </div>
                                <Divider className={listClasses.divider} />

                                {selectedIssuanceRequest && modalOpen && (
                                    <CBDCRequestDialogue
                                        open={modalOpen}
                                        request={selectedIssuanceRequest}
                                        approve={approve}
                                        onClose={handleClose}
                                        decline={deny}
                                    />
                                )}
                            </>
                        )}
                    </Paper>
                </div>
            </Fade>
            <InfoWidget showing={showInfoWidget} />
        </>
    );
};
