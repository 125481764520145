import apiCall from './apiCall';

/**
 * @remark
 * Used to get the current nodes identity
 *
 *
 * @returns
 * Axios Response which is resolves in string or error
 */
export const getOurOwnIdentity = async () =>
    apiCall({
        method: 'get',
        path: `/api/nodeDetails/ourIdentity`,
    });

/**
 * @remark
 * Used to get all participants on the network
 *
 * @returns
 * AxiosResponse which resolve in an array of identitites or error
 */
export const requestAllParticipants = async () =>
    apiCall({
        method: 'get',
        path: `/api/nodeDetails/participants`,
    });

/**
 * @remark
 * Used to get all notaries on the network
 *
 * @returns
 * AxiosResponse which resolves in a list of notaries or error
 */
export const requestAllNotaries = async () =>
    apiCall({
        method: 'get',
        path: `/api/nodeDetails/notaries`,
    });
