import { Asset, StepperFormStep, useInteractiveChecklistContext } from '@r3/cbdc-asset-frontend-core';
import React, { useEffect, useState } from 'react';

import { Fade } from '@material-ui/core';
import KycIssuanceSummaryStep from 'components/cbdcManager/IssueCBDC/KycIssuanceSummaryStep';
import SelectAssetStep from 'components/commonComponents/GenericFormSteps/SelectAssetStep';
import SelectParticipantStep from './SelectParticipantStep';
import { StepperForm } from '@r3/cbdc-asset-frontend-core';
import { kycIssueTo } from 'api/kycApi';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import useGetOurOwnIdentity from 'hooks/GetOurOwnIdentity';
import useGetParticipantsOnNetwork from 'hooks/GetParticipantsOnNetwork';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const IssueKyc: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [selectedParticipant, setSelectedParticipant] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [selectedAsset, setSelectedAsset] = useState<Asset>({
        tokenDecimals: 0,
        tokenIdentifier: '',
        tokenName: '',
        party: '',
    });
    const { getAssetbyName, getAllAssets, assets } = useGetAssetDefinitions();
    const { participants, participantsError, getParticipants } = useGetParticipantsOnNetwork();
    const { identity } = useGetOurOwnIdentity(true);
    const { t } = useTranslation();
    const checkListContext = useInteractiveChecklistContext();
    const location = useLocation();

    const handleReset = () => {
        setSelectedParticipant('');
    };

    const submitIssueKyc = async () => {
        const issueResponse = await kycIssueTo(selectedAsset.tokenIdentifier, selectedParticipant);

        if (issueResponse.error) {
            enqueueSnackbar(t('error.issueKyc', { error: issueResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.issueKyc'), {
                variant: 'success',
            });
            checkListContext?.completeStep(location.pathname);
        }
        handleReset();
    };

    useEffect(() => {
        setError((text) => text + (text.length > 0 ? '. ' : '') + participantsError);
    }, [participantsError]);

    useEffect(() => {
        getAllAssets();
        getParticipants();
    }, [enqueueSnackbar, t, getAllAssets, getParticipants]);

    const handleParticipantChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedParticipant(event.target.value as string);
    };

    const handleAssetChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let asset = getAssetbyName(event.target.value as string);
        setSelectedAsset(asset);
    };

    const steps: StepperFormStep[] = [
        {
            title: t('issueAsset.selectAsset'),
            component: (
                <SelectAssetStep
                    headerText={t('issueKyc.selectAsset')}
                    assetName={selectedAsset.tokenName}
                    handleAssetChange={handleAssetChange}
                    assets={assets}
                />
            ),
            isNextStepDisabled: () => {
                if (selectedAsset.tokenName.length > 0) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('issueKyc.selectParticipantStep'),
            component: (
                <SelectParticipantStep
                    participants={participants.participants.concat([identity])}
                    selectedParticipant={selectedParticipant}
                    handleParticipantChange={handleParticipantChange}
                />
            ),
            isNextStepDisabled: () => {
                if (selectedParticipant.length > 0) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('issueKyc.confirmKyc'),
            component: (
                <KycIssuanceSummaryStep assetName={selectedAsset.tokenName} selectedParticipant={selectedParticipant} />
            ),
            isNextStepDisabled: () => {
                return false;
            },
        },
    ];

    return (
        <>
            <Fade in={true}>
                <StepperForm
                    submit={submitIssueKyc}
                    steps={steps}
                    submitButtonText={t('commonText.issue')}
                    handleReset={handleReset}
                    renderError={error}
                />
            </Fade>
        </>
    );
};
