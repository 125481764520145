import apiCall from './apiCall';

/**
 * @remark
 * Used to approve a redemption request
 *
 * @param requestId
 * The id of the request
 *
 * @returns
 * Axios Response which is resolves in approved redemption request object or error
 */
export const approveRedemptionRequest = async (requestId: string) =>
    apiCall({
        method: 'post',
        path: `/api/redemptionRequest/${requestId}/approve`,
    });

/**
 * @remark
 * Used to deny a redemption request
 *
 * @param requestId
 * The id of the request
 *
 * @returns
 * Axios Response which is resolves in denied redemption request object or error
 */
export const denyRedemptionRequest = async (requestId: string) =>
    apiCall({
        method: 'post',
        path: `/api/redemptionRequest/${requestId}/deny`,
    });

/**
 * @remark
 * Used to request all redemption requests from all participants
 *
 * @returns
 * Axios Response which is resolves in redemption request object array or error
 */
export const requestAllRedemptionRequests = async (dontTrack?: boolean) =>
    apiCall({
        method: 'get',
        path: `/api/redemptionRequest`,
        dontTrackRequest: dontTrack,
    });
