import { TimeUnit } from '@r3/cbdc-asset-frontend-core';

var DateExtensions = /** @class */ (function () {
    function DateExtensions() {}
    /**
     * Tells if the provided dateTime is in range for the duration.
     *
     * @param dateTime DateTime to check whether it is in range.
     * @param diff Duration to add to current time.
     * @param unit Time unit of duration.
     * @returns Tells if the provided dateTime is in range for the duration.
     */
    DateExtensions.prototype.moreThanOrEqualToTimeBetween = function (dateFrom, dateTo, diff, unit) {
        if (unit === TimeUnit.Seconds) {
            diff = diff * 1000;
        }
        return dateTo.getTime() - dateFrom.getTime() >= diff;
    };

    DateExtensions.prototype.dateWithZeroSeconds = function (date): Date {
        const newDate = new Date(date);
        newDate.setSeconds(0);
        return newDate;
    };

    DateExtensions.prototype.datePlusDays = function (date, days): Date {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + days);
        return newDate;
    };

    DateExtensions.prototype.dateMinusDays = function (date, days): Date {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() - days);
        return newDate;
    };

    return DateExtensions;
})();
export default new DateExtensions();

export const DAY_IN_SECONDS = 86400;
export const MINUTE_IN_SECONDS = 60;
