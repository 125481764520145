import { useCallback, useEffect, useState } from 'react';

import { KycState } from '@r3/cbdc-asset-frontend-core';
import { ResolvedPromise } from '../api/resolvePromise';
import { requestAllKycStates } from '../api/kycApi';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function useGetKycStates() {
    const [kycStates, setKycStates] = useState<KycState[]>([]);
    const [kycStatesByPublicId, setKycStatesByPublicId] = useState<Map<string, KycState[]>>();
    const [error, setError] = useState<string>('');
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const getKycStates = useCallback(
        async (dontTrack?: true) => {
            setError('');
            let kycStatesResponse: ResolvedPromise;

            if (dontTrack) {
                kycStatesResponse = await requestAllKycStates(true);
            } else {
                kycStatesResponse = await requestAllKycStates();
            }
            if (kycStatesResponse.error && !dontTrack) {
                enqueueSnackbar(t('error.gettingKycStates', { error: kycStatesResponse.error }), {
                    variant: 'error',
                });
                setError(kycStatesResponse.error.length > 0 ? kycStatesResponse.error : t('error.networkError'));
            } else {
                if (kycStatesResponse.data.length <= 0 && !dontTrack) {
                    const warningText = t('listKyc.noKycStates');
                    enqueueSnackbar(warningText, {
                        variant: 'info',
                    });
                    setError(warningText);
                    return;
                }
                setKycStates(kycStatesResponse.data as KycState[]);
            }
        },
        [enqueueSnackbar, t]
    );

    useEffect(() => {
        setKycStatesByPublicId(kycStates.groupBy('publicKey') as Map<string, KycState[]>);
    }, [kycStates]);

    return { kycStates, getKycStates, error, kycStatesByPublicId };
}
