import { requestAllNotaries } from '../api/nodeDetailsApi';
import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function useGetAllNotaries() {
    const [notaries, setNotaries] = useState<string[]>([]);
    const [notariesError, setError] = useState<string>('');
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const getAllNotaries = useCallback(async () => {
        const response = await requestAllNotaries();
        if (response.error) {
            enqueueSnackbar(t('error.gettingNotaries', { error: response.error }), {
                variant: 'error',
            });
            setError(response.error.length > 0 ? response.error : t('error.networkError'));
        } else {
            setNotaries(response.data.notaries);
        }
    }, [t, enqueueSnackbar]);

    return { notaries, getAllNotaries, notariesError };
}
